import React from 'react';
import { Link } from 'react-router-dom';
import { Title, Text, Box } from '@nimbus-ds/components';
import { Logo, RecaptchaText } from 'components';
import { BaseAuthInterface } from './baseAuth.types';
import './baseAuth.styled.scss';

const BaseAuth: React.FC<BaseAuthInterface> = ({
  className,
  children,
  title,
  text,
  link
}) => {
  return (
    <Box height="100%" minHeight="100vh" display="flex" alignItems="stretch">
      <Box
        width={{
          md: '100%',
          xs: '100%',
          xl: '400px',
          lg: '400px'
        }}
        padding="8"
        position="relative"
        display="flex"
        flexDirection="column"
      >
        <Box>
          <Logo />
        </Box>
        {title && (
          <Box marginY="8">
            <Title>{title}</Title>
          </Box>
        )}
        <Box flex="1" className={className}>
          {children}
          <Box marginTop="8">
            {text && <Text color="neutral-textHigh">{text}</Text>}
            {link && (
              <Link
                className="baseauth-link"
                to={{ pathname: link?.href }}
                onClick={link?.onClick}
              >
                {link?.children}
              </Link>
            )}
          </Box>
        </Box>
        <RecaptchaText />
      </Box>
      <div className="baseauth-ilustration-wrapper" />
    </Box>
  );
};

export default BaseAuth;
