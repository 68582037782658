import { PencilIcon } from '@nimbus-ds/icons';
import { ROUTES } from 'commons/constants';
import { IAlertCardItem } from './alerts.types';

export const cardAlerts = (isTechPartner?: boolean): IAlertCardItem[] => {
  const alerts: IAlertCardItem[] = [
    {
      icon: PencilIcon,
      title: 'complete_data.title',
      subtitle: 'complete_data.subtitle',
      link: ROUTES.ACCOUNT.REGISTRATION,
      linkType: 'default'
    }
  ];

  // TODO: ENABLE BUTTON WHEN DOCUMENTATIONS HAVE BEEN READY
  /*  const customAlert: IAlertCardItem = {
    icon: GlassesIcon,
    title: 'documentation.title',
    subtitle: 'documentation.subtitle',
    link: ROUTES.DOCUMENTATION,
    linkType: 'default'
  }; */

  // TODO: ENABLE BUTTON WHEN DOCUMENTATIONS HAVE BEEN READY
  // if (isTechPartner) {
  //   customAlert = {
  //     ...customAlert,
  //     title: 'tech_documentation.title',
  //     subtitle: 'tech_documentation.subtitle',
  //     link: 'application_journey',
  //     linkType: 'nimbus'
  //   };
  // }
  if (!isTechPartner) {
    //alerts.push(customAlert);
  }

  return alerts;
};
