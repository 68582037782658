import React from 'react';
import { Trans } from 'react-i18next';
import { Box } from '@nimbus-ds/components';
import { useField, useFormikContext } from 'formik';
import { InteractiveListPriv, Text } from '@tiendanube/components';

import { useTranslate } from 'hooks';
import { ApplicationsTypings } from 'types';
import { Card } from 'components';

const ApplicationTypes: React.FC<{
  type?: 'card' | 'list';
  helpText?: string;
}> = ({ type, helpText }) => {
  const { errors } = useFormikContext<ApplicationsTypings.IApplication>();
  const { translate } = useTranslate(['applications-actions', 'validations']);
  const [field, , helpers] = useField('visibility');

  const transformTitle = (text: string): string =>
    (<Trans i18nKey={text} />) as unknown as string;

  return (
    <Card visibleBorder={type !== 'list'}>
      {!helpText && (
        <Card.Header title={translate('applications-actions:subtitle.type')} />
      )}

      {helpText && (
        <Box paddingLeft="2" paddingRight="4">
          <Text> {helpText}</Text>
        </Box>
      )}

      <Card.Body>
        <InteractiveListPriv>
          <InteractiveListPriv.RadioItem
            title={translate('applications-actions:fields.public')}
            value={ApplicationsTypings.EApplicationVisibility.PUBLIC}
            checked={
              field.value === ApplicationsTypings.EApplicationVisibility.PUBLIC
            }
            onChange={(value) => helpers.setValue(value.name)}
            description={transformTitle(
              'applications-actions:paragraph.type.public_text'
            )}
            labels={[
              { id: 'recommended', label: 'Recomendado', appearance: 'primary' }
            ]}
          />
          <InteractiveListPriv.RadioItem
            title={translate('applications-actions:fields.private')}
            value={ApplicationsTypings.EApplicationVisibility.PRIVATE}
            checked={
              field.value === ApplicationsTypings.EApplicationVisibility.PRIVATE
            }
            onChange={(value) => helpers.setValue(value.name)}
            description={transformTitle(
              'applications-actions:paragraph.type.private_text'
            )}
            hideBorder={type !== 'list'}
          />
        </InteractiveListPriv>
        {errors?.visibility && (
          <Text appearance="danger">
            {translate(`validations:${errors?.visibility}`)}
          </Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default ApplicationTypes;
