import React from 'react';
import { Link, Text, Title } from '@tiendanube/components';
import { WhatsappIcon } from '@nimbus-ds/icons';
import { Card } from 'components';
import { useTranslate } from 'hooks';

import styles from './helpCard.module.scss';

const HelpCard = () => {
  const { translate } = useTranslate(['partnership-plan', 'links']);
  return (
    <Card className={styles.card}>
      <Title type="h5">{`${translate(
        'partnership-plan:support.title'
      )}`}</Title>
      <Text>{`${translate('partnership-plan:support.subtitle')}`}</Text>
      <Link appearance="primary" underline href={translate('links:whatsapp')}>
        <div className={styles['wpp-icon']}>
          <WhatsappIcon />
        </div>
        {`${translate('partnership-plan:support.link')}`}
      </Link>
    </Card>
  );
};

export default HelpCard;
