import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { AppsIcon, ExternalLinkIcon, InfoCircleIcon } from '@nimbus-ds/icons';
import { useTranslate } from 'hooks';
import {
  Box,
  Card,
  Title,
  Link,
  Button,
  Text,
  Tooltip,
  Icon
} from '@nimbus-ds/components';
import { ROUTES } from 'commons/constants';
import { useCreateAppModalContext } from 'domains/Applications/pages/CreateApp/createAppModal.context';

const Applications: React.FC<{ totalApps: number }> = ({ totalApps }) => {
  const { translate } = useTranslate(['home', 'links']);
  const { showModal } = useCreateAppModalContext();
  const hasApps = useMemo(() => totalApps > 0, [totalApps]);

  return (
    <Card>
      <Card.Header>
        <Box display="flex">
          <Box marginRight="2">
            <AppsIcon size="medium" />
          </Box>
          <Title as="h4">{`${translate(
            'home:cards.applications.title'
          )}`}</Title>
        </Box>
      </Card.Header>
      <Card.Body>
        <Box>
          <Box display="flex" flexDirection="column" marginBottom="8">
            {hasApps && (
              <>
                <Title as="h2" fontWeight="bold">
                  {totalApps}
                </Title>

                <Box display="flex" flexDirection="row" alignItems="center">
                  <Text>
                    {totalApps === 1
                      ? translate('home:cards.applications.one_application')
                      : translate('home:cards.applications.more_application')}
                  </Text>
                  <Tooltip
                    content={translate('home:cards.applications.help_text')}
                    position="top"
                  >
                    <Box marginLeft="1">
                      <Icon source={<InfoCircleIcon />} />
                    </Box>
                  </Tooltip>
                </Box>
              </>
            )}
            {!hasApps && (
              <Text>
                <Trans
                  i18nKey={'home:cards.applications.create_application'}
                  components={[
                    <Link
                      as="a"
                      href={translate('links:doubts_email')}
                      key="journey_steps"
                      appearance="primary"
                    >
                      <React.Fragment />
                    </Link>
                  ]}
                />
              </Text>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              appearance="neutral"
              type="button"
              onClick={() => showModal()}
            >
              {`${translate(
                'home:cards.applications.buttons.create_application'
              )}`}
            </Button>
            <Box marginLeft="2">
              {hasApps && (
                <Link
                  as="a"
                  textDecoration="none"
                  href={ROUTES.APPLICATIONS.DEFAULT}
                >
                  <Text color="primary-textLow" fontWeight="bold">
                    {translate('home:cards.applications.buttons.applications')}
                  </Text>
                </Link>
              )}
              {!hasApps && (
                <Link
                  textDecoration="none"
                  as="a"
                  href={translate('links:technological_partner')}
                >
                  <Text fontWeight="bold" color="primary-textLow">
                    {translate('home:cards.applications.buttons.more_info')}
                  </Text>
                  <Icon source={<ExternalLinkIcon />} />
                </Link>
              )}
            </Box>
          </Box>
        </Box>
      </Card.Body>
    </Card>
  );
};

export default Applications;
