import { useLayoutEffect } from 'react';
import { useAppLayoutContext } from 'components/AppLayout/AppLayoutProvider';
import {
  IAppLayoutBackButton,
  IAppLayoutItem
} from 'components/AppLayout/appLayout.types';
import { useLocation } from 'react-router-dom';

const useTopMenu = (
  params?: {
    backButton?: IAppLayoutBackButton;
    items?: IAppLayoutItem[];
    actions?: IAppLayoutItem[];
  },
  deps?: React.DependencyList | undefined
) => {
  const appLayoutContext = useAppLayoutContext();
  const location = useLocation();

  useLayoutEffect(() => {
    appLayoutContext?.setBackButton?.(params?.backButton);
    appLayoutContext?.setItems?.(params?.items);
    appLayoutContext?.setActions?.(params?.actions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, ...(deps ?? [])]);
};

export default useTopMenu;
