import React from 'react';
import { Field, FieldProps } from 'formik';
import { Radio } from '@nimbus-ds/components';

import { IRadioButton } from './radiobutton.types';
import './radiobutton.styled.scss';

const RadioButtonV2 = ({
  name,
  label,
  value,
  dataTestId,
  disabled = false
}: IRadioButton): JSX.Element => (
  <Field name={name} type="radio" value={value}>
    {({ field }: FieldProps) => (
      <div className="radioV2">
        <Radio
          {...field}
          id={`radio${label || value}`}
          data-testid={dataTestId}
          name={name}
          label={label}
          disabled={disabled}
        />
      </div>
    )}
  </Field>
);

export default RadioButtonV2;
