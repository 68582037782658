import React from 'react';
import {
  Box,
  Button,
  Card,
  IconButton,
  Input,
  Link,
  Tag,
  Text,
  Title
} from '@nimbus-ds/components';

import { DataList } from '@nimbus-ds/patterns';
import { Skeleton } from 'components';

export const ScriptListCardSkeleton: React.FC = () => {
  return (
    <Card padding="none">
      <Card.Header padding="base">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Title.Skeleton as="h3" />
          <Button.Skeleton />
        </Box>
        <Box display="flex" flex="row" gap="2" marginTop="4">
          <Input.Skeleton />
          <Button.Skeleton />
        </Box>
      </Card.Header>
      <Card.Body padding="none">
        <DataList>
          {Array.from({ length: 3 }, (script, i) => (
            <DataList.Row
              backgroundColor="neutral-background"
              padding="none"
              key={`scripts-${i}`}
              gap="1"
            >
              <Box>
                <Box padding="3">
                  <Text.Skeleton width="200px" />
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      gap="2"
                      alignItems="center"
                    >
                      <Tag.Skeleton width="100px" />
                      <Tag.Skeleton width="140px" />
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      gap="2"
                      alignItems="center"
                    >
                      <Tag.Skeleton width="80px" />
                      <IconButton.Skeleton width="2rem" height="2rem" />
                      <IconButton.Skeleton width="2rem" height="2rem" />
                    </Box>
                  </Box>
                  <Link.Skeleton width="140px" />
                </Box>
              </Box>
            </DataList.Row>
          ))}
        </DataList>
      </Card.Body>
      <Card.Footer>
        <Box
          padding="4"
          display="flex"
          flexDirection={{
            md: 'row',
            xs: 'column'
          }}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box>
            <Text.Skeleton width="120px" />
          </Box>
          <Box>
            <Skeleton width="300px" height="38px" />
          </Box>
        </Box>
      </Card.Footer>
    </Card>
  );
};
