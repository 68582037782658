export interface IState<T> {
  isLoading: boolean;
  data?: T;
  isError?: string;
}

export enum EActions {
  INITIAL_STATE = 'initalState',
  IS_LOADING = 'isLoading',
  IS_ERROR = 'isError',
  DATA = 'data'
}

export type TActions<T> =
  | { type: EActions.INITIAL_STATE }
  | { type: EActions.IS_LOADING; payload: boolean }
  | { type: EActions.DATA; payload: T }
  | { type: EActions.IS_ERROR; payload: string };
