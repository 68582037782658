import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Card, Icon, Button, Text, Title } from '@nimbus-ds/components';
import { UserGroupIcon } from '@nimbus-ds/icons';
import { useTranslate } from 'hooks';
import { ROUTES } from 'commons/constants';

const Partnership: React.FC = () => {
  const { translate } = useTranslate(['home']);
  const history = useHistory();
  return (
    <Card>
      <Card.Header>
        <Box display="flex" flexDirection="row">
          <Title as="h4">{`${translate(
            'home:cards.partnership.title'
          )}`}</Title>
        </Box>
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="row" alignItems="center" marginY="6">
          <Box
            padding="2"
            marginRight="6"
            borderRadius="full"
            backgroundColor="primary-surface"
          >
            <Icon source={<UserGroupIcon size="medium" />} />
          </Box>
          <Text>{translate('home:cards.partnership.description')}</Text>
        </Box>
      </Card.Body>
      <Card.Footer>
        <Button
          appearance="neutral"
          onClick={() => history.push(ROUTES.PARTNERSHIP_PLAN)}
        >
          {`${translate('home:cards.partnership.buttons.about_program')}`}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default Partnership;
