import React from 'react';

import {
  UserGroupIcon,
  UniversityIcon,
  StoreIcon,
  CodeIcon,
  AppsIcon,
  MoneyIcon,
  TiendanubeIcon
} from '@nimbus-ds/icons';

export const documentationIcons = {
  partner_program: <UserGroupIcon size="small" />,
  first_steps: <UniversityIcon size="small" />,
  new_store: <StoreIcon size="small" />,
  development_and_design: <CodeIcon size="small" />,
  applications: <AppsIcon size="small" />,
  sales_channels: <MoneyIcon size="small" />,
  about_nuvemshop: <TiendanubeIcon size="small" />
} as { [key: string]: React.ReactNode };
