import React, { memo, useState } from 'react';
import { Button, Sidebar } from '@nimbus-ds/components';
import { Menu } from '@nimbus-ds/patterns';
import { MenuIcon } from '@nimbus-ds/icons';
import { MenuItems } from './widgets';

const MenuMobile: React.FC = () => {
  const [open, setOpen] = useState(false);
  const handleMenu = () => setOpen((prevState) => !prevState);

  return (
    <>
      <Button
        data-testid="button-menu-mobile"
        appearance="transparent"
        onClick={handleMenu}
      >
        {<MenuIcon size={24} />}
      </Button>
      <Sidebar
        data-testid="sidebar"
        open={open}
        position="left"
        onRemove={handleMenu}
        maxWidth="300px"
        zIndex="900"
      >
        <Menu>
          <MenuItems handleCloseMenu={handleMenu} />
        </Menu>
      </Sidebar>
    </>
  );
};

export default memo(MenuMobile);
