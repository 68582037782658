import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Icon,
  Input as InputNimbus,
  Label,
  Text,
  Textarea,
  TextareaProps
} from '@nimbus-ds/components';
import { EyeIcon, EyeOffIcon, InfoCircleIcon } from '@nimbus-ds/icons';
import { Field, FieldInputProps, FieldProps, FormikProps } from 'formik';
import { IInput } from './input.types';
import { formatInput } from 'commons/formatters';

const Input: React.FC<IInput> = ({
  boxWrapper,
  name,
  handleOnChange,
  formatter,
  maxLength,
  showQuantity,
  value,
  exampleText,
  label,
  labelIcon,
  helpText,
  multiRows,
  prependLabel,
  appendLabel,
  rows,
  id,
  marginBottom = '4',
  ...props
}) => {
  const isPassword = props.type === 'password';
  const [seePassword, setSeePassword] = useState(false);

  const inputType = useMemo(() => {
    return isPassword && !seePassword
      ? 'password'
      : isPassword && seePassword
      ? 'text'
      : props.type;
  }, [isPassword, props.type, seePassword]);

  const Component = useCallback(
    (field: FieldInputProps<any>, form: FormikProps<any>) => {
      if (multiRows) {
        return (
          <Textarea
            {...(props as unknown as React.FC<TextareaProps>)}
            id={id ?? name}
            data-testid={name}
            value={value ? value : field.value ?? ''}
            appearance={!!helpText && !exampleText ? 'danger' : 'neutral'}
            onChange={(e) => {
              const value = e.target.value ?? '';
              handleOnChange?.(value);
              form.setFieldValue(name, value);
            }}
            onBlur={(e) => {
              const name = e?.target?.name ?? '';
              const value = e?.target?.value?.trim();
              form.setFieldValue(name, value);
              props?.onBlur?.(e as any);
            }}
            lines={rows}
          />
        );
      } else {
        const helpTextIcon = helpText ? (
          <Icon source={<InfoCircleIcon />} />
        ) : undefined;

        const appendTextAndIcon = appendLabel ? (
          <Text>{appendLabel}</Text>
        ) : (
          helpTextIcon
        );

        const append = isPassword ? (
          field.value ? (
            <Icon
              cursor="pointer"
              onClick={() => setSeePassword((prev) => !prev)}
              source={seePassword ? <EyeOffIcon /> : <EyeIcon />}
            />
          ) : (
            <React.Fragment />
          )
        ) : prependLabel ? (
          <Text>{prependLabel}</Text>
        ) : (
          appendTextAndIcon
        );

        return (
          <InputNimbus
            append={append}
            appendPosition={
              appendLabel || helpText || isPassword ? 'end' : 'start'
            }
            {...(props as React.InputHTMLAttributes<HTMLInputElement> &
              React.RefAttributes<HTMLInputElement>)}
            type={inputType}
            id={id ?? name}
            data-testid={name}
            appearance={!!helpText && !exampleText ? 'danger' : 'neutral'}
            value={value ? value : field.value ?? ''}
            onChange={(e) => {
              const value = e?.target?.value ?? '';
              handleOnChange?.(value);
              if (formatter) {
                formatInput(e, formatter, (value) =>
                  form.setFieldValue(name, value)
                );
              } else {
                form.setFieldValue(name, value);
              }
            }}
            onBlur={(e) => {
              const name = e?.target?.name ?? '';
              const value = e.target.value?.trim();
              form.setFieldValue(name, value);
              props?.onBlur?.(e);
            }}
          />
        );
      }
    },
    [
      multiRows,
      exampleText,
      handleOnChange,
      helpText,
      name,
      props,
      value,
      prependLabel,
      appendLabel,
      isPassword,
      inputType,
      seePassword,
      id,
      rows,
      formatter
    ]
  );

  return (
    <Box {...boxWrapper}>
      {(label || labelIcon) && (
        <Box paddingBottom="2">
          <Label htmlFor={name}>
            <Icon source={labelIcon} />
            {label}
          </Label>
        </Box>
      )}
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <Box position="relative" marginBottom={marginBottom}>
            {Component(field, form)}
            <Box display="flex" flexDirection="row">
              {(helpText || exampleText) && (
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  flex="1"
                  marginTop="1"
                >
                  <Label
                    color={
                      helpText
                        ? 'danger-surface-highlight'
                        : 'neutral-textDisabled'
                    }
                  >
                    {helpText ?? exampleText}
                  </Label>
                </Box>
              )}
              {showQuantity && (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  flex="1"
                  marginTop="1"
                >
                  <Label>{`${field?.value?.length || 0}/${maxLength}`}</Label>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Field>
    </Box>
  );
};

export default Input;
