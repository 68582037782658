import StringMask from './StringMask';

export type TPattern = { pattern: string; reverse?: boolean };

export const formatInput = (
  e: React.ChangeEvent<HTMLInputElement>,
  patterns: TPattern | TPattern[],
  action: (value: string) => void
) => {
  const e2 = e.nativeEvent as unknown as { inputType: string };
  const isBackspaceKey = e2.inputType === 'deleteContentBackward';
  const isDeleteKey = e2.inputType === 'deleteContentForward';
  const isDeleting = isBackspaceKey || isDeleteKey;

  const text = e.target.value;
  const rawText = text.replace(/[^a-zA-Z0-9]/g, '');

  if (!Array.isArray(patterns)) patterns = [patterns];

  let pattern = patterns.find(
    (pattern) => text.length <= pattern.pattern.length
  );

  if (!pattern) pattern = patterns.at(-1);

  if (pattern) {
    const mask = new StringMask(pattern.pattern, {
      reverse: pattern.reverse
    });
    action(!isDeleting ? mask.apply(rawText) : text);
  }
};
