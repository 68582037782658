import React, { useCallback, useMemo } from 'react';
import { Box, Table, Text } from '@nimbus-ds/components';

import { Skeleton } from 'components';
import { IRows } from 'components/Table/tables.types';
import { getKey } from 'components/Table/table.definitions';

function RowsDesktop<T>({
  rows: lines,
  columns,
  perPage,
  isLoading,
  fillWhiteColumns
}: IRows<T>) {
  const rowRender = useCallback(
    (
      field: T,
      row: T,
      formatter?: (field: any, row: any) => string | JSX.Element,
      fillWhiteColumns?: boolean
    ) => {
      if (!field) {
        return fillWhiteColumns ? <Text>-</Text> : <Box height="31px" />;
      }
      return formatter && field ? formatter(field, row) : <Text>{field}</Text>;
    },
    []
  );

  const rows = useMemo(
    () =>
      (isLoading
        ? Array.from({ length: perPage })
        : [
            ...lines,
            ...Array.from({ length: perPage - lines.length }).map(() => ({}))
          ]) as T[],
    [lines, perPage, isLoading]
  );

  return (
    <Table.Body data-testid="desktop-table-row">
      {rows?.map(
        (row, index) =>
          JSON.stringify(row) !== '{}' && (
            <Table.Row
              key={`desktop-${getKey(index)}`}
              data-testid={`desktop-${getKey(index)}`}
              id={`desktop-${getKey(index)}`}
              backgroundColor={
                index % 2 === 0 ? 'neutral-background' : 'neutral-surface'
              }
              padding="base"
            >
              {columns.getColumns().map((column, i) => (
                <Table.Cell key={`${column.field as string}${i}`}>
                  {!isLoading && (
                    <Box
                      padding="2"
                      data-testid={row[column.field]}
                      {...column.options?.boxWrapperColumn}
                    >
                      {rowRender(
                        row[column.field] as unknown as T,
                        row,
                        column.options?.formatter,
                        fillWhiteColumns
                      )}
                    </Box>
                  )}
                  {isLoading && (
                    <Skeleton height="20px" width="100%" borderRadius="4px" />
                  )}
                </Table.Cell>
              ))}
            </Table.Row>
          )
      )}
    </Table.Body>
  );
}

export default RowsDesktop;
