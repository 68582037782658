export const validateCUIT = (cuit: string): boolean => {
  const numericCUIT = cuit.replace(/\D/g, '');

  if (numericCUIT.length !== 11) {
    return false;
  }

  const firstDigit = parseInt(numericCUIT.charAt(0));
  if (firstDigit < 2 || firstDigit > 3) {
    return false;
  }

  const digits = Array.from(numericCUIT, Number);
  const verifier = digits.pop() || 0;
  const weights = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  const sum = digits.reduce(
    (acc, curr, index) => acc + curr * weights[index],
    0
  );
  const remainder = sum % 11;
  const calculatedDigit = remainder !== 0 ? 11 - remainder : 0;

  if (verifier !== calculatedDigit) {
    return false;
  }

  return true;
};

export const validateCBULength = (cbu: string): boolean => cbu.length === 22;

export const validateCBU = (cbu: string): boolean => {
  const numericCBU = cbu.replace(/\D/g, '');

  function validateCBULength(cbu: string): boolean {
    return cbu.length === 22;
  }

  function validateBankCode(code: string): boolean {
    if (code.length !== 8) {
      return false;
    }

    const bank = code.substring(0, 3);
    const verifier1 = parseInt(code[3]);
    const branch = code.substring(4, 7);
    const verifier2 = parseInt(code[7]);

    const sum =
      parseInt(bank[0]) * 7 +
      parseInt(bank[1]) * 1 +
      parseInt(bank[2]) * 3 +
      verifier1 * 9 +
      parseInt(branch[0]) * 7 +
      parseInt(branch[1]) * 1 +
      parseInt(branch[2]) * 3;

    const difference = 10 - (sum % 10);

    return difference === verifier2;
  }

  function validateAccount(account: string): boolean {
    if (account.length !== 14) {
      return false;
    }

    const verifier = parseInt(account[13]);
    const sum =
      parseInt(account[0]) * 3 +
      parseInt(account[1]) * 9 +
      parseInt(account[2]) * 7 +
      parseInt(account[3]) * 1 +
      parseInt(account[4]) * 3 +
      parseInt(account[5]) * 9 +
      parseInt(account[6]) * 7 +
      parseInt(account[7]) * 1 +
      parseInt(account[8]) * 3 +
      parseInt(account[9]) * 9 +
      parseInt(account[10]) * 7 +
      parseInt(account[11]) * 1 +
      parseInt(account[12]) * 3;

    const difference = 10 - (sum % 10);

    return difference === verifier;
  }

  return (
    validateCBULength(numericCBU) &&
    validateBankCode(numericCBU.substring(0, 8)) &&
    validateAccount(numericCBU.substring(8, 22))
  );
};

export function scrollToTop() {
  return document.getElementById('root')?.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}
