import { useCallback, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { useFetchV2, useAuth, useTranslate, useToast } from 'hooks';
import { PaymentsTypingsV2 } from 'types';
import { validate } from './useDetails.definitions';

const useDetails = () => {
  const { addToast } = useToast();
  const { translate } = useTranslate(['api']);
  const { auth } = useAuth();

  const { request } = useFetchV2();
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();

  const query = useQuery(
    ['ledger-details', auth.partnerId],
    () =>
      request<PaymentsTypingsV2.ILedgerDetails>({
        url: `partners/${auth.partnerId}/ledgers/${id}`
      }),
    {
      staleTime: 20000, // 20 seconds
      retry: false,
      onError: () => {
        addToast({
          appearance: 'danger',
          label: translate('api:generic_error')
        });
      }
    }
  );

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries(['ledger-details', auth.partnerId]);
    queryClient.removeQueries(['ledger-details', auth.partnerId]);
  }, [auth.partnerId, queryClient]);

  useEffect(() => {
    return () => {
      invalidateQuery();
    };
  }, [invalidateQuery]);

  return {
    ...query,
    invalidateQuery,
    ...validate(auth.countryCode, query)
  };
};

export default useDetails;
