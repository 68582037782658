import { array, bool, mixed, object, string } from 'yup';
import { IAppScriptModal } from '.';

export const MAX_5MB_SIZE_IN_BYTES = 5242880;

export const initialValues: IAppScriptModal = {
  isAutoInstall: true,
  event: '',
  handle: '',
  location: [],
  file: undefined,
  name: ''
};

export const CreateScriptModalValidate = object().shape({
  isAutoInstall: bool().required(
    'application-create-script-modal:auto-install.empty'
  ),
  event: string()
    .oneOf(['onload', 'onfirstinteraction'])
    .required('application-create-script-modal:event.empty'),
  location: array(bool())
    .min(1, 'application-create-script-modal:location.empty')
    .required(),
  file: mixed()
    .required('application-create-script-modal:create_script_modal_file.empty')
    .test(
      'file size',
      'application-create-script-modal:api.Validation failed (expected size is less than 5242880)',
      (file) => {
        return !file ? false : file.size < MAX_5MB_SIZE_IN_BYTES;
      }
    ),
  name: string().required('application-create-script-modal:name.empty'),
  handle: string()
    .required('application-create-script-modal:handle.empty')
    .matches(
      RegExp(/^[A-Za-z0-9_-]+$/),
      'application-create-script-modal:handle.validation'
    )
});
