export const listCards = [
  {
    link: 'links:regulamentation',
    text: 'partnership-plan:start_platform.LGPD'
  },
  {
    link: 'links:comission_rescue',
    text: 'partnership-plan:start_platform.comission_rescue'
  },
  { link: 'links:terms', text: 'partnership-plan:start_platform.use_terms' },
  {
    link: 'links:policy_terms',
    text: 'partnership-plan:start_platform.privacy_policy'
  }
];
