import { ELocale } from 'app/i18n/i18n.types';

const byPassCodes = ['CO', 'CL'];

export const currency = (
  value?: number | null,
  language = 'pt-BR',
  currency = 'BRL',
  code = 'BR',
  fixedFractionDigits = 2,
  test = false
): string => {
  if (!value || value === null) value = 0;
  if (typeof value === 'string') value = Number(value);
  const isByPass = byPassCodes.includes(code);
  const lang = isByPass ? ELocale.ARGENTINA : language;
  const curr = isByPass ? 'ARS' : currency;
  const formatter = new Intl.NumberFormat(lang, {
    style: 'currency',
    currency: curr,
    maximumFractionDigits: fixedFractionDigits
  });
  const format = formatter?.format(value);
  return test ? format.replace(/\u00a0/g, ' ') : format;
};
