import React from 'react';
import { Trans } from 'react-i18next';
import { Text, Title } from '@tiendanube/components';
import { Card } from 'components';
import { useTranslate } from 'hooks';

import styles from './partnersAdvantageCard.module.scss';
import PartnersAdvantageImage from './partners-advantage.png';
import { advantagesList } from './partnersAdvantageCard.definitions';

const PartnersAdvantageCard = () => {
  const { translate } = useTranslate(['partnership-plan', 'links']);

  return (
    <Card className={styles.card}>
      <Title type="h5">{`${translate(
        'partnership-plan:partners_benefits.title'
      )}`}</Title>

      <div className={styles.container}>
        <img src={PartnersAdvantageImage} alt="vantagens de parceiros" />
        <div className={styles.text_container}>
          <Text>
            <Trans i18nKey="partnership-plan:partners_benefits.subtitle" />
          </Text>
          <ul className={styles.list}>
            {advantagesList.map((item) => (
              <li key={item}>
                <Text>{`${translate(
                  `partnership-plan:partners_benefits.${item}`
                )}`}</Text>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Card>
  );
};

export default PartnersAdvantageCard;
