import { ApplicationsTypings } from 'types';
import { object, string, mixed } from 'yup';

export const initialValues: ApplicationsTypings.IApplicationCreate = {
  name: '',
  visibility: ApplicationsTypings.EApplicationVisibility.PUBLIC
};

export const CreateAppValidation = object({
  name: string()
    .required('application_name.empty')
    .max(35, 'application_name.max'),
  visibility: mixed<ApplicationsTypings.EApplicationVisibility>()
    .oneOf(Object.values(ApplicationsTypings.EApplicationVisibility))
    .required('application_type.empty')
});
