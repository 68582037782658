import React from 'react';
import classNames from 'classnames';
import { Title } from '@tiendanube/components';

import { IHeader } from './header.types';
import './header.styled.scss';

const Header: React.FC<IHeader> = ({
  className,
  title,
  children,
  ...props
}) => {
  const classnames = classNames({
    'card--header__content': true,
    [className || '']: className && true
  });

  return (
    <div {...props} className={classnames}>
      {title && (
        <div className="icon--container">
          <Title type="h3">{title}</Title>
        </div>
      )}
      {children}
    </div>
  );
};

export default Header;
