import React from 'react';
import { Box, IconButton, Popover } from '@nimbus-ds/components';
import {
  CheckIcon,
  EllipsisIcon,
  ForbiddenIcon,
  PlusCircleIcon
} from '@nimbus-ds/icons';
import { AllowedActionsEnum, IActionMenu } from './actionMenu.types';
import ActionMenuItem from '../ActionMenuItem';

const ActionMenu: React.FC<IActionMenu> = ({
  allowedActions,
  scripts,
  currentScript
}) => {
  return (
    <Popover
      width="240px"
      content={
        <Box display="flex" flexDirection="column" gap="2" width="100%">
          <ActionMenuItem
            titleName={'activate'}
            icon={<CheckIcon />}
            currentAllowedActions={allowedActions}
            requirementAction={AllowedActionsEnum.ACTIVATE}
          />

          <ActionMenuItem
            titleName={'disable'}
            icon={<ForbiddenIcon />}
            currentAllowedActions={allowedActions}
            requirementAction={AllowedActionsEnum.DEACTIVATE}
            onClick={async () => {
              scripts.deleteScript.mutate(currentScript.id);
            }}
          />

          <ActionMenuItem
            titleName={'add_version'}
            icon={<PlusCircleIcon />}
            currentAllowedActions={allowedActions}
            requirementAction={AllowedActionsEnum.ADD_VERSION}
          />
        </Box>
      }
      position="bottom"
      arrow={false}
      padding="small"
    >
      <IconButton size="2rem" source={<EllipsisIcon size="small" />} />
    </Popover>
  );
};

export default ActionMenu;
