export enum ELedgerEntryType {
  AFFILIATE = 'affiliate',
  APP_PAYMENT = 'app payment',
  INITIAL_BALANCE = 'initial balance',
  PARTNER_BONUS = 'partner bonus',
  PAYOUT = 'payout',
  PARTNER_COMMISSION = 'partner commission'
}

export enum ELedgerEntryTypeOther {
  CORE_PAYOUT = 'core payout'
}
