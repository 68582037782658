import { PURGE } from 'redux-persist';
import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { IAlert, ICommons } from './commons.types';

export const initialStateCommons = {
  helpModal: false,
  welcomeModal: true,
  showAppTutorial: true
} as ICommons;

const setAlert: CaseReducer<ICommons, PayloadAction<IAlert | undefined>> = (
  state,
  action
) => {
  state.alert = action.payload;
};

const setShowAppTutorial: CaseReducer<ICommons, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.showAppTutorial = action.payload;
};

const setHelpModal: CaseReducer<ICommons, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.helpModal = action.payload;
};

const setWelcomeModal: CaseReducer<ICommons, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.welcomeModal = action.payload;
};
const setIsNewPortal: CaseReducer<ICommons, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.isNewPortal = action.payload;
};

const commonsSlice = createSlice({
  name: 'commons',
  initialState: initialStateCommons,
  reducers: {
    setAlertAction: setAlert,
    setHelpModalAction: setHelpModal,
    setWelcomeModalAction: setWelcomeModal,
    setIsNewPortalAction: setIsNewPortal,
    setShowAppTutorialAction: setShowAppTutorial
  },
  extraReducers: (builder) => {
    /* istanbul ignore next */
    builder.addCase(PURGE, () => initialStateCommons);
  }
});

export const {
  setAlertAction,
  setHelpModalAction,
  setWelcomeModalAction,
  setIsNewPortalAction,
  setShowAppTutorialAction
} = commonsSlice.actions;

export const { reducer: reducerCommons } = commonsSlice;
