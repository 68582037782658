import React, { memo } from 'react';
import { MenuItems } from './widgets';
import { Menu } from '@nimbus-ds/patterns';

const MenuDesktop: React.FC = () => (
  <Menu>
    <MenuItems />
  </Menu>
);

export default memo(MenuDesktop);
