import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import { Loading } from 'components';
import store, { persistor } from './store';

interface ReduxProviderInterface {
  children: React.ReactNode;
}

const ReduxProvider: React.FC<ReduxProviderInterface> = ({
  children
}: ReduxProviderInterface) => (
  <Provider store={store}>
    <PersistGate loading={<Loading size="large" />} persistor={persistor}>
      {children}
    </PersistGate>
  </Provider>
);

export default ReduxProvider;
