import React from 'react';
import { useTranslate } from 'hooks';
import { Icon, Link, Text } from '@nimbus-ds/components';
import { IActionMenuItem } from './actionMenuItem.types';

const ActionMenuItem: React.FC<IActionMenuItem> = ({
  titleName,
  icon,
  currentAllowedActions,
  requirementAction,
  onClick
}) => {
  const { translate } = useTranslate(['application-list-scripts']);
  const text = `application-list-scripts:buttons.${titleName}`;

  const isVisible =
    !currentAllowedActions || !requirementAction
      ? true
      : currentAllowedActions.includes(requirementAction);

  return (
    <>
      {isVisible && (
        <Link
          as="button"
          textDecoration="none"
          onClick={async () => {
            if (!onClick) {
              return;
            }
            await onClick();
          }}
        >
          <Icon source={icon} color="neutral-textHigh" />
          <Text as="span" color="neutral-textHigh">
            {translate(text)}
          </Text>
        </Link>
      )}
    </>
  );
};

export default ActionMenuItem;
