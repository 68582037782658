import { useLayoutEffect, useState } from 'react';

const useLoading = (isLoadingParams: boolean[]) => {
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    const isAnyLoading = isLoadingParams?.includes?.(true);
    setIsLoading(isAnyLoading);
  }, [isLoadingParams]);

  return isLoading;
};

export default useLoading;
