import { AppsScriptsTypings } from 'types';

export interface IActionMenu {
  allowedActions: string[];
  scripts: any;
  currentScript: AppsScriptsTypings.AppScript;
}

export enum AllowedActionsEnum {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  ADD_VERSION = 'add-version'
}
