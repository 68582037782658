import { TCurrency } from 'app/i18n/i18n.types';
import { PaymentsTypingsV2 } from 'types';
import {
  ELedgerEntryType,
  ELedgerEntryTypeOther
} from 'types/payments/v2/payments.typings';

export const getPartnerEntryType = (status: string, origin: number) => {
  const isRescue =
    getPartnerEntryTypeOrigin(origin) === ELedgerEntryType.PAYOUT;
  if (status === 'paid' || isRescue) return 'rescue';
  return 'credit';
};

const entryTypeMapping = {
  1: ELedgerEntryType.AFFILIATE,
  2: ELedgerEntryType.APP_PAYMENT,
  3: ELedgerEntryType.INITIAL_BALANCE,
  4: ELedgerEntryType.PARTNER_BONUS,
  5: ELedgerEntryType.PAYOUT,
  6: ELedgerEntryType.PARTNER_COMMISSION,
  7: ELedgerEntryTypeOther.CORE_PAYOUT
};

export const getPartnerEntryTypeOrigin = (origin: number) => {
  return entryTypeMapping[origin as 1 | 2 | 3 | 4 | 5 | 6 | 7] || '-';
};

export const getPartnerOriginEntryType = (
  entryType: ELedgerEntryType | ELedgerEntryTypeOther
) => {
  switch (entryType) {
    case ELedgerEntryType.AFFILIATE:
      return 1;
    case ELedgerEntryType.APP_PAYMENT:
      return 2;
    case ELedgerEntryType.INITIAL_BALANCE:
      return 3;
    case ELedgerEntryType.PARTNER_BONUS:
      return 4;
    case ELedgerEntryType.PAYOUT:
      return 5;
    case ELedgerEntryType.PARTNER_COMMISSION:
      return 6;
    case ELedgerEntryTypeOther.CORE_PAYOUT:
      return 7;
    default:
      return undefined;
  }
};

export const appearanceTagStatusLedger = {
  [PaymentsTypingsV2.ELedgerStatus.PAID]: 'success',
  [PaymentsTypingsV2.ELedgerStatus.CANCELED]: 'danger',
  [PaymentsTypingsV2.ELedgerStatus.AVAILABLE]: 'primary',
  [PaymentsTypingsV2.ELedgerStatus.PENDING]: 'warning',
  [PaymentsTypingsV2.ELedgerStatus.WITHDRAWAL_REQUESTED]: 'neutral'
} as {
  [key: string]: 'success' | 'primary' | 'danger' | 'neutral' | 'warning';
};

export const getOriginalCurrency = (currency: string) => {
  const key = currency as TCurrency;
  const obj = {
    ARS: { language: 'es-AR', currency, code: 'AR' },
    BRL: { language: 'pt-BR', currency, code: 'BR' },
    MXN: { language: 'es-MX', currency, code: 'MX' },
    COP: { language: 'es-CO', currency, code: 'CO' },
    CLP: { language: 'es-CL', currency, code: 'CL' }
  };
  return obj[key] ?? obj.BRL;
};
