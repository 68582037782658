import { useTranslate } from 'hooks';
import React from 'react';
import { ELocale } from 'app/i18n/i18n.types';
import { ReactComponent as LogoNuvemParners } from './nuvem-partners.svg';
import { ReactComponent as LogoNuvemParnersNegative } from './nuvem-partners-negative.svg';
import { ReactComponent as LogoNubeParners } from './nube-partners.svg';
import { ReactComponent as LogoNubeParnersNegative } from './nube-partners-negative.svg';
import { useDarkMode } from '../DarkMode/DarkModeProvider';

const Logo: React.FC = () => {
  const { language } = useTranslate('');
  const darkModeHook = useDarkMode();

  if (language === ELocale.BRAZIL) {
    if (darkModeHook?.darkMode) {
      return <LogoNuvemParnersNegative width={160} />;
    }
    return <LogoNuvemParners width={160} />;
  }

  if (darkModeHook?.darkMode) {
    return <LogoNubeParnersNegative width={160} />;
  }

  return <LogoNubeParners width={160} />;
};

export default Logo;
