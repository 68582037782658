import React, { useState } from 'react';
import { Button, Text, Card } from '@nimbus-ds/components';

import { useTranslate } from 'hooks';
import { useHistory } from 'react-router-dom';

import SealModal from './SealModal';
import { ISeal } from './seal.types';
import { ROUTES } from 'commons/constants';

const Seal: React.FC<ISeal> = ({ seal, banners }) => {
  const { translate } = useTranslate(['home', 'links']);

  const [modalState, setModalState] = useState(false);
  const history = useHistory();

  const handleModal = () => setModalState((prevState) => !prevState);
  const handleBanners = () => history.push(ROUTES.BANNERS, { banners });

  return (
    <Card>
      <Card.Header title={translate('home:cards.seal.title')} />
      <Card.Body>
        <Text>{`${translate(`home:cards.seal.subtitle`)}`}</Text>
        <SealModal seal={seal} show={modalState} onDismiss={handleModal} />
      </Card.Body>
      <Card.Footer>
        <Button appearance="neutral" onClick={handleBanners}>
          {`${translate('home:cards.seal.button_show_banners')}`}
        </Button>
        <Button appearance="neutral" onClick={handleModal}>
          {`${translate('home:cards.seal.button_show_seal')}`}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default Seal;
