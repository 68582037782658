import { createContext, useContext } from 'react';

import { IItemContext } from './item.types';

export const context = createContext<IItemContext>({
  index: '',
  testId: ''
} as IItemContext);
context.displayName = 'AccordionItemContext';

export const useAccordionItem: () => IItemContext = () => useContext(context);
export default context;
