import React from 'react';
import classNames from 'classnames';
import { CopyIcon } from '@nimbus-ds/icons';

import { ICodeEditor } from './codeEditor.types';
import styles from './codeEditor.module.scss';

const CodeEditor: React.FC<ICodeEditor> = ({ className, value, onCopy }) => {
  const classnames = classNames({
    [styles.wrapper]: true,
    [className || '']: className && true
  });

  return (
    <>
      <div className={classnames}>
        {value && (
          <>
            {onCopy && (
              <button className={styles.icon} onClick={onCopy}>
                <CopyIcon size={'medium'} />
              </button>
            )}
            <textarea className={styles.textarea} readOnly value={value} />
          </>
        )}
      </div>
    </>
  );
};

export default CodeEditor;
