import { rand, randCurrencyCode, randNumber, randUuid } from '@ngneat/falso';
import { ECountryLanguage, IUser, TCountryCode } from '../auth.typings';

export class User implements IUser {
  constructor(
    public accessToken = randUuid(),
    public countryId = randNumber(),
    public partnerId = randNumber(),
    public countryCode = rand(['BR', 'MX', 'AR', 'CL', 'CO'] as TCountryCode[]),
    public currency = randCurrencyCode(),
    public countryLanguage = rand(Object.values(ECountryLanguage)),
    public employeeId = `${randNumber()}`
  ) {}

  static generate(): User {
    return new User();
  }

  removeProperties(keys?: keyof IUser | (keyof IUser)[]): IUser {
    typeof keys == 'string'
      ? delete this[keys]
      : keys?.forEach((key) => {
          delete this[key];
        });

    return this;
  }
}
