import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@tiendanube/components';

import { Card } from 'components';
import { useTranslate } from 'hooks';
import { ROUTES } from 'commons/constants';

const ExternalCodes: React.FC = () => {
  const { translate } = useTranslate('account');
  const history = useHistory();
  return (
    <Card className="account__card">
      <Card.Header title={translate('title.external_codes')} />
      <Card.Body
        className="account__card--body"
        message={translate('subtitle.external_codes')}
      />
      <Card.Footer>
        <Button onClick={() => history.push(ROUTES.ACCOUNT.EXTERNAL_CODES)}>
          {`${translate('buttons.access')}`}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default ExternalCodes;
