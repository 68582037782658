import React from 'react';
import { FastField, FieldProps } from 'formik';
import { Checkbox as CheckboxNimbus, Box } from '@nimbus-ds/components';
import { ICheckbox } from './checkbox.types';

const Checkbox: React.FC<ICheckbox> = ({
  name,
  id,
  boxWrapper,
  value,
  ...props
}) => (
  <Box {...boxWrapper}>
    <FastField type="checkbox" name={name} value={value}>
      {({ field }: FieldProps) => (
        <CheckboxNimbus
          {...props}
          {...field}
          name={name}
          id={id}
          data-testid={id}
        />
      )}
    </FastField>
  </Box>
);

export default Checkbox;
