import React from 'react';
import { FileUploader as FileUploaderNimbus } from '@nimbus-ds/components';
import { Field, FieldProps } from 'formik';

import { IFileUploader } from './fileUploader.types';

const FileUploader: React.FC<IFileUploader> = ({
  name,
  placeholder,
  width,
  height,
  accept = '*',
  helperToast: helperText = undefined,
  maxSize = undefined
}) => {
  return (
    <Field type="file" name={name}>
      {({ field, form }: FieldProps) => (
        <FileUploaderNimbus
          accept={accept}
          {...field}
          type="file"
          onChange={(event) => {
            const file = event?.currentTarget?.files?.[0];
            if (helperText && maxSize && file && file.size > maxSize) {
              helperText();
              return;
            }

            return form.setFieldValue(name, file);
          }}
          placeholder={placeholder}
          width={width}
          height={height}
          flexDirection="row"
          name="file"
          value=""
        />
      )}
    </Field>
  );
};

export default FileUploader;
