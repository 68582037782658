import { useSelector } from 'react-redux';
import { getCommons } from 'redux/selectors';
import {
  setAlertAction,
  setHelpModalAction,
  setIsNewPortalAction,
  setWelcomeModalAction,
  setShowAppTutorialAction
} from 'redux/slices';

import { IAlert } from 'redux/slices/commons/commons.types';
import { useAppDispatch } from 'app/ReduxProvider/store';
import { IUseCommons } from './useCommons.types';

const useCommons = (): IUseCommons => {
  const dispatch = useAppDispatch();
  const commons = useSelector(getCommons);

  const setAlert = (alert?: IAlert) => dispatch(setAlertAction(alert));

  const setHelpModal = (helpModal: boolean) =>
    dispatch(setHelpModalAction(helpModal));

  const setWelcomeModal = (welcomeModal: boolean) =>
    dispatch(setWelcomeModalAction(welcomeModal));

  const setIsNewPortal = (isNewPortal: boolean) =>
    dispatch(setIsNewPortalAction(isNewPortal));

  const setShowAppTutorial = (showAppTutorial: boolean) =>
    dispatch(setShowAppTutorialAction(showAppTutorial));

  return {
    commons,
    setAlert,
    setHelpModal,
    setWelcomeModal,
    setIsNewPortal,
    setShowAppTutorial
  };
};

export default useCommons;
