import React from 'react';
import classNames from 'classnames';

import { Title } from '@tiendanube/components';
import { IHeader } from './header.types';
import './header.styled.scss';

const Header: React.FC<IHeader> = ({ children, className, title, testId }) => {
  const classnames = classNames({
    'modal--header': true,
    [className || '']: className && true
  });
  return (
    <div className={classnames} data-testid={testId}>
      {title && <Title type="h1">{title}</Title>}
      {children}
    </div>
  );
};

export default Header;
