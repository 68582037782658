import { useSelector } from 'react-redux';

import { useAppDispatch } from 'app/ReduxProvider/store';
import { handleAuthAction } from 'redux/slices';
import { getAuth } from 'redux/selectors';
import { AuthTypings } from 'types';
import { IUseAuth } from './useAuth.types';

const useAuth = (): IUseAuth => {
  const dispatch = useAppDispatch();
  const auth = useSelector(getAuth);

  const setAuth = (auth: AuthTypings.IUser) => dispatch(handleAuthAction(auth));

  return {
    auth,
    setAuth
  };
};

export default useAuth;
