import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ROUTES } from 'commons/constants';

const Stores = React.lazy(() => import('./Stores'));
const Create = React.lazy(() => import('./pages/Create'));
const Details = React.lazy(() => import('./pages/Details'));

const Routes: React.FC = () => (
  <Switch>
    <Route path={ROUTES.STORES.DEFAULT} exact>
      <Stores />
    </Route>
    <Route path={ROUTES.STORES.CREATE} exact>
      <Create />
    </Route>
    <Route path={ROUTES.STORES.DETAILS} exact>
      <Details />
    </Route>
    <Redirect from="*" to={ROUTES.NOT_FOUND} />
  </Switch>
);

export default Routes;
