import React from 'react';
import { Button } from '@tiendanube/components';

import { ROUTES } from 'commons/constants';
import { CommonPage } from 'components';
import { useTranslate } from 'hooks';

const NotFound: React.FC = () => {
  const { translate } = useTranslate('not-found');
  return (
    <CommonPage
      title={translate('titles.ops')}
      subtitle={translate('subtitles.not_found')}
      legend={translate('subtitles.error_404')}
    >
      <Button
        appearance="primary"
        onClick={() => {
          window.location.href = ROUTES.HOME;
        }}
      >
        {`${translate('links.initial')}`}
      </Button>
    </CommonPage>
  );
};

export default NotFound;
