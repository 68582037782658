import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import { persistor } from 'app/ReduxProvider/store';
import { ROUTES } from 'commons/constants';
import { useCommons, useTranslate, useFetchV2 } from 'hooks';
import { IUseLogout, IMessage } from './useLogout.types';

const useLogout = (): IUseLogout => {
  const history = useHistory();
  const { request } = useFetchV2();
  const { setAlert, commons } = useCommons();
  const { translate } = useTranslate('common');
  const query = useQueryClient();

  const onLogout = useMutation(
    () =>
      request({
        url: '/auth/logout',
        method: 'POST'
      }),
    {
      onSuccess: async () => {
        localStorage.removeItem('token');
        query.clear();
        await purgeStore();
        //TODO: FIND SOME SOLUTION TO UPDATE ALERT AFTER PURGE
        setTimeout(() => {
          sendMessage({
            appearance: 'success',
            title: 'alerts.logoff.title',
            message: 'alerts.logoff.message'
          });
        }, 510);
      }
    }
  );

  const purgeStore = useCallback(async () => {
    await persistor.purge();
    history.push(ROUTES.DEFAULT);
  }, [history]);

  const sendMessage = useCallback(
    (message: IMessage) => {
      setAlert({
        ...commons?.alert,
        login: {
          appearance: message.appearance,
          title: translate(message.title),
          message: translate(message.message)
        }
      });
    },
    [setAlert, commons?.alert, translate]
  );

  return { onLogout: onLogout.mutate, sendMessage, purgeStore };
};

export default useLogout;
