import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducerCommons, reducerAuth } from 'redux/slices';

const authPersistConfig = {
  key: 'auth',
  storage: storage
};

const commonsPersistConfig = {
  key: 'commons',
  storage: storage,
  blacklist: ['helpModal', 'alert']
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, reducerAuth),
  commons: persistReducer(commonsPersistConfig, reducerCommons)
});

export default rootReducer;
