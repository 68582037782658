export const links = {
  BR: {
    termOfUse: 'https://www.nuvemshop.com.br/termos-de-uso-partners',
    policyTerms: 'https://www.nuvemshop.com.br/politica-de-privacidade'
  },
  MX: {
    termOfUse: 'https://www.tiendanube.com.mx/terminos-de-uso',
    policyTerms: 'https://www.tiendanube.com.mx/politicas-de-privacidad'
  },
  AR: {
    termOfUse: 'https://www.tiendanube.com/socios/terminos-de-uso',
    policyTerms: 'https://www.tiendanube.com/politicas-de-privacidad'
  },
  CO: {
    termOfUse: 'https://www.tiendanube.com/co/socios/terminos-de-uso',
    policyTerms: 'https://www.tiendanube.com/co/politicas-de-privacidad'
  },
  CL: {
    termOfUse: 'https://www.tiendanube.com/cl/socios/terminos-de-uso',
    policyTerms: 'https://www.tiendanube.com/cl/politicas-de-privacidad'
  }
} as { [key: string]: { termOfUse: string; policyTerms: string } };
