import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ROUTES } from 'commons/constants';

const Home = React.lazy(() => import('./Home'));
const Banners = React.lazy(() => import('./pages/Banners'));

const Routes: React.FC = () => (
  <Switch>
    <Route path={ROUTES.HOME} exact>
      <Home />
    </Route>
    <Route path={ROUTES.BANNERS} exact>
      <Banners />
    </Route>
    <Redirect from="*" to={ROUTES.NOT_FOUND} />
  </Switch>
);

export default Routes;
