import React, { useCallback, useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { useLogout } from 'domains/Account/hooks';
import axiosApiInstance from './axiosApiInstance';

const UNAUTHORIZED = 401;

const byPassRoutes = ['/auth/logout'];

const AxiosInterceptors: React.FC = ({ children }) => {
  const { onLogout, sendMessage, purgeStore } = useLogout();

  const setupInterceptors = useCallback(() => {
    axiosApiInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        Sentry.captureException(error);
        if (error?.response?.status === UNAUTHORIZED) {
          if (byPassRoutes.some((url) => url === error.response.config.url)) {
            purgeStore();
            sendMessage({
              appearance: 'warning',
              title: 'alerts.expired_session.title',
              message: 'alerts.expired_session.message'
            });
          } else {
            onLogout();
          }
        }
        return Promise.reject(error);
      }
    );
  }, [onLogout, sendMessage, purgeStore]);

  useEffect(() => setupInterceptors(), [setupInterceptors]);

  return <>{children}</>;
};

export default AxiosInterceptors;
