import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { GoogleReCaptchaProvider as GoogleReCaptcha } from 'react-google-recaptcha-v3';

import { ROUTES } from 'commons/constants';

const Login = React.lazy(() => import('./pages/Login'));
const Signup = React.lazy(() => import('./pages/Signup'));
const SignupSuccess = React.lazy(() => import('./pages/SignupSuccess'));
const RequestChangePassword = React.lazy(
  () => import('./pages/RequestChangePassword')
);
const Introduction = React.lazy(() => import('./pages/Introduction'));
const Stats = React.lazy(() => import('./pages/Stats'));

const LoginSkeleton = React.lazy(() => import('./pages/Login/Login.skeleton'));
const SignupSkeleton = React.lazy(
  () => import('./pages/Signup/Signup.skeleton')
);
const RequestChangePasswordSkeleton = React.lazy(
  () => import('./pages/RequestChangePassword/RequestChangePassword.skeleton')
);
const IntroductionSkeleton = React.lazy(
  () => import('./pages/Introduction/Introduction.skeleton')
);

const Routes: React.FC = () => (
  <Switch>
    <Route path={ROUTES.AUTH.INTRODUCTION} exact>
      <Suspense fallback={<IntroductionSkeleton />}>
        <Introduction />
      </Suspense>
    </Route>
    <Route path={ROUTES.AUTH.STATS} exact>
      <Stats />
    </Route>
    <GoogleReCaptcha reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA}>
      <Switch>
        <Route path={ROUTES.DEFAULT} exact>
          <Suspense fallback={<LoginSkeleton />}>
            <Login />
          </Suspense>
        </Route>
        <Route path={ROUTES.AUTH.CREATE_ACCOUNT} exact>
          <Suspense fallback={<SignupSkeleton />}>
            <Signup />
          </Suspense>
        </Route>
        <Route path={ROUTES.AUTH.SIGNUP_SUCCESS} exact>
          <SignupSuccess />
        </Route>
        <Route
          path={[ROUTES.AUTH.NEW_PASSWORD, ROUTES.AUTH.FORGOT_PASSWORD]}
          exact
        >
          <Suspense fallback={<RequestChangePasswordSkeleton />}>
            <RequestChangePassword />
          </Suspense>
        </Route>
        <Redirect
          from="*"
          to={{
            pathname: ROUTES.DEFAULT,
            state: { from: window.location.pathname }
          }}
        />
      </Switch>
    </GoogleReCaptcha>
    <Redirect
      from="*"
      to={{
        pathname: ROUTES.DEFAULT,
        state: { from: window.location.pathname }
      }}
    />
  </Switch>
);

export default Routes;
