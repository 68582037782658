import { useState, useMemo, useCallback } from 'react';
import { useQuery } from 'hooks';
import { IUsePagination } from './usePagination.types';

const usePagination = (perPage: number, totalItems = 0): IUsePagination => {
  const [query] = useQuery();
  const queryPage = query.get('page');
  const [page, setPage] = useState<number>(
    queryPage ? Number(queryPage) - 1 : 0
  );

  const getTotalPages = useCallback(
    (totalItems) => {
      const total = Math.ceil(totalItems / perPage);
      return total > 0 ? total : 1;
    },
    [perPage]
  );

  return {
    skip: useMemo(() => page * perPage, [page, perPage]),
    totalPages: useMemo(
      () => getTotalPages(totalItems),
      [totalItems, getTotalPages]
    ),
    page: useMemo(() => page + 1, [page]),
    onChangePage: useCallback((value: number) => setPage(value - 1), [setPage]),
    getTotalPages,
    setPage
  };
};

export default usePagination;
