import React from 'react';
import classNames from 'classnames';

import Box from 'components/Box';
import { IFilters, IFiltersComponents } from './filters.types';
import { Item } from './widgets';
import styles from './filters.module.scss';

const Filters: React.FC<IFilters> & IFiltersComponents = ({
  children,
  className
}: IFilters) => {
  return (
    <Box
      className={classNames({
        [styles.flex]: true,
        [className || '']: className && true
      })}
    >
      {children}
    </Box>
  );
};

Filters.Item = Item;

export default Filters;
