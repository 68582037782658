import React, { createContext, useContext, useState } from 'react';
import { ToastProvider } from '@nimbus-ds/components';

const ShowCreateScriptModalContext = createContext<{
  open: boolean;
  setOpen?: (open: boolean) => void;
}>({
  open: false
});

const useCreateScriptModal = () => {
  const [open, setOpen] = useState(false);
  const CreateScriptModalProvider = ({
    children
  }: {
    children: React.ReactNode;
  }) => (
    <ToastProvider>
      <ShowCreateScriptModalContext.Provider value={{ open, setOpen }}>
        {children}
      </ShowCreateScriptModalContext.Provider>
    </ToastProvider>
  );
  const context = useContext(ShowCreateScriptModalContext);
  return {
    context,
    CreateScriptModalProvider
  };
};

export default useCreateScriptModal;
