import React, { createRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { CloseIcon } from '@nimbus-ds/icons';

import { Header, Body } from './widgets';
import { IModal, IModalComponents } from './modal.types';
import './modal.styled.scss';

const Modal: React.FC<IModal> & IModalComponents = ({
  children,
  onDismiss,
  className,
  appearance,
  show,
  testId,
  id,
  isStatic
}) => {
  const modalRef = createRef<HTMLDivElement>();
  const rootElement = document.getElementById('root') as HTMLElement;
  const classnames = classNames({
    'modal--content': true,
    [appearance || '']: appearance,
    [className || '']: className && true
  });

  const handleClickOutside = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if ((event.target as HTMLElement).className === 'modal' && !isStatic) {
        onDismiss?.();
      }
    },
    [onDismiss, isStatic]
  );

  useEffect(() => {
    if (show) {
      modalRef.current?.focus();
    }
  }, [modalRef, show]);

  return ReactDOM.createPortal(
    show ? (
      <div className="modal" onClick={handleClickOutside} data-testid="modal">
        <div
          className={classnames}
          data-testid={testId}
          id={id}
          role="dialog"
          aria-modal="true"
          ref={modalRef}
          tabIndex={0}
        >
          <button
            type="button"
            aria-label="Close"
            className="modal--header__close"
            onClick={onDismiss}
          >
            <CloseIcon />
          </button>
          {children}
        </div>
      </div>
    ) : null,
    rootElement
  );
};

Modal.Header = Header;
Modal.Body = Body;

export default Modal;
