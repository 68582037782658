import { useCallback } from 'react';
import { useFetch } from 'hooks';
import { AuthTypings } from 'types';
import { TUseRecaptcha } from './useRecaptcha.types';

const useRecaptcha = (): TUseRecaptcha => {
  const [validateRecaptcha, { loading: recaptchaLoading, data: token }] =
    useFetch<string>('/auth/validate-recaptcha', {
      fallbackError: {
        execute: true
      },
      notToast: true,
      messages: { messageError: 'Erro ao validar o recaptcha, tente novamente' }
    });

  const onValidateRecaptcha = useCallback(
    (token: AuthTypings.IvalidateRecaptcha) =>
      validateRecaptcha({
        method: 'POST',
        data: token
      }),
    [validateRecaptcha]
  );

  return [onValidateRecaptcha, { recaptchaLoading, token }];
};

export default useRecaptcha;
