import React from 'react';
import { Text } from '@nimbus-ds/components';
import { IconProps } from '@nimbus-ds/icons';
import { IBlankPageItem } from './item.types';

import './item.styled.scss';

const Item: React.FC<IBlankPageItem> = ({
  icon,
  text,
  ariaLabel
}: IBlankPageItem) => {
  const IconComponent = icon as React.FC<IconProps>;
  return (
    <div className="blankpage--info__item">
      <div className="blankpage--info__item--icon">
        <IconComponent size="medium" aria-label={ariaLabel} />
      </div>
      {text && <Text>{text}</Text>}
    </div>
  );
};

export default Item;
