import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import CreateAppModalProvider from 'domains/Applications/pages/CreateApp';
import AxiosInterceptors from 'commons/utils/axios/axiosInterceptors';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { ROUTES } from 'commons/constants';
import { HelpModal } from 'components';
import { useTranslate, useDocumentTitle } from 'hooks';
import { AppRoutes, ScrollToTop } from './widgets';

const Router: React.FC = () => {
  const { userConfirmation } = useModalConfirmation();
  const { translate } = useTranslate('common');
  useDocumentTitle(translate('title'));

  return (
    <BrowserRouter
      basename={ROUTES.DEFAULT}
      getUserConfirmation={userConfirmation}
    >
      <ScrollToTop />
      <CreateAppModalProvider>
        <AxiosInterceptors>
          <QueryParamProvider ReactRouterRoute={Route}>
            <AppRoutes />
          </QueryParamProvider>
        </AxiosInterceptors>
      </CreateAppModalProvider>
      <HelpModal />
    </BrowserRouter>
  );
};

export default Router;
