import React from 'react';
import { Link } from 'react-router-dom';
import { Link as NimbusLink, Card } from '@nimbus-ds/components';
import { CalloutCard } from '@nimbus-ds/patterns';
import { useTranslate } from 'hooks';

import styles from './alerts.module.scss';
import { cardAlerts } from './alerts.definitions';
import { IAlertCardItem } from './alerts.types';

const Alerts: React.FC<{ isTechPartner?: boolean }> = ({
  isTechPartner = false
}) => {
  const { translate } = useTranslate(['home', 'links']);

  const renderCards = cardAlerts(isTechPartner)?.map((item: IAlertCardItem) => {
    const CalloutCardComponent = (
      <CalloutCard
        appearance="primary"
        icon={item?.icon}
        title={`${translate(`home:cards.alerts.${item?.title}`)}`}
        subtitle={`${translate(`home:cards.alerts.${item?.subtitle}`)}`}
      />
    );
    if (item?.linkType === 'nimbus') {
      return (
        <div key={item.title} className={styles.alerts}>
          <NimbusLink as="a" href={`${translate(`links:${item.link}`)}`}>
            {CalloutCardComponent}
          </NimbusLink>
        </div>
      );
    }
    return (
      <Link
        key={item.title}
        to={item.link}
        className={styles.alerts}
        target="_self"
      >
        {CalloutCardComponent}
      </Link>
    );
  });

  return (
    <Card>
      <Card.Header title={translate(`home:cards.alerts.title`)} />
      <Card.Body>{renderCards}</Card.Body>
    </Card>
  );
};

export default Alerts;
