import React from 'react';
import { Text } from '@nimbus-ds/components';
import { Trans } from 'react-i18next';

import { ENABLE_RECAPTCHA } from 'commons/constants';
import { useTranslate } from 'hooks';
import './recaptchaText.styled.scss';

const RecaptchaText: React.FC = () => {
  const { translate } = useTranslate(['commons', 'links']);
  if (!ENABLE_RECAPTCHA) return null;

  return (
    <div className="recaptcha-container">
      <Text>
        <Trans
          i18nKey={translate('commons:links.recaptcha')}
          components={[
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              key="policy_privacy"
              className="recaptcha--link"
              href={translate('links:recaptcha_privacy_policy')}
              target="_blank"
              rel="noreferrer"
            />,
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              className="recaptcha--link"
              key="terms"
              href={translate('links:recaptcha_terms')}
              target="_blank"
              rel="noreferrer"
            />
          ]}
        />
      </Text>
    </div>
  );
};

export default RecaptchaText;
