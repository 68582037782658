import React from 'react';
import { Box, Title, Text } from '@nimbus-ds/components';

import { useTopMenu, useTranslate } from 'hooks';

import {
  AccessPassword,
  ExternalCodes,
  Registration,
  Revenue,
  Session
} from './widgets';

const Account: React.FC = () => {
  const { translate } = useTranslate('account');
  useTopMenu();
  return (
    <>
      <Box flexDirection="column" padding="6">
        <Title>{`${translate('title.my_account')}`}</Title>
        <Text>{translate('subtitle.my_account')}</Text>
        <Box marginTop="6">
          <Box display="flex" flexDirection={{ lg: 'row', xs: 'column' }}>
            <Box
              display="flex"
              flexDirection="column"
              width={{ lg: '50%', xs: '100%' }}
              paddingRight={{ lg: '4', xs: 'none' }}
            >
              <Box display="flex" flexDirection="column" gap="4">
                <Registration />
                <AccessPassword />
                <Revenue />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              width={{ lg: '50%', xs: '100%' }}
            >
              <Box display="flex" flexDirection="column" gap="4">
                <ExternalCodes />
                <Session />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Account;
