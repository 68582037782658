import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Icon, IconProps, Popover } from '@nimbus-ds/components';
import { AppShell } from '@nimbus-ds/patterns';
import { MenuDesktop, MenuMobile } from 'components/Menu';
import { useCommons, useTranslate } from 'hooks';
import {
  ChevronLeftIcon,
  EllipsisIcon,
  QuestionCircleIcon
} from '@nimbus-ds/icons';
import { IAppLayout } from './appLayout.types';
import { useAppLayoutContext } from './AppLayoutProvider';

const AppLayout: React.FC<IAppLayout> = ({ children }: IAppLayout) => {
  const { translate } = useTranslate(['common', 'links']);
  const { setHelpModal } = useCommons();
  const [rightMenuVisible, setRightMenuVisible] = useState(false);
  //TODO: enable this when nimbus be fully replaced
  //const { toggleDarkMode, darkMode } = useDarkMode();

  const context = useAppLayoutContext();

  const menuButton = useCallback((item) => {
    const Icon = item.icon as React.FC<IconProps>;
    return (
      <Button
        key={item.children}
        onClick={item.onClick}
        appearance="transparent"
      >
        {item.icon && (
          <Icon aria-label={item.children} source={<item.icon />} />
        )}
        {item.children}
      </Button>
    );
  }, []);

  const menuButtons = useMemo(() => {
    const defaultMenuItems = [
      {
        children: translate('common:menus.help'),
        onClick: () => setHelpModal(true),
        icon: QuestionCircleIcon
      }
      //TODO: Enable this button when nimbus be fully replaced
      /* {
        children: '',
        onClick: () => toggleDarkMode(),
        icon: darkMode ? MoonIcon : SunIcon
      } */
    ];

    const defaultMenuItemsMobile = [
      ...(context?.items ?? []),
      ...defaultMenuItems
    ];

    const defaultMenuItemsDesktop = defaultMenuItems;

    return (
      <>
        <Box display={{ xs: 'flex', md: 'none' }} flexDirection="column">
          {defaultMenuItemsMobile.map((item) => menuButton(item))}
        </Box>
        <Box display={{ xs: 'none', md: 'flex' }} flexDirection="row">
          {defaultMenuItemsDesktop.map((item) => menuButton(item))}
        </Box>
      </>
    );
  }, [setHelpModal, translate, context?.items, menuButton]);

  const showMobileButtons = (
    <Box display="flex" flexDirection="row">
      {context?.actions?.map((item) => menuButton(item))}
      <Popover
        enabledClick
        content={menuButtons}
        visible={rightMenuVisible}
        onVisibility={setRightMenuVisible}
        arrow={false}
        width="fit-content"
        padding="small"
        position="bottom-end"
        backgroundColor="neutral-background"
      >
        <Button data-testid="mobile-menu-button" appearance="transparent">
          <Icon source={<EllipsisIcon size={24} />} />
        </Button>
      </Popover>
    </Box>
  );

  const backButton = context?.backButton?.title && (
    <Button
      appearance="transparent"
      data-testid="back-button"
      onClick={() => context?.backButton?.onClick?.()}
    >
      <Icon source={<ChevronLeftIcon />} />
      {context?.backButton?.title}
    </Button>
  );

  const mobileMenuButton = <MenuMobile />;

  const rightStack = (
    <>
      <Box display={{ xs: 'block', md: 'none' }}>{showMobileButtons}</Box>
      <Box display={{ xs: 'none', md: 'block' }}>{menuButtons}</Box>
    </>
  );

  const leftStack = (
    <>
      <Box display={{ xs: 'block', md: 'none' }}>{mobileMenuButton}</Box>
      <Box display={{ xs: 'none', md: 'block' }}>{backButton}</Box>
    </>
  );

  return (
    <>
      <AppShell menu={<MenuDesktop />}>
        <AppShell.Header
          leftSlot={leftStack}
          rightSlot={rightStack}
          borderColor="neutral-surfaceHighlight"
          borderBottomWidth="1"
          borderStyle="solid"
        />
        <Box display="flex" justifyContent="center" overflowY="auto">
          <Box
            width="100%"
            maxWidth={{
              md: '1248px',
              xs: '100%'
            }}
            minHeight="calc(100vh - 67px)"
            paddingY={{ md: 'none' }}
            paddingX={{ md: '6' }}
          >
            {children}
          </Box>
        </Box>
      </AppShell>
    </>
  );
};

export default AppLayout;
