import React from 'react';
import { Title, Text } from '@tiendanube/components';
import { Box } from 'components';
import { Box as BoxNimbus } from '@nimbus-ds/components';
import { useTopMenu, useTranslate } from 'hooks';

import styles from './partnershipPlan.module.scss';
import {
  PartnersAdvantageCard,
  PlansCards,
  PlatformCard,
  HelpCard
} from './widgets';

const PartnershipPlan: React.FC = () => {
  const { translate } = useTranslate(['partnership-plan', 'links']);

  useTopMenu();

  return (
    <BoxNimbus padding="6">
      <Box.Container
        direction={{ desktop: 'column', mobile: 'row' }}
        className="documentation__container"
      >
        <Title>{`${translate('partnership-plan:title')}`}</Title>
        <Box className={styles.subtitle} width={{ desktop: '100%' }}>
          <Text size="featured" appearance="secondary">
            {`${translate('partnership-plan:subtitle')}`}
          </Text>
        </Box>
        <Box.Container>
          <Box width={{ desktop: '100%' }}>
            <Box.Container direction={{ desktop: 'row' }}>
              <Box width={{ desktop: '70%' }} className={styles.container}>
                <PlansCards />
              </Box>
              <Box width={{ desktop: '30%' }}>
                <PlatformCard />
              </Box>
            </Box.Container>
          </Box>
          <Box width={{ desktop: '70%' }}>
            <Box.Container className={styles.container}>
              <PartnersAdvantageCard />
              <HelpCard />
            </Box.Container>
          </Box>
        </Box.Container>
      </Box.Container>
    </BoxNimbus>
  );
};

export default PartnershipPlan;
