import React from 'react';
import {
  Text,
  Title,
  IconButton,
  Icon,
  Box,
  Card,
  Input
} from '@nimbus-ds/components';
import { CopyIcon, TagIcon } from '@nimbus-ds/icons';
import { useToast, useTranslate } from 'hooks';

const Coupons: React.FC<{ coupon?: string | null }> = ({ coupon }) => {
  const { translate } = useTranslate(['home']);

  const { addToast } = useToast();

  const copyValue = (values: any) => {
    values && navigator.clipboard.writeText(coupon ?? '');
    addToast({
      label: translate('home:cards:coupons.toast'),
      appearance: 'success'
    });
  };

  return (
    <Card>
      <Card.Header>
        <Box display="flex">
          <Box marginRight="2">
            <TagIcon size="medium" />
          </Box>
          <Title as="h4">{`${translate('home:cards.coupons.title')}`}</Title>
        </Box>
      </Card.Header>
      <Card.Body>
        <Text>{translate('home:cards.coupons.description')}</Text>
      </Card.Body>
      <Card.Footer>
        <Box
          flex="1"
          display="flex"
          flexDirection="row"
          alignItems="center"
          minWidth="100%"
        >
          <Box display="flex" marginRight="4" width="100%">
            <Input name="coupon" type="text" value={coupon ?? ''} readOnly />
          </Box>
          <Box>
            <IconButton
              data-testid="copy-coupon"
              source={
                <Icon color="primary-interactive" source={<CopyIcon />} />
              }
              as="button"
              onClick={copyValue}
            />
          </Box>
        </Box>
      </Card.Footer>
    </Card>
  );
};

export default Coupons;
