import React from 'react';
import { Box, Table as TableNimbus } from '@nimbus-ds/components';

import { Footer, Header, RowsDesktop, RowsMobile } from './widgets';
import { ITable } from './tables.types';
import './table.styled.scss';

function Table<T>({
  columns,
  rows,
  children,
  perPage,
  isLoading = false,
  dataTestId,
  boxWrapperRows,
  boxWrapperInnerRows,
  fillWhiteColumns = false
}: ITable<T>): JSX.Element {
  return (
    <>
      <Box
        boxShadow="3"
        borderRadius="2"
        data-testid="desktop-table"
        display={{ xs: 'none', lg: 'block' }}
      >
        <TableNimbus data-testid={`${dataTestId}-desktop`}>
          <Header columns={columns} />
          <RowsDesktop
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            perPage={perPage}
            boxWrapperRows={boxWrapperRows}
            fillWhiteColumns={fillWhiteColumns}
          />
        </TableNimbus>
      </Box>
      <Box paddingBottom="2" display={{ xs: 'none', lg: 'block' }}>
        {children}
      </Box>
      <Box
        data-testid="mobile-table"
        display={{ xs: 'block', lg: 'none' }}
        width="100%"
      >
        <Box data-testid={`${dataTestId}-mobile`} overflow="hidden">
          <RowsMobile
            rows={rows}
            columns={columns}
            perPage={perPage}
            isLoading={isLoading}
            boxWrapperRows={boxWrapperRows}
            boxWrapperInnerRows={boxWrapperInnerRows}
            fillWhiteColumns={fillWhiteColumns}
          />
        </Box>
        {children}
      </Box>
    </>
  );
}

Table.Footer = Footer;

export default Table;
