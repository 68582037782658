export const EXPIRATION_TIME_2M = 120000;

const TEST_FEATURE_FLAGS = {
  ECOSYSTEM_API_TESTING: 'ecosystem-api-testing',
  ECOSYSTEM_OTHER_API_TESTING: 'ecosystem-other-api-testing',
  ECOSYSTEM_OTHER_API_TESTING2: 'ecosystem-other-api-testing2'
};

export const FEATURE_FLAGS = {
  ...TEST_FEATURE_FLAGS
} as const;

export type FeatureFlagsType = typeof FEATURE_FLAGS;
export type FlagName = FeatureFlagsType[keyof FeatureFlagsType];
