import React from 'react';
import { Box, Card } from '@nimbus-ds/components';
import { Skeleton } from 'components';

const ApplicationDoubtsSkeleton: React.FC = () => (
  <Card>
    <Card.Body>
      <Box display="flex" flexDirection="column">
        <Skeleton
          width="280px"
          height="15px"
          marginBottom="8px"
          borderRadius="4px"
        />
        <Skeleton width="210px" height="15px" borderRadius="4px" />
      </Box>
    </Card.Body>
  </Card>
);

export default ApplicationDoubtsSkeleton;
