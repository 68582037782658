import React from 'react';
import { Box, Card, Button, Skeleton } from '@nimbus-ds/components';

const PaymentsSkeleton = () => {
  return (
    <Card backgroundColor="primary-surface">
      <Card.Header>
        <Skeleton width="120px" height="32px" />
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="2">
          <Skeleton width="90px" height="24px" />
          <Skeleton width="150px" height="32px" />
        </Box>
      </Card.Body>
      <Card.Footer>
        <Button.Skeleton width="100px" />
        <Button.Skeleton width="140px" />
      </Card.Footer>
    </Card>
  );
};

export default PaymentsSkeleton;
