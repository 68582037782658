import { BoxSprinkle } from '@nimbus-ds/styles';
import { IColumn, IColumnsDefinitions } from './tables.types';

export class ColumnsDefinitions<T> implements IColumnsDefinitions<T> {
  private readonly columns: IColumn<T>[];
  constructor() {
    this.columns = [];
    return this;
  }

  setColum(
    label: string,
    field: keyof T,
    options?: {
      boxWrapperColumn?: BoxSprinkle;
      formatter?: (field: any, row: any) => string | JSX.Element;
      showNameColumnMobile?: boolean;
    }
  ): IColumnsDefinitions<T> {
    this.columns.push({ label, field, options });
    return this;
  }

  getColumns(): IColumn<T>[] {
    return this.columns;
  }
}

export const getKey = (key: number, value?: string): string =>
  `key-${value ?? ''}-${key}`;
