import React from 'react';

import { IItem } from './item.types';
import AccordionItemContext from './item.context';

const Item: React.FC<IItem> = ({ children, index, testId }) => (
  <AccordionItemContext.Provider value={{ index, testId }}>
    {children}
  </AccordionItemContext.Provider>
);

export default Item;
