import { IDirection, IJustifyContent } from './container.types';

export const style = (
  direction?: IDirection,
  justifyContent?: IJustifyContent
): React.CSSProperties =>
  ({
    '--direction-mobile': direction?.mobile || 'column',
    '--direction-tablet': direction?.tablet || 'column',
    '--direction-desktop': direction?.desktop || 'row',
    '--justify-content-mobile': justifyContent?.mobile || 'column',
    '--justify-content-tablet': justifyContent?.tablet || 'column',
    '--justify-content-desktop': justifyContent?.desktop || 'row'
  } as React.CSSProperties);
