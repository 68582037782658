import React from 'react';
import classNames from 'classnames';

import { IFooter } from './footer.types';
import './footer.styled.scss';

const Footer: React.FC<IFooter> = ({ className, children, ...props }) => {
  const classnames = classNames({
    'card--footer__content': true,
    [className || '']: className && true
  });

  return (
    <div {...props} className={classnames}>
      {children}
    </div>
  );
};

export default Footer;
