import { IImagesGallery } from 'components/ImageGallery/imageGallery.types';
import { useFetch } from 'hooks';
import { ApplicationsTypings } from 'types';
import { IUseImages } from './useImages.types';

const useImages = (id: string, language?: string): IUseImages => {
  const [onSubmitIconReq, { loading: loadingIcon }] = useFetch(
    `/apps/${id}/icons`,
    {
      fallbackError: {
        execute: true
      }
    }
  );

  const [onSubmitScreenshotReq, { loading: loadingScreenshot }] = useFetch<
    ApplicationsTypings.IScreenshots[]
  >(`/apps/${id}/screenshots`, {
    fallbackError: {
      execute: true
    }
  });

  const onSubmitIcon = async (images: IImagesGallery) => {
    const formData = new FormData();
    images.files.forEach((file) => formData.append('smallIcon', file));
    formData.append('language', language || '');
    await onSubmitIconReq({
      method: 'POST',
      data: formData
    });
  };

  const onSubmitScreenshots = async (images: IImagesGallery) => {
    const formData = new FormData();
    images.files.forEach((file) => formData.append('appScreenshotList', file));
    formData.append('imagePositionList', JSON.stringify(images.positions));
    const response = await onSubmitScreenshotReq({
      method: 'POST',
      data: formData
    });
    return response?.map((item) => item.image);
  };

  const onDeleteScreenshots = async (imageName: string | File) => {
    let hasDeletedImage;
    try {
      await onSubmitScreenshotReq({
        method: 'DELETE',
        data: {
          imageName
        }
      });
      hasDeletedImage = true;
    } catch (error) {
      hasDeletedImage = false;
    }
    return hasDeletedImage;
  };

  return {
    onSubmitIcon,
    loadingIcon,
    onSubmitScreenshots,
    loadingScreenshot,
    onDeleteScreenshots
  };
};

export default useImages;
