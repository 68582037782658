import { AbstractTypings, AppsScriptsTypings } from 'types';
import { useMutation, useQuery } from 'react-query';
import { useFetchV2, useToast, useTranslate } from 'hooks';
import { IUsePagination } from 'hooks/usePaginationV2/usePagination.types';
import { PER_PAGE } from 'domains/Applications/pages/Details/widgets/ScriptListCard';

const useAppsScripts = (
  appId: number | string,
  pagination?: IUsePagination
) => {
  const { request } = useFetchV2();
  const { addToast } = useToast();
  const { translate } = useTranslate(['applications-listing', 'api']);

  const query = useQuery(
    ['scripts-app', appId, (pagination?.page ?? 1) - 1],
    () =>
      request<AbstractTypings.PageResponse<AppsScriptsTypings.AppScript>>({
        url: `apps/${appId}/scripts?countVersion=true&page=${
          (pagination?.page ?? 1) - 1
        }&per_page=${PER_PAGE}`
      }),
    {
      retry: false
    }
  );

  const genericError = async () => {
    addToast({
      appearance: 'danger',
      label: translate('api:generic_error')
    });
  };

  const getVersions = useMutation(
    (scriptId: number | string) =>
      request<
        AbstractTypings.PageResponse<AppsScriptsTypings.AppScriptVersion>
      >({
        url: `apps/${appId}/scripts/${scriptId}/versions`,
        method: 'GET'
      }),
    {
      onError: genericError
    }
  );

  const editTitle = useMutation(
    (args: { scriptId: number | string; newScriptName: string }) =>
      request<
        AbstractTypings.PageResponse<AppsScriptsTypings.AppScriptVersion>
      >({
        url: `apps/${appId}/scripts/${args.scriptId}`,
        method: 'PATCH',
        data: {
          name: args.newScriptName
        }
      }),
    {
      onSuccess: async () => {
        addToast({
          appearance: 'success',
          label: 'Successfully edited Script'
        });
        await query.refetch();
      },
      onError: genericError
    }
  );

  const deleteScript = useMutation(
    (scriptId: number | string) =>
      request<
        AbstractTypings.PageResponse<AppsScriptsTypings.AppScriptVersion>
      >({
        url: `apps/${appId}/scripts/${scriptId}`,
        method: 'DELETE'
      }),
    {
      onSuccess: async () => {
        addToast({
          appearance: 'success',
          label: 'Successfully deleted Script'
        });
        await query.refetch();
      },
      onError: genericError
    }
  );

  const deployVersionTest = useMutation(
    (args: { scriptId: number | string; scriptVersionId: number | string }) =>
      request<
        AbstractTypings.PageResponse<AppsScriptsTypings.AppScriptVersion>
      >({
        url: `apps/${appId}/scripts/${args.scriptId}/versions/${args.scriptVersionId}/deploy-test`,
        method: 'PATCH'
      }),
    {
      onSuccess: async () => {
        addToast({
          appearance: 'success',
          label: 'Successfully deployed Script version to TEST stores'
        });
      },
      onError: genericError
    }
  );

  const deployVersion = useMutation(
    (args: { scriptId: number | string; scriptVersionId: number | string }) =>
      request<
        AbstractTypings.PageResponse<AppsScriptsTypings.AppScriptVersion>
      >({
        url: `apps/${appId}/scripts/${args.scriptId}/versions/${args.scriptVersionId}/deploy`,
        method: 'PATCH'
      }),
    {
      onSuccess: async () => {
        addToast({
          appearance: 'success',
          label: 'Successfully deployed Script version'
        });
      },
      onError: genericError
    }
  );

  return {
    scripts: query.data?.content ?? { entities: [], count: 0 },
    getScripts: query.refetch,
    scriptsLoading: query.isLoading,
    getVersions,
    editTitle,
    deleteScript,
    deployVersionTest,
    deployVersion,
    versions: getVersions.data?.content ?? { entities: [], count: 0 }
  };
};

export default useAppsScripts;
