import { AppsScriptsTypings } from 'types';

export const PER_PAGE = 5;

export const getScriptStatusI18n = (
  statusString: AppsScriptsTypings.EScriptStatus
) => {
  const status = {
    active_store_demo: 'activated_demo_store'
  };
  return status[statusString];
};
