import React from 'react';
import { Text, Title } from '@nimbus-ds/components';

import { Logo } from 'components';
import { ICommonPage } from './commonPage.types';
import styles from './commonPage.module.scss';

const CommonPage: React.FC<ICommonPage> = ({
  title,
  subtitle,
  legend,
  children
}) => (
  <div className={styles.container}>
    <Logo />
    <div data-testid="content" className={styles.content}>
      <Title>{title}</Title>
      <Title as="h3">{subtitle}</Title>
      <Text>{legend}</Text>
      <div className={styles.actions}>{children}</div>
    </div>
  </div>
);

export default CommonPage;
