import { IS_DEVELOPMENT } from 'commons/constants';

const msw = async () => {
  if (IS_DEVELOPMENT) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require('commons/tests/mocks/server/browser');
    await worker.start();
  }
};

export default msw;
