import React from 'react';
import { Box, IconButton, Popover } from '@nimbus-ds/components';
import { CodeIcon, DownloadIcon, EllipsisIcon } from '@nimbus-ds/icons';
import {
  IVersionActionMenu,
  VersionAllowedActionsEnum
} from './versionActionMenu.types';
import ActionMenuItem from '../../ScriptListCard/widgets/ActionMenuItem';

const VersionActionMenu: React.FC<IVersionActionMenu> = ({
  allowedActions,
  scripts,
  currentScript,
  currentVersion
}) => {
  return (
    <Popover
      width="240px"
      content={
        <Box display="flex" flexDirection="column" gap="2" width="100%">
          <ActionMenuItem
            titleName={'install_demo_store'}
            icon={<CodeIcon />}
            currentAllowedActions={allowedActions}
            requirementAction={VersionAllowedActionsEnum.DEPLOY_TEST}
            onClick={async () => {
              await scripts.deployVersionTest.mutate({
                scriptId: currentScript.id,
                scriptVersionId: currentVersion.id
              });
              await scripts.getVersions.mutate(currentScript.id);
            }}
          />

          <ActionMenuItem
            titleName={'install'}
            icon={<CodeIcon />}
            currentAllowedActions={allowedActions}
            requirementAction={VersionAllowedActionsEnum.DEPLOY}
            onClick={async () => {
              await scripts.deployVersion.mutate({
                scriptId: currentScript.id,
                scriptVersionId: currentVersion.id
              });
              await scripts.getVersions.mutate(currentScript.id);
            }}
          />

          <ActionMenuItem
            titleName={'download_version'}
            icon={<DownloadIcon />}
          />
        </Box>
      }
      position="bottom"
      arrow={false}
      padding="small"
    >
      <IconButton size="2rem" source={<EllipsisIcon size="small" />} />
    </Popover>
  );
};

export default VersionActionMenu;
