import { useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

const useQuery = (): [URLSearchParams, (params: any) => void] => {
  const { search } = useLocation();
  const history = useHistory();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const setQuery = useCallback(
    (params: { [value: string]: string }) => {
      const urlParams = new URLSearchParams(params);
      const path = window.location.pathname;
      history.push(`${path}?${urlParams}`);
    },
    [history]
  );
  return [query, setQuery];
};

export default useQuery;
