import React from 'react';
import { ExternalLinkIcon, MarketingIcon } from '@nimbus-ds/icons';
import {
  Button,
  Text,
  Title,
  Link,
  Icon,
  Box,
  Card
} from '@nimbus-ds/components';
import { useTranslate } from 'hooks';

import { useToast } from 'components/Toast/toast.context';

const MarketingLink: React.FC<{ link?: string }> = ({ link }) => {
  const { translate } = useTranslate(['home', 'links']);
  const { addToast } = useToast();

  const copyLink = () => {
    link && navigator.clipboard.writeText(link);
    addToast({
      label: translate('home:cards.marketing_link.toast.copy_link'),
      appearance: 'success'
    });
  };
  return (
    <Card>
      <Card.Header>
        <Box display="flex" flexDirection="row">
          <Box marginRight="2">
            <MarketingIcon size="medium" />
          </Box>
          <Title as="h4">{`${translate(
            'home:cards.marketing_link.title'
          )}`}</Title>
        </Box>
      </Card.Header>
      <Card.Body>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          marginY="6"
        >
          <Text>{translate('home:cards.marketing_link.description')}</Text>
        </Box>
      </Card.Body>
      <Card.Footer>
        {link && (
          <Button appearance="neutral" onClick={copyLink}>
            {`${translate('home:cards.marketing_link.buttons.link_copy')}`}
          </Button>
        )}
        <Link
          textDecoration="none"
          href={translate('links:partnership_program')}
          as="a"
        >
          <Text color="primary-textLow" fontWeight="bold">
            {translate('home:cards.marketing_link.buttons.more_info')}
          </Text>
          <Icon source={<ExternalLinkIcon />} color="primary-textLow" />
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default MarketingLink;
