import { useMemo } from 'react';

import { useFetch } from 'hooks';
import { CountriesTypings } from 'types';
import { transformCountries } from './useCountries.definitions';
import { TUseCountries } from './useCountries.types';

const useCountries = (): TUseCountries => {
  const [onCountries, { data: originalCountries = [] }] = useFetch<
    CountriesTypings.ICountry[]
  >('/countries/active', {
    immediate: true,
    notToast: true
  });

  const countries = useMemo(
    () => transformCountries(originalCountries),
    [originalCountries]
  );

  return [countries, originalCountries, onCountries];
};

export default useCountries;
