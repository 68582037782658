import { ApplicationsTypings } from 'types';
import { string, addMethod, StringSchema, TestContext } from 'yup';

interface YupContext {
  from: {
    value: ApplicationsTypings.TApplicationEdit;
  }[];
}

addMethod<StringSchema>(string, 'appNativeName', function (errorMessage) {
  return this.test('appNativeName', errorMessage, function (value, context) {
    const { from } = context as TestContext & YupContext;
    if (
      from?.[1]?.value?.appType === ApplicationsTypings.EAppTypeEnum.ENHANCED &&
      !value?.length
    ) {
      return this.createError({ path: this.path, message: errorMessage });
    }
    return true;
  });
});
