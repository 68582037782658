import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useFetchV2, useAuth, useTranslate } from 'hooks';
import { PaymentsTypingsV2 } from 'types';

import { EXPIRATION_TIME_6H } from 'commons/constants';
import { currency } from 'commons/formatters';

const useRescue = (staleTime = EXPIRATION_TIME_6H) => {
  const queryClient = useQueryClient();
  const { auth } = useAuth();
  const { request } = useFetchV2();
  const { language } = useTranslate('');

  const query = useQuery(
    ['request-rescueV2', auth.partnerId],
    () =>
      request<PaymentsTypingsV2.ILedgerRescue>({
        url: `v2/partners/${auth.partnerId}/available-withdraw`
      }),
    {
      retry: false,
      staleTime
    }
  );

  const invalidateCache = useCallback(
    () => queryClient.invalidateQueries('request-rescueV2'),
    [queryClient]
  );

  const isRescueInProcess = useMemo(() => {
    if (query.isLoading) {
      return false;
    }
    return (
      query?.data?.content &&
      query?.data?.content?.pendingWithdrawValue !== null &&
      Number(query?.data?.content?.pendingWithdrawValue) < 0 &&
      query?.data?.content?.isAvailable === false
    );
  }, [query?.data?.content, query.isLoading]);

  const totalRescue = useMemo(
    () =>
      currency(
        Math.abs(
          query?.data?.content?.pendingWithdrawValue
            ? Number(query?.data?.content?.pendingWithdrawValue)
            : 0
        ),
        language,
        auth.currency,
        auth.countryCode
      ),
    [
      auth.countryCode,
      auth.currency,
      language,
      query?.data?.content?.pendingWithdrawValue
    ]
  );

  return {
    ...query,
    invalidateCache,
    isRescueInProcess,
    totalRescue
  };
};

export default useRescue;
