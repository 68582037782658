import { useState, useEffect } from 'react';

import { IUseInfiniteScroll } from './useWindowSize.types';

interface ISize {
  width?: number;
  height?: number;
}

const useWindowSize = (): IUseInfiniteScroll => {
  const [windowSize, setWindowSize] = useState<ISize>({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    ...windowSize,
    isMobile: windowSize.width ? windowSize.width < 672 : false,
    isTablet: windowSize.width ? windowSize.width < 1022 : false
  };
};

export default useWindowSize;
