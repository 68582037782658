export const MIN_LENGTH = 10;

export const validations = [
  {
    key: 'lowerCase',
    description: 'errors.password.lowercase'
  },
  {
    key: 'upperCase',
    description: 'errors.password.uppercase'
  },
  {
    key: 'hasNumber',
    description: 'errors.password.number'
  },
  {
    key: 'specialChar',
    description: 'errors.password.special_char'
  },
  {
    key: 'minLength',
    description: 'errors.password.length'
  }
];
