import React from 'react';
import { Box, Card, Link } from '@nimbus-ds/components';
import { Text } from '@tiendanube/components';
import { QuestionCircleIcon } from '@nimbus-ds/icons';

import { useTranslate } from 'hooks';

import { IApplicationDoubts } from './applicationDoubts.types';

const ApplicationDoubts: React.FC<IApplicationDoubts> = () => {
  const { translate } = useTranslate(['applications', 'links']);
  return (
    <Card>
      <Card.Body>
        <Box display="flex" flexDirection="column">
          <Text>
            <QuestionCircleIcon />
            {translate('paragraph.doubts.text')}
          </Text>
          <Link
            as="a"
            href={translate('links:doubts_email')}
            appearance="primary"
          >
            {translate('paragraph.doubts.link')}
          </Link>
        </Box>
      </Card.Body>
    </Card>
  );
};

export default ApplicationDoubts;
