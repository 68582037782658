import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  HomeIcon,
  AppsIcon,
  StoreIcon,
  UserGroupIcon,
  UserIcon,
  GiftBoxIcon,
  TiendanubeIcon,
  GlassesIcon,
  CreditCardIcon
} from '@nimbus-ds/icons';
import { Box } from '@nimbus-ds/components';
import { Menu } from '@nimbus-ds/patterns';
import { ROUTES } from 'commons/constants';
import { useTranslate } from 'hooks';
import { Logo } from 'components';

const MenuItems: React.FC<{ handleCloseMenu?: () => void }> = ({
  handleCloseMenu
}) => {
  const { translate } = useTranslate(['common', 'links']);
  const location = useLocation();
  const history = useHistory();

  const handleActive = (paths: string[]) =>
    paths.some((path) =>
      path.length === 1
        ? location.pathname?.toUpperCase() === path?.toUpperCase()
        : location.pathname?.includes(path)
    );

  const redirect = useCallback(
    (path: string) => {
      handleCloseMenu && handleCloseMenu();
      history.push(path);
    },
    [history, handleCloseMenu]
  );

  return (
    <>
      <Menu.Header>
        <Box display="flex" gap="2" alignItems="center" width="100%">
          <Logo />
        </Box>
      </Menu.Header>
      <Menu.Body>
        <Menu.Section>
          <Menu.Button
            startIcon={HomeIcon}
            active={handleActive([ROUTES.HOME, ROUTES.BANNERS])}
            onClick={() => redirect(ROUTES.HOME)}
            label={translate('common:menus.initial')}
          />
        </Menu.Section>
        <Menu.Section title={translate('common:sections.potentialize')}>
          <Menu.Button
            startIcon={StoreIcon}
            onClick={() => redirect(ROUTES.STORES.DEFAULT)}
            active={handleActive([ROUTES.STORES.DEFAULT])}
            label={translate('common:menus.shops')}
          />

          <Menu.Button
            startIcon={AppsIcon}
            onClick={() => redirect(ROUTES.APPLICATIONS.DEFAULT)}
            active={handleActive([ROUTES.APPLICATIONS.DEFAULT])}
            label={translate('common:menus.applications')}
          />
        </Menu.Section>
        <Menu.Section title={translate('common:sections.administer')}>
          <Menu.Button
            startIcon={CreditCardIcon}
            onClick={() => redirect(ROUTES.PAYMENTS.DEFAULT)}
            active={handleActive([ROUTES.PAYMENTS.DEFAULT])}
            label={translate('common:menus.payments')}
          />

          <Menu.Button
            startIcon={UserGroupIcon}
            onClick={() => redirect(ROUTES.PARTNERSHIP_PLAN)}
            active={handleActive([ROUTES.PARTNERSHIP_PLAN])}
            label={translate('common:menus.partnership_plan')}
          />

          <Menu.Button
            startIcon={GlassesIcon}
            onClick={() => redirect(ROUTES.DOCUMENTATION)}
            active={handleActive([ROUTES.DOCUMENTATION])}
            label={translate('common:menus.trainings')}
          />

          <Menu.Button
            startIcon={UserIcon}
            onClick={() => redirect(ROUTES.ACCOUNT.DEFAULT)}
            active={handleActive([ROUTES.ACCOUNT.DEFAULT])}
            label={translate('common:menus.my_account')}
          />
        </Menu.Section>
        <Menu.Section title={translate('common:sections.go_along')}>
          <Menu.Button
            startIcon={GiftBoxIcon}
            onClick={() => window.open(translate('links:news'), '_blank')}
            label={translate('common:menus.news')}
          />

          <Menu.Button
            startIcon={TiendanubeIcon}
            onClick={() => window.open(translate('links:status'), '_blank')}
            label={translate('common:menus.platform_status')}
          />
        </Menu.Section>
      </Menu.Body>
    </>
  );
};
export default MenuItems;
