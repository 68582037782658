import { useCallback } from 'react';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import axios from 'commons/utils/axios/axiosApiInstance';
import { useTranslate } from 'hooks';
import { IApiResponse } from './useFetch.types';

const useFetch = () => {
  const { translate } = useTranslate('api');

  const request = useCallback(
    async <T>(params: AxiosRequestConfig) => {
      let axiosResponse: AxiosResponse<IApiResponse<T>>;
      try {
        axiosResponse = await axios.request(params);
        if (!axiosResponse?.data?.content && !axiosResponse?.data?.message) {
          return {
            message: '',
            content: axiosResponse?.data as unknown as NonNullable<T>,
            statusCode: axiosResponse?.status
          };
        }
        return {
          message: translate(axiosResponse?.data?.message),
          content: axiosResponse?.data?.content,
          statusCode: axiosResponse?.status
        };
      } catch (error: any) {
        axiosResponse = error.response;
        return Promise.reject({
          message: translate(axiosResponse?.data?.message ?? 'generic_error'),
          content: axiosResponse?.data?.content ?? null,
          statusCode: axiosResponse?.status
        });
      }
    },
    [translate]
  );

  return { request };
};

export default useFetch;
