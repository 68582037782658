import { PURGE } from 'redux-persist';
import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { AuthTypings } from 'types';

export const initialStateAuth = {
  accessToken: ''
} as AuthTypings.IUser;

const handleAuth: CaseReducer<
  AuthTypings.IUser,
  PayloadAction<AuthTypings.IUser>
> = (state, action) => (state = action.payload);

const authSlice = createSlice({
  name: 'auth',
  initialState: initialStateAuth,
  reducers: {
    handleAuthAction: handleAuth
  },
  extraReducers: (builder) => {
    /* istanbul ignore next */
    builder.addCase(PURGE, () => initialStateAuth);
  }
});

export const { handleAuthAction } = authSlice.actions;

export const { reducer: reducerAuth } = authSlice;
