import { ENABLE_SENTRY } from 'commons/constants';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';

const sentry = () => {
  if (ENABLE_SENTRY) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY,
      integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0
    });
  }
};

export default sentry;
