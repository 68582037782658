import React, { createContext, useState, useCallback } from 'react';

import { ToastProgress as ToastProgressNimbus } from '@tiendanube/components';
import { IToastProgress, IToastProgressContext } from './toastProgress.types';

export const ToastProgressContext = createContext<IToastProgressContext>(
  {} as unknown as IToastProgressContext
);

const initialState = { show: false, label: '', number: '' };

function ToastProgress({ children }: IToastProgress): JSX.Element {
  const [toast, setToast] = useState(initialState);

  const addToastProgress = useCallback(
    ({ label, number }: { label: string; number: string }) => {
      setToast({ show: true, label, number });
    },
    []
  );

  const closeToastProgress = useCallback(() => {
    setToast(initialState);
  }, []);

  return (
    <ToastProgressContext.Provider
      value={{ addToastProgress, closeToastProgress }}
    >
      {children}
      <ToastProgressNimbus
        show={toast.show}
        percentage={parseInt(toast.number)}
        label={toast.label}
      />
    </ToastProgressContext.Provider>
  );
}

export default ToastProgress;
