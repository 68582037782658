import React from 'react';
import { Field, FieldProps } from 'formik';
import { Toggle as NimbusToggle } from '@nimbus-ds/components';

import { IToggle } from './toggle.types';

const Toggle = ({
  name,
  label,
  value,
  dataTestId,
  disabled = false
}: IToggle): JSX.Element => (
  <Field name={name} type="checkbox" value={value}>
    {({ field }: FieldProps) => (
      <NimbusToggle
        {...field}
        data-testid={dataTestId}
        name={name}
        label={label}
        disabled={disabled}
        active={value}
      />
    )}
  </Field>
);

export default Toggle;
