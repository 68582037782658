import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Button, Title } from '@tiendanube/components';
import { FormikProps } from 'formik';
import { Modal, Form, Input } from 'components';

import { useCommons, useFetchV2, useToast, useTranslate } from 'hooks';
import { ApplicationsTypings } from 'types';

import { ApplicationTypes } from 'domains/Applications/components';
import { ROUTES } from 'commons/constants';

import style from './createApp.module.scss';
import { CreateAppModalContext } from './createAppModal.context';
import { ICreateAppModal } from './createApps.types';
import { CreateAppValidation, initialValues } from './createApp.definitions';

function CreateAppModal({ children }: ICreateAppModal): JSX.Element {
  const { request } = useFetchV2();
  const { addToast } = useToast();
  const { setAlert } = useCommons();
  const history = useHistory();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [valuesApp, setValuesApp] =
    useState<ApplicationsTypings.IApplicationCreate | null>(null);

  const showModal = () => setIsOpenModal(true);
  const hideModal = () => setIsOpenModal(false);

  const { translate } = useTranslate([
    'applications',
    'applications-actions',
    'links',
    'common',
    'api'
  ]);

  const { mutate, isLoading: createLoading } = useMutation(
    (data: ApplicationsTypings.IApplicationCreate) =>
      request<ApplicationsTypings.IApplicationCreate>({
        url: `/apps/`,
        method: 'POST',
        data
      }),
    {
      onSuccess: (response) => {
        setAlert({
          details: {
            appearance: 'success',
            title: translate('common:alerts.create_app.title', {
              appId: response.content
            }),
            message: translate('common:alerts.create_app.message', {
              appName: valuesApp?.name
            }),
            data: `${response.content}`
          }
        });
        history.push(
          ROUTES.APPLICATIONS.DETAILS.replace(':id', `${response?.content}`)
        );
        setValuesApp(null);
        hideModal();
      },
      onError: (response: { message: string }) => {
        addToast({
          appearance: 'danger',
          label: translate(response.message)
        });
      }
    }
  );

  return (
    <CreateAppModalContext.Provider
      value={{ hideModal, isOpenModal, showModal }}
    >
      {children}
      <Modal onDismiss={() => hideModal()} show={isOpenModal}>
        <Modal.Header>
          <Title type="h3">
            {`${translate('applications-actions:title.create_application')}`}
          </Title>
        </Modal.Header>
        <Modal.Body className={style.body}>
          <Form<ApplicationsTypings.IApplicationCreate>
            initialValues={initialValues}
            validationSchema={CreateAppValidation}
            onSubmit={(data) => {
              setValuesApp(data);
              mutate(data);
            }}
          >
            {({
              errors
            }: FormikProps<ApplicationsTypings.IApplicationCreate>) => (
              <>
                <Input
                  boxWrapper={{ padding: '4' }}
                  name="name"
                  type="text"
                  label={translate('applications:fields.name')}
                  helpText={
                    errors?.name && translate(`validations:${errors?.name}`)
                  }
                  maxLength={35}
                  showQuantity
                />
                <ApplicationTypes
                  type="list"
                  helpText={`${translate(
                    'applications-actions:paragraph.application_type_description'
                  )}`}
                />
                <div className={style.button}>
                  <Button
                    type="submit"
                    appearance="primary"
                    spinner={createLoading}
                  >
                    {`${translate(
                      'applications-actions:buttons.create_application'
                    )}`}
                  </Button>
                </div>
              </>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </CreateAppModalContext.Provider>
  );
}

export default CreateAppModal;
