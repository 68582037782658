import React from 'react';

import { Skeleton } from 'components';
import { Box, Card } from '@nimbus-ds/components';

const DemoStoreSkeleton: React.FC = () => {
  return (
    <Card>
      <Card.Header>
        <Box display="flex" gap="2">
          <Skeleton width="32px" height="32px" />
          <Skeleton width="120px" height="32px" />
        </Box>
      </Card.Header>
      <Card.Body>
        <Skeleton
          width="100%"
          height="60px"
          borderRadius="4px"
          marginBottom="24px"
        />
      </Card.Body>
      <Card.Footer>
        <Box display="flex" gap="2" alignItems="center">
          <Skeleton borderRadius="8px" width="120px" height="42px" />
          <Skeleton width="100px" height="24px" borderRadius="4px" />
        </Box>
      </Card.Footer>
    </Card>
  );
};

export default DemoStoreSkeleton;
