import { AppsScriptsTypings } from 'types';

export interface IVersionActionMenu {
  allowedActions: string[];
  scripts: any;
  currentVersion: AppsScriptsTypings.AppScriptVersion;
  currentScript: AppsScriptsTypings.AppScript;
}

export enum VersionAllowedActionsEnum {
  DEPLOY = 'deploy',
  DEPLOY_TEST = 'deploy-test'
}
