import React from 'react';
import { DataList } from '@nimbus-ds/patterns';
import { Box, IconButton, Link, Tag, Text } from '@nimbus-ds/components';
import { TiendanubeIcon } from '@nimbus-ds/icons';
import { IScriptsVersionsListProps } from './scriptsVersionsList.types';
import dayjs from 'dayjs';
import VersionActionMenu from './widgets';

export const ScriptsVersionsList: React.FC<IScriptsVersionsListProps> = ({
  id,
  versionsAmount,
  versions,
  scripts,
  currentScript
}) => {
  return (
    <DataList>
      {versionsAmount > 0 &&
        versions.entities.map((version, i) => (
          <DataList.Row
            backgroundColor="neutral-surface"
            key={`version-${id}-${i}`}
            gap="1"
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" gap="2">
                <Text fontWeight="bold" color="neutral-textLow">
                  {`v.${version.version ?? 0}`}
                </Text>
                <Text fontWeight="medium" color="neutral-textLow">
                  Criada {dayjs(version.createdAt).format('DD/MM/YYYY')}
                </Text>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="2"
              >
                <Tag appearance="success">
                  <Text color="success-textHigh">{version.status}</Text>
                </Tag>
                <IconButton
                  size="2rem"
                  source={<TiendanubeIcon size="small" />}
                />
                <VersionActionMenu
                  allowedActions={version.allowed_actions}
                  scripts={scripts}
                  currentVersion={version}
                  currentScript={currentScript}
                />
              </Box>
            </Box>
          </DataList.Row>
        ))}
      <DataList.Row backgroundColor="neutral-surface" gap="1">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Link as="button" appearance="primary" textDecoration="none">
            Ver todas as versões ({versionsAmount})
          </Link>
        </Box>
      </DataList.Row>
    </DataList>
  );
};
