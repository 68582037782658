import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ROUTES } from 'commons/constants';
const Payments = React.lazy(() => import('../v2/Payments'));
const RequestRescue = React.lazy(() => import('./pages/RequestRescue'));
const RequestRescueStatus = React.lazy(
  () => import('./pages/RequestRescueStatus')
);
const Details = React.lazy(() => import('../v2/pages/Details'));

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path={ROUTES.PAYMENTS.DEFAULT} exact>
        <Payments />
      </Route>
      <Route path={ROUTES.PAYMENTS.REQUEST_RESCUE} exact>
        <RequestRescue />
      </Route>
      <Route path={ROUTES.PAYMENTS.REQUEST_RESCUE_STATUS} exact>
        <RequestRescueStatus />
      </Route>
      <Route path={ROUTES.PAYMENTS.DETAILS} exact>
        <Details />
      </Route>
      <Redirect from="*" to={ROUTES.NOT_FOUND} />
    </Switch>
  );
};

export default Routes;
