import React, { useEffect, useCallback } from 'react';
import { Prompt } from 'react-router-dom';

import { useTranslate } from 'hooks';
import { IExitFormConfirmation } from './exitFormConfirmation.types';

const ExitFormConfirmation: React.FC<IExitFormConfirmation> = ({ when }) => {
  const { translate } = useTranslate('common');
  const handleBeforeUnload = useCallback((event) => {
    event.preventDefault();
    event.returnValue = true;
  }, []);

  useEffect(() => {
    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [when, handleBeforeUnload]);

  return <Prompt when={when} message={translate('modal.exit_edit.info')} />;
};

export default ExitFormConfirmation;
