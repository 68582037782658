import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.getElementById('content')?.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  return <></>;
};

export default ScrollToTop;
