import React from 'react';
import {
  Box,
  Tag,
  Text,
  Thumbnail,
  Card as CardNimbus,
  Skeleton
} from '@nimbus-ds/components';
import { useHistory } from 'react-router-dom';

import { useLoading, useTranslate } from 'hooks';
import { ROUTES } from 'commons/constants';
import { useMetrics } from 'domains/Applications/hooks';

import { colorsStatus } from './card.definitions';
import { IAppCard } from './card.types';
import EMPTY_IMAGE from './empty-image.png';

const Card: React.FC<IAppCard> = ({
  title,
  image,
  status,
  testId,
  label,
  id
}) => {
  const history = useHistory();
  const { translate } = useTranslate('applications-listing');

  const { IS_LOADING: IS_LOADING_METRICS, ACTIVES } = useMetrics(
    id as unknown as number
  );

  const IS_LOADING = useLoading([IS_LOADING_METRICS]);

  return (
    <CardNimbus
      data-testid={testId}
      onClick={() =>
        history.push(ROUTES.APPLICATIONS.DETAILS.replace(':id', id as string))
      }
    >
      <CardNimbus.Body>
        <Box display="flex" flexDirection="row" gap="2">
          <Thumbnail
            data-testid={`img-${testId}`}
            src={image ?? EMPTY_IMAGE}
            alt=""
            width="72px"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = EMPTY_IMAGE;
            }}
          />
          <Box display="flex" flexDirection="column" flex="1">
            {IS_LOADING && <Skeleton width="130px" height="20px" />}
            {!IS_LOADING && title && (
              <Box display="flex" flexDirection="row" alignItems="center">
                <Text as="span" fontWeight="bold">
                  {title}
                </Text>
                <Box marginLeft="2">
                  <Text as="span">{`(#${id})`}</Text>
                </Box>
              </Box>
            )}
            <Box marginY="0-5">
              {!IS_LOADING && ACTIVES !== undefined && (
                <Text>{`${ACTIVES} ${translate(
                  'applications-listing:cards.active_installations'
                )}`}</Text>
              )}
              {IS_LOADING && <Skeleton width="170px" height="20px" />}
            </Box>

            {status && (
              <Box display="flex" flex="1" height="100%" alignItems="flex-end">
                {!IS_LOADING && (
                  <Tag appearance={colorsStatus[status]} id={title}>
                    {label}
                  </Tag>
                )}
                {IS_LOADING && <Tag.Skeleton width="120px" height="20px" />}
              </Box>
            )}
          </Box>
        </Box>
      </CardNimbus.Body>
    </CardNimbus>
  );
};

export default Card;
