import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Icon,
  IconButton,
  Input,
  Link,
  Pagination,
  Tag,
  Text,
  Title
} from '@nimbus-ds/components';

import { IScriptsCardProps } from './scriptListCards.types';
import { usePaginationV2, useTranslate } from 'hooks';
import {
  EditIcon,
  ShoppingCartIcon,
  SlidersIcon,
  StoreIcon
} from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import Collapsible from '@nimbus-ds/collapsible';
import useAppsScripts from '../../../../hooks/useAppsScripts';
import { ScriptListCardSkeleton } from './ScriptListCards.skeleton';
import { ScriptsVersionsList } from '../ScriptsVersionsList/ScriptVersionsList';
import { AppsScriptsTypings } from 'types';
import { getScriptStatusI18n, PER_PAGE } from './scriptListCards.definitions';
import { useCreateScriptModal } from 'domains/Applications/hooks';
import { ActionMenu } from './widgets';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'commons/constants';
import ScriptEmptyCard from '../ScriptEmpytCard';

const ScriptListCard: React.FC<IScriptsCardProps> = ({ id, hasScope }) => {
  const { translate } = useTranslate(['application-list-scripts']);
  const [open, setOpen] = useState<any>({});
  const pagination = usePaginationV2(PER_PAGE);
  const scripts = useAppsScripts(id, pagination);
  const { context } = useCreateScriptModal();
  const history = useHistory();

  const totalPages = useMemo(
    () => pagination.getTotalPages(scripts.scripts.count ?? 0),
    [pagination, scripts.scripts.count]
  );

  if (scripts.scriptsLoading) {
    return <ScriptListCardSkeleton />;
  }

  if (scripts.scriptsLoading === false && scripts.scripts.count === 0) {
    return (
      <Box width="100%" marginY="4">
        <ScriptEmptyCard hasScriptScope={hasScope} id={Number(id)} />
      </Box>
    );
  }

  const Versions = ({
    script,
    index
  }: {
    script: AppsScriptsTypings.AppScript;
    index: number;
  }) => (
    <>
      {script.version_count > 0 && (
        <Link
          as="button"
          onClick={() => {
            scripts.getVersions.mutate(script.id);
            setOpen({ ...open, [index]: !open[index] });
          }}
          appearance="primary"
          textDecoration="none"
        >
          {!open[index]
            ? translate('application-list-scripts:buttons.version', {
                count: script.version_count
              })
            : translate('application-list-scripts:buttons.hide_versions')}
        </Link>
      )}
    </>
  );

  return (
    <Card padding="none">
      <Card.Header padding="base">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Title as="h3">
            {translate('application-list-scripts:titles.scripts')}
          </Title>
          <Button onClick={() => context.setOpen?.(true)}>
            {translate('application-list-scripts:buttons.create')}
          </Button>
        </Box>
        <Box display="flex" flex="row" gap="2" marginTop="4">
          <Input.Search placeholder="Search" />
          <Button>
            <Icon source={<SlidersIcon />} />
          </Button>
        </Box>
      </Card.Header>
      <Card.Body padding="none">
        <DataList>
          {scripts.scripts.entities.map(
            (script: AppsScriptsTypings.AppScript, i) => (
              <DataList.Row
                backgroundColor={
                  open[i] ? 'neutral-surface' : 'neutral-background'
                }
                padding="none"
                key={`scripts-${id}-${i}`}
                gap="1"
              >
                <Collapsible open={open[i]}>
                  <Collapsible.Item>
                    <Box padding="3">
                      <Link
                        as="button"
                        textDecoration="none"
                        onClick={() =>
                          history.push(
                            ROUTES.APPLICATIONS.SCRIPT_DETAILS.replace(
                              ':id',
                              id.toString()
                            ).replace(':scriptId', script.id.toString())
                          )
                        }
                      >
                        <Text
                          as="span"
                          color="neutral-textHigh"
                          fontWeight="bold"
                          fontSize="highlight"
                        >
                          {script.name}
                        </Text>
                      </Link>
                      <Box
                        display="flex"
                        flexDirection={{ lg: 'row', xs: 'column' }}
                        gap={{ lg: '0-5', xs: '2' }}
                        marginTop={{ lg: '0-5', xs: '1' }}
                        justifyContent="space-between"
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          gap="2"
                          alignItems="center"
                        >
                          <Tag appearance="neutral">
                            {script.location ===
                              AppsScriptsTypings.EScripTLocation.STOREFRONT && (
                              <StoreIcon size={12} />
                            )}
                            {script.location ===
                              AppsScriptsTypings.EScripTLocation.CHECKOUT && (
                              <ShoppingCartIcon size={12} />
                            )}
                            <Text color="neutral-textHigh">
                              {script.location}
                            </Text>
                          </Tag>
                          <Tag appearance="neutral">
                            <Text color="neutral-textHigh">{script.event}</Text>
                          </Tag>
                        </Box>
                        <Box display={{ xs: 'block', lg: 'none' }}>
                          {<Versions script={script} index={i} />}
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          gap="2"
                          width="100%"
                          justifyContent="space-between"
                        >
                          <Box
                            display="flex"
                            width="100%"
                            justifyContent="flex-end"
                          >
                            {getScriptStatusI18n(script.status) && (
                              <Tag appearance="success">
                                <Text color="success-textHigh">
                                  {translate(
                                    `application-list-scripts:labels.${getScriptStatusI18n(
                                      script.status
                                    )}`
                                  )}
                                </Text>
                              </Tag>
                            )}
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            gap="2"
                          >
                            <IconButton
                              size="2rem"
                              onClick={async () => {
                                scripts.editTitle.mutate({
                                  scriptId: script.id,
                                  newScriptName: 'test'
                                });
                              }}
                              source={<EditIcon size="small" />}
                            />
                            <ActionMenu
                              allowedActions={script.allowed_actions}
                              scripts={scripts}
                              currentScript={script}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box display={{ xs: 'none', lg: 'block' }}>
                        {<Versions script={script} index={i} />}
                      </Box>
                    </Box>
                  </Collapsible.Item>
                  <Collapsible.Body>
                    <Box width="100%">
                      <ScriptsVersionsList
                        id={script.id}
                        versionsAmount={script.version_count}
                        versions={scripts.versions}
                        scripts={scripts}
                        currentScript={script}
                      />
                    </Box>
                  </Collapsible.Body>
                </Collapsible>
              </DataList.Row>
            )
          )}
        </DataList>
      </Card.Body>
      {totalPages > 1 && (
        <Card.Footer>
          <Box
            padding="4"
            display="flex"
            flexDirection={{
              md: 'row',
              xs: 'column'
            }}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box>
              <Text>
                {translate(
                  'application-list-scripts:text.pagination_description',
                  {
                    page: (pagination.page - 1) * PER_PAGE,
                    total_page: pagination.page * PER_PAGE,
                    count: scripts.scripts.count
                  }
                )}
              </Text>
            </Box>
            <Box>
              <Pagination
                activePage={pagination.page}
                pageCount={totalPages}
                onPageChange={pagination.onChangePage}
              />
            </Box>
          </Box>
        </Card.Footer>
      )}
    </Card>
  );
};

export default ScriptListCard;
