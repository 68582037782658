import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Loading } from 'components';
import { getAuth } from 'redux/selectors';
import { ROUTES } from 'commons/constants';

import PublicRoutes from '../PublicRoutes';
import PrivateRoutes from '../PrivateRoutes';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { amplitudeTrackAction } from 'commons/utils';
import { useAppCues } from 'hooks';

const Stats = React.lazy(() => import('domains/Auth/pages/Stats'));
const ResetPasswordSkeleton = React.lazy(
  () => import('domains/Auth/pages/ResetPassword/ResetPassword.skeleton')
);
const ResetPassword = React.lazy(
  () => import('domains/Auth/pages/ResetPassword')
);
const ConfirmEmail = React.lazy(
  () => import('domains/Auth/pages/ConfirmEmail')
);
const NotFound = React.lazy(() => import('domains/NotFound/NotFound'));

const CommonRoutes: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const sanitizedPathname = pathname
      .replace('/', '')
      .replaceAll('/', '-')
      .replace(/\d+/g, '')
      .concat('-screen-view')
      .replaceAll('--', '-');

    amplitudeTrackAction(sanitizedPathname, { pathname });
  }, [pathname]);

  useAppCues();

  const auth = useSelector(getAuth);
  return (
    <Suspense fallback={<Loading size="medium" />}>
      <Switch>
        <Route path={ROUTES.NOT_FOUND} exact>
          <NotFound />
        </Route>
        <Route path={ROUTES.HEALTH} exact>
          <div>Ok</div>
        </Route>
        <Route path={ROUTES.AUTH.STATS} exact>
          <Stats />
        </Route>
        <Route path={ROUTES.AUTH.RESET_PASSWORD} exact>
          <Suspense fallback={<ResetPasswordSkeleton />}>
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
            >
              <ResetPassword />
            </GoogleReCaptchaProvider>
          </Suspense>
        </Route>
        <Route path={ROUTES.AUTH.CONFIRM_EMAIL} exact>
          <ConfirmEmail />
        </Route>
        {!auth.accessToken.length ? <PublicRoutes /> : <PrivateRoutes />}
      </Switch>
    </Suspense>
  );
};

export default CommonRoutes;
