import React from 'react';
import { WhatsappIcon } from '@nimbus-ds/icons';
import { Card, Icon, Link, Text } from '@nimbus-ds/components';
import { useTranslate } from 'hooks';

const Doubts: React.FC = () => {
  const { translate } = useTranslate(['home', 'links']);
  return (
    <Card>
      <Card.Body>
        <Text>{translate('home:cards.doubts.title')}</Text>
        <Link as="a" appearance="primary" href={translate('links:whatsapp')}>
          <Icon source={<WhatsappIcon />} color="primary-interactive" />
          {translate('home:cards.doubts.link')}
        </Link>
      </Card.Body>
    </Card>
  );
};

export default Doubts;
