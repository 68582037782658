import {
  AnyAction,
  CombinedState,
  configureStore,
  ThunkDispatch
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import { persistStore } from 'redux-persist';
import rootReducer from './rootReducer';

export type RootStateType = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): ThunkDispatch<
  CombinedState<AppDispatch>,
  null,
  AnyAction
> => useDispatch<AppDispatch>();

export const persistor = persistStore(store);
export default store;
