import { UseQueryResult } from 'react-query';
import { PaymentsTypingsV2, AuthTypings } from 'types';
import {
  getOriginalCurrency,
  getPartnerEntryType,
  getPartnerEntryTypeOrigin
} from '../../payments.definition';

export interface IValidators {
  isApplication: boolean;
  isAffiliate: boolean;
  isStore: boolean;
  isCredit: boolean;
  isRescue: boolean;
  isPaid: boolean;
  isPending: boolean;
  isCancelled: boolean;
  isDifferentCountryCode: boolean;
  hasSingleAffiliateCommission: boolean;
  hasMultipleAffiliateCommissions?: boolean;
}

export const validate = (
  countryCode: AuthTypings.TCountryCode,
  query: UseQueryResult<
    {
      message: string;
      content: PaymentsTypingsV2.ILedgerDetails;
      statusCode: number;
    },
    unknown
  >
): IValidators => {
  const type = getPartnerEntryTypeOrigin(
    query?.data?.content?.ledger?.partnerLedgerEntryTypeId ?? 0
  );

  const partnerEntryType = getPartnerEntryType(
    query?.data?.content?.ledger?.status ?? '',
    query?.data?.content?.ledger?.partnerLedgerEntryTypeId ?? 0
  );

  const originalCurrency = getOriginalCurrency(
    query?.data?.content?.ledger?.originalCurrency ?? 'BRL'
  );

  const isCredit = !query.isLoading && partnerEntryType === 'credit';

  const isRescue = !query.isLoading && partnerEntryType === 'rescue';

  const isApplication =
    !query.isLoading &&
    (type === PaymentsTypingsV2.ELedgerEntryType.APP_PAYMENT ||
      type === PaymentsTypingsV2.ELedgerEntryType.PAYOUT);

  const isStore =
    !query.isLoading &&
    type === PaymentsTypingsV2.ELedgerEntryType.PARTNER_COMMISSION;

  const isAffiliate =
    !query.isLoading && type === PaymentsTypingsV2.ELedgerEntryType.AFFILIATE;

  const isPaid =
    !query.isLoading && (query?.data?.content?.ledger?.paidAt ?? null) !== null;

  const isPending =
    !query.isLoading &&
    query?.data?.content?.ledger?.status ===
      PaymentsTypingsV2.ELedgerStatus.PENDING;

  const isCancelled =
    !query.isLoading &&
    query?.data?.content?.ledger?.status ===
      PaymentsTypingsV2.ELedgerStatus.CANCELED;

  const isDifferentCountryCode = countryCode !== originalCurrency.code;

  const hasSingleAffiliateCommission =
    query?.data?.content?.ledger?.totalAffiliateCommissions === 1;

  const hasMultipleAffiliateCommissions =
    query?.data?.content?.affiliateCommissions &&
    query?.data?.content?.affiliateCommissions?.length > 1;

  return {
    isApplication,
    isAffiliate,
    isStore,
    isCredit,
    isRescue,
    isPaid,
    isPending,
    isCancelled,
    isDifferentCountryCode,
    hasSingleAffiliateCommission,
    hasMultipleAffiliateCommissions
  };
};
