import React from 'react';

import { IItem } from './item.types';
import styles from './item.module.scss';

const Item: React.FC<IItem> = ({ label, ...props }) => (
  <>
    <input {...props} className={styles.input} type="radio" />
    <label className={styles.label} htmlFor={props.id}>
      {label}
    </label>
  </>
);

export default Item;
