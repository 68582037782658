import React from 'react';

import { Box } from '@nimbus-ds/components';
import { ENABLE_RECAPTCHA } from 'commons/constants';
import { Logo, Skeleton } from 'components';
import { BaseAuthSkeletonInterface } from './baseAuth.types';
import './baseAuth.styled.scss';

const BaseAuth: React.FC<BaseAuthSkeletonInterface> = ({
  className,
  children,
  title,
  text,
  link
}) => {
  return (
    <Box height="100%" minHeight="100vh" display="flex" alignItems="stretch">
      <Box
        width={{
          md: '100%',
          xs: '100%',
          xl: '400px',
          lg: '400px'
        }}
        padding="8"
        position="relative"
        display="flex"
        flexDirection="column"
      >
        <Box>
          <Logo />
        </Box>
        {title && (
          <Box marginY="8" display="flex" flexDirection="column">
            <Skeleton height={40} width="80%" borderRadius={8} />
          </Box>
        )}
        <Box flex="1" className={className}>
          {children}
          <Box marginTop="8" height="100%">
            {text && (
              <Box display="flex" flexDirection="column">
                <Skeleton height={16} width="90%" />
              </Box>
            )}
            {link && (
              <Box display="flex" flexDirection="column">
                <Skeleton
                  height={16}
                  width="80%"
                  marginTop={8}
                  borderRadius={4}
                />
                <Skeleton
                  height={16}
                  width="40%"
                  marginTop={8}
                  borderRadius={4}
                />
              </Box>
            )}
          </Box>
        </Box>
        {ENABLE_RECAPTCHA && (
          <>
            <Skeleton
              height={16}
              width="80%"
              marginBottom={4}
              marginTop={34}
              borderRadius={4}
            />
            <Skeleton
              height={16}
              width="80%"
              marginBottom={4}
              borderRadius={4}
            />
            <Skeleton
              height={16}
              width="20%"
              marginBottom={4}
              borderRadius={4}
            />
          </>
        )}
      </Box>
      <div className="baseauth-ilustration-wrapper" />
    </Box>
  );
};

export default BaseAuth;
