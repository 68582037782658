import ReactGA from 'react-ga4';

const ga = () => {
  if (process.env.REACT_APP_ANALYTICS_ID) {
    const page = window.location.pathname + window.location.search;
    ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
    ReactGA.send({ hitType: 'pageview', page, title: page });
  }
};

const gaEvent = (category: string, action: string, label: string) => {
  if (process.env.REACT_APP_ANALYTICS_ID) {
    ReactGA.event({
      category: category,
      action: action,
      label,
      nonInteraction: true
    });
  }
};

const gaEventValue = (
  category: string,
  action: string,
  label: string,
  value: number
) => {
  if (process.env.REACT_APP_ANALYTICS_ID) {
    ReactGA.event({
      category: category,
      action: action,
      label,
      value
    });
  }
};

export { ga, gaEvent, gaEventValue };
