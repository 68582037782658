import { appCuesWindowType } from 'commons/utils/appcues/appcues';
import { useFetchV2 } from 'hooks';
import useAuth from 'hooks/useAuth/useAuth';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { AccountTypings } from 'types';

const useAppCues = () => {
  const { auth } = useAuth();
  const { request } = useFetchV2();

  const { data: registration } = useQuery(
    ['registration', auth.partnerId],
    () =>
      request<AccountTypings.IRegistration>({
        url: `/partners/${auth.partnerId}`
      }),
    {
      retry: false,
      enabled: !!auth.partnerId,
      staleTime: 300000 // 5 minutes
    }
  );

  const location = useLocation();

  useEffect(() => {
    if (registration?.content) {
      const externalUserId = registration.content.id;
      const fullName = registration.content?.fullName?.split(' ');
      const firstName = fullName ? fullName[0] : registration.content.name;
      const lastName =
        fullName && fullName.length > 1 ? fullName[1] : undefined;
      const email = registration?.content.email;
      const countryCode = registration.content.countryCode;
      const createdAt = registration.content.createdAt;

      (window as unknown as appCuesWindowType)?.Appcues?.identify?.(email, {
        externalUserId,
        firstName,
        lastName,
        email,
        countryCode,
        createdAt
      });
    }
  }, [registration, location]);
};

export default useAppCues;
