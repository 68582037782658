import React from 'react';
import { Text, Title, Link } from '@tiendanube/components';
import { LogOutIcon } from '@nimbus-ds/icons';
import { Card } from 'components';
import { useTranslate } from 'hooks';

import styles from './platformCard.module.scss';
import { listCards } from './platformCard.definitions';

const PlatformCard: React.FC = () => {
  const { translate } = useTranslate(['partnership-plan', 'links']);
  return (
    <Card className={styles.card}>
      <Title type="h5">{`${translate(
        'partnership-plan:start_platform.title'
      )}`}</Title>
      <Text>{`${translate('partnership-plan:start_platform.subtitle')}`}</Text>
      <ul className={styles.list}>
        {listCards.map((item) => {
          return (
            <Link
              appearance="primary"
              key={item.link}
              href={translate(item.link)}
              target="_blank"
            >
              {`${translate(item.text)}`}
              <div className={styles['logout-icon']}>
                <LogOutIcon />
              </div>
            </Link>
          );
        })}
      </ul>
    </Card>
  );
};

export default PlatformCard;
