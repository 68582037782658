import React from 'react';
import { Title, Text, Box, Accordion, Icon, Link } from '@nimbus-ds/components';
import { useAuth, useTopMenu, useTranslate } from 'hooks';
import { DocumentationTypings } from 'types';
import { documentationIcons } from './documentation.definitions';

const Documentation: React.FC = () => {
  const { translate } = useTranslate('documentation');
  const { auth } = useAuth();
  useTopMenu();
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        padding="6"
        gap="8"
        width={{
          xl: '70%',
          md: '100%'
        }}
      >
        <Title>{`${translate('title.documentation')}`}</Title>
        <Text>{translate('subtitle.documentation')}</Text>
        <Box
          borderStyle="solid"
          borderWidth="1"
          borderColor="neutral-surfaceHighlight"
          borderRadius="0-5"
          overflow="hidden"
        >
          <Accordion>
            {DocumentationTypings.links.map((documentation, index) => {
              const links = (
                documentation.links as {
                  [key: string]: string[];
                }
              )[auth.countryLanguage] as string[];
              return (
                <Accordion.Item
                  key={documentation.title}
                  index={`${index}`}
                  testId={`accordion-item-${documentation.title}`}
                >
                  <Accordion.Header
                    borderTop={index === 0 ? 'none' : 'base'}
                    icon={
                      <Box
                        backgroundColor="danger-surface"
                        minWidth="2.5rem"
                        minHeight="2.5rem"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="0-5"
                      >
                        <Icon
                          source={documentationIcons[documentation.title]}
                        />
                      </Box>
                    }
                    title={translate(
                      `documentation.${documentation.title}.title`
                    )}
                    subtitle={`${links.length} ${translate(
                      links.length > 1
                        ? 'subtitle.contents'
                        : 'subtitle.content'
                    )}`}
                  />
                  <Accordion.Body padding="none">
                    <Box display="flex" flexDirection="column" width="100%">
                      {links.map((link) => (
                        <Box
                          key={link}
                          padding="4"
                          borderStyle="solid"
                          borderColor="neutral-surfaceHighlight"
                          borderTopWidth="1"
                        >
                          <Link
                            as="a"
                            className="documentation__list-link"
                            textDecoration="none"
                            href={translate(
                              `documentation.${documentation.title}.links.${link}.link`
                            )}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {translate(
                              `documentation.${documentation.title}.links.${link}.title`
                            )}
                          </Link>
                        </Box>
                      ))}
                    </Box>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Box>
      </Box>
    </>
  );
};
export default Documentation;
