import React from 'react';
import { Button } from '@tiendanube/components';

import { Card } from 'components';
import { useTranslate } from 'hooks';
import { useLogout } from 'domains/Account/hooks';

const Session: React.FC = () => {
  const { onLogout } = useLogout();
  const { translate } = useTranslate('account');
  return (
    <Card className="account__card">
      <Card.Header title={translate('title.session')} />
      <Card.Body
        className="account__card--body"
        message={translate('subtitle.session')}
      />
      <Card.Footer>
        <Button onClick={() => onLogout()}>
          {`${translate('buttons.log_off')}`}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default Session;
