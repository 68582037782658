import { useEffect, useMemo, useState } from 'react';
import { SelectOptionProperties } from '@nimbus-ds/components';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { ApplicationsTypings, StoresTypings } from 'types';
import { useAuth, useCommons, useFetchV2, useToast } from 'hooks';

import { ELocale } from 'app/i18n/i18n.types';
import { ROUTES, scrollToTop } from 'commons/constants';
import { IApiResponse } from 'hooks/useFetch/useFetch.types';
import {
  IDemoStoreOptions,
  TApplicationDetailsResponse
} from '../../pages/Details/details.types';

const useDetails = (id: string, country: ELocale) => {
  const [demoStore, setDemoStore] = useState<IDemoStoreOptions | undefined>(
    undefined
  );
  const { setAlert } = useCommons();
  const { request } = useFetchV2();
  const { auth } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const {
    data: details,
    isLoading: loadingDetails,
    refetch: onGetApp
  } = useQuery(
    ['application', auth.accessToken],
    () =>
      request<TApplicationDetailsResponse>({
        url: `/apps-deprecated/${id}?fields=name,categoryId,id,clientSecret,status,visibility,redirectUrl,scopeIdList,appUrl,preferencesUrl,privacyPolicyUrl,supportUrl,supportEmail,storeRedactUrl,customersDataRequestUrl,customersRedactUrl,screenshotList,billingList&lang=${country}`,
        method: 'GET'
      }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!auth.accessToken,
      onError: (err: IApiResponse<any>) => {
        err?.statusCode === 404 && history.push(ROUTES.NOT_FOUND);
      }
    }
  );

  const transformDemoStore = (
    stores: StoresTypings.IStoresData[] | undefined
  ) => {
    const demoStoreList = stores?.reduce(
      (demoStoreList: SelectOptionProperties[], currentDemoStore) => {
        const obj: SelectOptionProperties = {
          label: currentDemoStore?.name as string,
          value: currentDemoStore?.url
        };
        demoStoreList.push(obj);
        return demoStoreList;
      },
      []
    );
    return demoStoreList;
  };

  const { data: storesDemoList, isLoading: loadingDemoStoreList } = useQuery(
    ['storesDemo', auth.partnerId],
    () =>
      request<StoresTypings.IStores>({
        url: `/legacy/stores/${auth.partnerId}`,
        method: 'GET',
        params: {
          take: 10,
          skip: 0,
          filter: 'demo'
        }
      }),
    {
      cacheTime: 0,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!auth.partnerId
    }
  );

  useEffect(() => {
    const options = transformDemoStore(storesDemoList?.content.data);
    if (!options) {
      setDemoStore(undefined);
    }
    setDemoStore({ options, selected: options?.[0]?.value });
  }, [storesDemoList?.content.data]);

  const { isLoading: loadingPublish, refetch: onPublishOrUnpublishApp } =
    useQuery(
      ['publish', auth.accessToken],
      () =>
        request({
          url: `/apps/${id}/${
            details?.content?.status !==
            ApplicationsTypings.EApplicationStatus.PUBLISHED
              ? 'publish'
              : 'unpublish'
          }`,
          method: 'PUT'
        }),
      {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: false,
        onSuccess: (response: IApiResponse<any>) => {
          if (!IS_PUBLISHED) {
            addToast({
              appearance: 'success',
              label: response.message
            });
          }
          onGetApp();
        },
        onError(response: IApiResponse<any>) {
          addToast({
            appearance: 'danger',
            label: response.message
          });
        }
      }
    );

  const onRequestHomologation = useMutation(
    () =>
      request({
        url: `/apps/${id}/request-homologation`,
        method: 'PUT'
      }),
    {
      onSuccess: () => {
        scrollToTop();
        setAlert({
          details: {
            appearance: 'success',
            title: `common:alerts.homologation_valid.title`,
            message: `common:alerts.homologation_valid.message`
          }
        });
        onGetApp();
      },
      onError: () => {
        scrollToTop();
        let errorMessage = 'generic_message';

        if (
          STATUS_BASIC_DATA ===
            ApplicationsTypings.EApplicationStatusData.PENDING &&
          STATUS_PUBLICATION_DATA ===
            ApplicationsTypings.EApplicationStatusData.FINISHED
        ) {
          errorMessage = 'basic_data_invalid_message';
        }

        if (
          STATUS_BASIC_DATA ===
            ApplicationsTypings.EApplicationStatusData.FINISHED &&
          STATUS_PUBLICATION_DATA ===
            ApplicationsTypings.EApplicationStatusData.PENDING
        ) {
          errorMessage = 'publication_data_invalid_message';
        }

        setAlert({
          details: {
            appearance: 'danger',
            title: `common:alerts.homologation_invalid.title`,
            message: `common:alerts.homologation_invalid.${errorMessage}`
          }
        });
      }
    }
  );

  const LOADING_APP_INFORMATION = useMemo(
    () =>
      loadingDetails ||
      loadingDemoStoreList ||
      loadingPublish ||
      onRequestHomologation.isLoading,
    [
      loadingDetails,
      loadingDemoStoreList,
      loadingPublish,
      onRequestHomologation.isLoading
    ]
  );

  const STATUS_APP = useMemo(
    () => !LOADING_APP_INFORMATION && details?.content.status,
    [LOADING_APP_INFORMATION, details?.content.status]
  );

  const INSTALLATION_URL_DEMO_STORE = useMemo(
    () =>
      !LOADING_APP_INFORMATION && !demoStore?.selected
        ? null
        : `${demoStore?.selected}/admin/apps/${details?.content.id}/authorize`,
    [LOADING_APP_INFORMATION, details?.content.id, demoStore?.selected]
  );

  const IS_PUBLISHED = useMemo(
    () =>
      !LOADING_APP_INFORMATION &&
      details?.content?.status ===
        ApplicationsTypings.EApplicationStatus.PUBLISHED,
    [LOADING_APP_INFORMATION, details?.content?.status]
  );

  const IS_PUBLIC_APP = useMemo(
    () =>
      !LOADING_APP_INFORMATION &&
      details?.content.visibility !==
        ApplicationsTypings.EApplicationVisibility.PRIVATE,
    [LOADING_APP_INFORMATION, details?.content.visibility]
  );

  const STATUS_BASIC_DATA = useMemo(() => {
    if (
      details?.content?.categoryId &&
      details?.content?.scopeIdList &&
      details?.content?.redirectUrl &&
      details?.content.appUrl &&
      details?.content.customersDataRequestUrl &&
      details?.content?.customersRedactUrl &&
      details?.content?.storeRedactUrl
    ) {
      return ApplicationsTypings.EApplicationStatusData.FINISHED;
    }
    return ApplicationsTypings.EApplicationStatusData.PENDING;
  }, [
    details?.content.appUrl,
    details?.content?.categoryId,
    details?.content.customersDataRequestUrl,
    details?.content?.customersRedactUrl,
    details?.content?.redirectUrl,
    details?.content?.scopeIdList,
    details?.content?.storeRedactUrl
  ]);

  const STATUS_PUBLICATION_DATA = useMemo(() => {
    if (
      details?.content?.preferencesUrl &&
      details?.content?.privacyPolicyUrl &&
      details?.content?.supportUrl &&
      details?.content?.screenshotList &&
      details?.content?.billingList?.length
    ) {
      return ApplicationsTypings.EApplicationStatusData.FINISHED;
    }
    return ApplicationsTypings.EApplicationStatusData.PENDING;
  }, [
    details?.content?.billingList,
    details?.content?.preferencesUrl,
    details?.content?.privacyPolicyUrl,
    details?.content?.screenshotList,
    details?.content?.supportUrl
  ]);

  const handleDemoStore = (selectedStore: any) => {
    setDemoStore({ ...demoStore, selected: selectedStore });
  };

  return {
    details,
    demoStore,
    handleDemoStore,
    onPublishOrUnpublishApp,
    onRequestHomologation,
    LOADING_APP_INFORMATION,
    IS_PUBLISHED,
    INSTALLATION_URL_DEMO_STORE,
    STATUS_BASIC_DATA,
    STATUS_PUBLICATION_DATA,
    IS_PUBLIC_APP,
    STATUS_APP
  };
};

export default useDetails;
