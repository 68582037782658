import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ExternalLinkIcon, StoreIcon } from '@nimbus-ds/icons';
import {
  Box,
  Button,
  Icon,
  Link as NimbusLink,
  Text,
  Card as NimbusCard,
  Title
} from '@nimbus-ds/components';

import { useTranslate } from 'hooks';
import { ROUTES } from 'commons/constants';

const DemoStore: React.FC<{ storeDemoUrl?: string | null }> = ({
  storeDemoUrl
}) => {
  const { translate } = useTranslate(['home', 'links']);
  const url = useMemo(() => storeDemoUrl as string, [storeDemoUrl]);
  const history = useHistory();

  return (
    <NimbusCard>
      <NimbusCard.Header>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box marginRight="2">
            <StoreIcon size="medium" />
          </Box>
          <Title as="h4">{`${translate('home:cards.demo_store.title')}`}</Title>
        </Box>
      </NimbusCard.Header>
      <NimbusCard.Body>
        <Box marginBottom="8">
          <Text>
            {`${translate(
              `home:cards.demo_store.${
                url ? 'text_has_store' : 'text_create_store'
              }`
            )}`}
          </Text>
        </Box>
      </NimbusCard.Body>
      <NimbusCard.Footer>
        <Box display="flex" flexDirection="row">
          <Box marginRight="2">
            <Button
              onClick={() =>
                history.push(
                  url
                    ? `${ROUTES.STORES.DEFAULT}?filter=demo`
                    : `${ROUTES.STORES.CREATE}?type=demo`
                )
              }
            >
              {`${translate(
                `home:cards.demo_store.${
                  url ? 'button_access_store' : 'button_create_store'
                }`
              )}`}
            </Button>
          </Box>

          <Box display="flex" alignItems="center">
            <NimbusLink
              as="a"
              href={translate('links:demo_store_help')}
              target="_blank"
              textDecoration="none"
            >
              <Box display="flex" flexDirection="row">
                <Box marginRight="2">
                  <Text fontWeight="bold" as="span" color="primary-textLow">
                    {translate('home:cards.demo_store.more_info')}
                  </Text>
                </Box>
                <Icon color="primary-textHigh" source={<ExternalLinkIcon />} />
              </Box>
            </NimbusLink>
          </Box>
        </Box>
      </NimbusCard.Footer>
    </NimbusCard>
  );
};

export default DemoStore;
