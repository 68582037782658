import React from 'react';
import { Field, FieldProps } from 'formik';
import { CheckIcon } from '@nimbus-ds/icons';

import { IRadioButton } from './radiobutton.types';
import './radiobutton.styled.scss';

const Radiobutton = ({
  name,
  label,
  className,
  value,
  dataTestId,
  disabled = false
}: IRadioButton): JSX.Element => (
  <div className={className}>
    <Field name={name} type="radio" value={value}>
      {({ field }: FieldProps) => (
        <div className="radiobutton-wrapper">
          <input
            {...field}
            id={`radio${label || value}`}
            className="radiobutton-input"
            type="radio"
            data-testid={dataTestId}
            disabled={disabled}
          />
          <label
            className="radiobutton-label"
            htmlFor={`radio${label || value}`}
          >
            {label}
          </label>
          <div className="radiobutton__check">
            <CheckIcon />
          </div>
        </div>
      )}
    </Field>
  </div>
);

export default Radiobutton;
