import { rest } from 'msw';
import { PaymentsMocksV2 } from 'types';

const _defaultMock = {
  message: '',
  content: ''
};

const handlers = [
  rest.get(
    `${process.env.REACT_APP_API_BFF}/partners/:partnerId/ledgers`,
    async (_req, res, ctx) => {
      return res(ctx.json({ content: PaymentsMocksV2.Ledgers.generate() }));
    }
  )
];

export { handlers };
