import * as amplitude from '@amplitude/analytics-browser';

export function amplitudeInit() {
  if (process.env.REACT_APP_AMPLITUDE_ID) {
    const authStorage = localStorage.getItem('persist:auth');
    if (authStorage) {
      const auth = JSON.parse(authStorage);
      const partnerId = auth.partnerId;
      amplitude.init(process.env.REACT_APP_AMPLITUDE_ID, partnerId);
    } else {
      amplitude.init(process.env.REACT_APP_AMPLITUDE_ID);
    }
  }
}

export function amplitudeTrackAction(
  action: string,
  props?: { [key: string]: string }
) {
  props
    ? amplitude.logEvent(`partnersportal-${action}`, props)
    : amplitude.logEvent(`partnersportal-${action}`);
}
