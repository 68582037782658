import React from 'react';
import { Button, Card, Skeleton, Box } from '@nimbus-ds/components';

const SealSkeleton: React.FC = () => {
  return (
    <Card>
      <Card.Header>
        <Skeleton width="200px" height="32px" />
      </Card.Header>
      <Card.Body>
        <Box display="flex" gap="2" flexDirection="column">
          <Skeleton width="100%" height="24px" />
          <Skeleton width="100%" height="24px" />
        </Box>
      </Card.Body>
      <Card.Footer>
        <Button.Skeleton width="120px" />
        <Button.Skeleton width="190px" />
      </Card.Footer>
    </Card>
  );
};

export default SealSkeleton;
