import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ROUTES } from 'commons/constants';
import { AppLayout, Loading } from 'components';
import {
  AccountRoutes,
  ApplicationsRoutes,
  PaymentsRoutes,
  StoresRoutes
} from 'domains';
import { HomeRoutes } from 'domains/Home';

const NotFound = React.lazy(() => import('domains/NotFound/NotFound'));
const Home = React.lazy(() => import('domains/Home'));
const Documentation = React.lazy(() => import('domains/Documentation'));
const PartnershipPlan = React.lazy(() => import('domains/PartnershipPlan'));

const PrivateRoutes: React.FC = () => (
  <Suspense fallback={<Loading size="medium" />}>
    <AppLayout>
      <Switch>
        <Route path={ROUTES.NOT_FOUND} exact>
          <NotFound />
        </Route>
        <Route path={ROUTES.DOCUMENTATION} exact>
          <Documentation />
        </Route>
        <Route path={ROUTES.APPLICATIONS.DEFAULT}>
          <ApplicationsRoutes />
        </Route>
        <Route path={ROUTES.ACCOUNT.DEFAULT}>
          <AccountRoutes />
        </Route>
        <Route path={ROUTES.PAYMENTS.DEFAULT}>
          <PaymentsRoutes />
        </Route>
        <Route path={ROUTES.STORES.DEFAULT}>
          <StoresRoutes />
        </Route>
        <Route path={ROUTES.PARTNERSHIP_PLAN}>
          <PartnershipPlan />
        </Route>
        <Route path={ROUTES.AUTH.INTRODUCTION} exact>
          <Redirect to={ROUTES.DEFAULT} />
        </Route>
        <Route path={ROUTES.AUTH.CREATE_ACCOUNT} exact>
          <Home />
        </Route>
        <Route path={ROUTES.AUTH.FORGOT_PASSWORD} exact>
          <Home />
        </Route>
        <Route path={ROUTES.HOME}>
          <HomeRoutes />
        </Route>
        <Redirect exact from={ROUTES.DEFAULT} to={ROUTES.HOME} />
        <Redirect from="*" to={ROUTES.NOT_FOUND} />
      </Switch>
    </AppLayout>
  </Suspense>
);

export default PrivateRoutes;
