import axios from 'axios';

const axiosApiInstance = axios.create();
axiosApiInstance.defaults.baseURL = process.env.REACT_APP_API_BFF;

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['X-Frame-Options'] = 'DENY';
      config.headers['X-XSS-Protection'] = '1; mode=block';
      config.headers['X-Content-Type-Options'] = 'no-sniff';
      config.headers['X-Download-Options'] = 'noopen';
      config.headers['Referrer-Policy'] = 'no-referrer';
      config.headers['Strict-Transport-Security'] =
        'max-age=31536000; includeSubDomains;';
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosApiInstance;
