import { AuthTypings } from 'types';

export const AdminAppActionImages = {
  [AuthTypings.ECountryLanguage.BRAZIL]:
    'https://tiendanube-mati.s3.sa-east-1.amazonaws.com/apps/create-app-tutorial/admin-app-action-pt.png',
  [AuthTypings.ECountryLanguage.ARGENTINA]:
    'https://tiendanube-mati.s3.sa-east-1.amazonaws.com/apps/create-app-tutorial/admin-app-actions-es.png'
} as {
  [key in AuthTypings.ECountryLanguage]: string;
};

export const ErrorInstallAppActionImages = {
  [AuthTypings.ECountryLanguage.BRAZIL]:
    'https://tiendanube-mati.s3.sa-east-1.amazonaws.com/apps/create-app-tutorial/error-install-app-action-pt.png',
  [AuthTypings.ECountryLanguage.ARGENTINA]:
    'https://tiendanube-mati.s3.sa-east-1.amazonaws.com/apps/create-app-tutorial/error-install-app-action-es.png'
} as {
  [key in AuthTypings.ECountryLanguage]: string;
};

export const ExampleCurlImages = {
  [AuthTypings.ECountryLanguage.BRAZIL]:
    'https://tiendanube-mati.s3.sa-east-1.amazonaws.com/apps/create-app-tutorial/curl-code-pt.png',
  [AuthTypings.ECountryLanguage.ARGENTINA]:
    'https://tiendanube-mati.s3.sa-east-1.amazonaws.com/apps/create-app-tutorial/curl-code-es.png'
} as {
  [key in AuthTypings.ECountryLanguage]: string;
};

export const InstallAppImages = {
  [AuthTypings.ECountryLanguage.BRAZIL]:
    'https://tiendanube-mati.s3.sa-east-1.amazonaws.com/apps/create-app-tutorial/install-app-pt.png',
  [AuthTypings.ECountryLanguage.ARGENTINA]:
    'https://tiendanube-mati.s3.sa-east-1.amazonaws.com/apps/create-app-tutorial/install-app-es.png'
} as {
  [key in AuthTypings.ECountryLanguage]: string;
};

export const LpCurlCodeImages = {
  [AuthTypings.ECountryLanguage.BRAZIL]:
    'https://tiendanube-mati.s3.sa-east-1.amazonaws.com/apps/create-app-tutorial/lp-curl-code-pt.png',
  [AuthTypings.ECountryLanguage.ARGENTINA]:
    'https://tiendanube-mati.s3.sa-east-1.amazonaws.com/apps/create-app-tutorial/correct-lp-es.png'
} as {
  [key in AuthTypings.ECountryLanguage]: string;
};

export const LpFailInstallAppImages = {
  [AuthTypings.ECountryLanguage.BRAZIL]:
    'https://tiendanube-mati.s3.sa-east-1.amazonaws.com/apps/create-app-tutorial/lp-fail-install-app-pt.png',
  [AuthTypings.ECountryLanguage.ARGENTINA]:
    'https://tiendanube-mati.s3.sa-east-1.amazonaws.com/apps/create-app-tutorial/lp-fail-install-app-es.png'
} as {
  [key in AuthTypings.ECountryLanguage]: string;
};

export const RequestResponseImages = {
  [AuthTypings.ECountryLanguage.BRAZIL]:
    'https://tiendanube-mati.s3.sa-east-1.amazonaws.com/apps/create-app-tutorial/response-install.png',
  [AuthTypings.ECountryLanguage.ARGENTINA]:
    'https://tiendanube-mati.s3.sa-east-1.amazonaws.com/apps/create-app-tutorial/response-install.png'
} as {
  [key in AuthTypings.ECountryLanguage]: string;
};
