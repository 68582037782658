// Typescript implementation from: https://github.com/the-darc/string-mask

interface Token {
  pattern?: RegExp;
  optional?: boolean;
  recursive?: boolean;
  transform?: (c: string) => string;
  escape?: boolean;
  _default?: string;
}

interface Options {
  reverse?: boolean;
  usedefaults?: boolean;
}

class StringMask {
  private pattern: string;
  private options: Options;

  constructor(pattern: string, opt?: Options) {
    this.options = opt || {};
    this.options = {
      reverse: this.options.reverse || false,
      usedefaults: this.options.usedefaults || this.options.reverse
    };
    this.pattern = pattern;
  }

  private isEscaped(pattern: string, pos: number): boolean {
    let count = 0;
    let i = pos - 1;
    let token: Token = { escape: true };
    while (i >= 0 && token && token.escape) {
      token = tokens[pattern.charAt(i)];
      count += token && token.escape ? 1 : 0;
      i--;
    }
    return count > 0 && count % 2 === 1;
  }

  private calcOptionalNumbersToUse(pattern: string, value: string): number {
    const numbersInP = pattern.replace(/[^0]/g, '').length;
    const numbersInV = value.replace(/[^\d]/g, '').length;
    return numbersInV - numbersInP;
  }

  private concatChar(text: string, character: string, token?: Token): string {
    if (token && typeof token.transform === 'function') {
      character = token.transform(character);
    }
    if (this.options.reverse) {
      return character + text;
    }
    return text + character;
  }

  private hasMoreTokens(pattern: string, pos: number, inc: number): boolean {
    const pc = pattern.charAt(pos);
    const token = tokens[pc];
    if (pc === '') {
      return false;
    }
    return token && !token.escape
      ? true
      : this.hasMoreTokens(pattern, pos + inc, inc);
  }

  private hasMoreRecursiveTokens(
    pattern: string,
    pos: number,
    inc: number
  ): boolean {
    const pc = pattern.charAt(pos);
    const token = tokens[pc];
    if (pc === '') {
      return false;
    }
    return token && token.recursive
      ? true
      : this.hasMoreRecursiveTokens(pattern, pos + inc, inc);
  }

  private insertChar(text: string, char: string, position: number): string {
    const t = text.split('');
    t.splice(position, 0, char);
    return t.join('');
  }

  public process(value: string): { result: string; valid: boolean } {
    if (!value) {
      return { result: '', valid: false };
    }
    value = (value + '').replace(/[^a-zA-Z0-9]/g, '');
    let pattern2 = this.pattern;
    let valid = true;
    let formatted = '';
    let valuePos = this.options.reverse ? value.length - 1 : 0;
    let patternPos = 0;
    let optionalNumbersToUse = this.calcOptionalNumbersToUse(pattern2, value);
    let escapeNext = false;
    let inRecursiveMode = false;
    const recursive: string[] = [];

    const steps = {
      start: this.options.reverse ? pattern2.length - 1 : 0,
      end: this.options.reverse ? -1 : pattern2.length,
      inc: this.options.reverse ? -1 : 1
    };

    const continueCondition = (options: Options): boolean => {
      if (
        !inRecursiveMode &&
        !recursive.length &&
        this.hasMoreTokens(pattern2, patternPos, steps.inc)
      ) {
        return true;
      } else if (
        !inRecursiveMode &&
        recursive.length &&
        this.hasMoreRecursiveTokens(pattern2, patternPos, steps.inc)
      ) {
        return true;
      } else if (!inRecursiveMode) {
        inRecursiveMode = recursive.length > 0;
      }

      if (inRecursiveMode) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const pc = recursive.shift()!;
        recursive.push(pc);
        if (options.reverse && valuePos >= 0) {
          patternPos++;
          pattern2 = this.insertChar(pattern2, pc, patternPos);
          return true;
        } else if (!options.reverse && valuePos < value.length) {
          pattern2 = this.insertChar(pattern2, pc, patternPos);
          return true;
        }
      }
      return patternPos < pattern2.length && patternPos >= 0;
    };

    for (
      patternPos = steps.start;
      continueCondition(this.options);
      patternPos = patternPos + steps.inc
    ) {
      const vc = value.charAt(valuePos);
      const pc = pattern2.charAt(patternPos);

      let token: Token | undefined = tokens[pc];
      if (recursive.length && token && !token.recursive) {
        token = undefined;
      }

      if (!inRecursiveMode || vc) {
        if (this.options.reverse && this.isEscaped(pattern2, patternPos)) {
          formatted = this.concatChar(formatted, pc, token);
          patternPos = patternPos + steps.inc;
          continue;
        } else if (!this.options.reverse && escapeNext) {
          formatted = this.concatChar(formatted, pc, token);
          escapeNext = false;
          continue;
        } else if (!this.options.reverse && token && token.escape) {
          escapeNext = true;
          continue;
        }
      }

      if (!inRecursiveMode && token && token.recursive) {
        recursive.push(pc);
      } else if (inRecursiveMode && !vc) {
        formatted = this.concatChar(formatted, pc, token);
        continue;
      } else if (!inRecursiveMode && recursive.length > 0 && !vc) {
        continue;
      }

      if (!token) {
        formatted = this.concatChar(formatted, pc, token);
        if (!inRecursiveMode && recursive.length) {
          recursive.push(pc);
        }
      } else if (token.optional) {
        if (token.pattern && token.pattern.test(vc) && optionalNumbersToUse) {
          formatted = this.concatChar(formatted, vc, token);
          valuePos = valuePos + steps.inc;
          optionalNumbersToUse--;
        } else if (recursive.length > 0 && vc) {
          valid = false;
          break;
        }
      } else if (token.pattern && token.pattern.test(vc)) {
        formatted = this.concatChar(formatted, vc, token);
        valuePos = valuePos + steps.inc;
      } else if (!vc && token._default && this.options.usedefaults) {
        formatted = this.concatChar(formatted, token._default, token);
      } else {
        valid = false;
        break;
      }
    }

    return { result: formatted, valid: valid };
  }

  public apply(value: string): string {
    return this.process(value).result;
  }

  public validate(value: string): boolean {
    return this.process(value).valid;
  }

  public static process(
    value: string,
    pattern: string,
    options?: Options
  ): { result: string; valid: boolean } {
    return new StringMask(pattern, options).process(value);
  }

  public static apply(
    value: string,
    pattern: string,
    options?: Options
  ): string {
    return new StringMask(pattern, options).apply(value);
  }

  public static validate(
    value: string,
    pattern: string,
    options?: Options
  ): boolean {
    return new StringMask(pattern, options).validate(value);
  }
}

const tokens: { [key: string]: Token } = {
  '0': { pattern: /\d/, _default: '0' },
  '9': { pattern: /\d/, optional: true },
  '#': { pattern: /\d/, optional: true, recursive: true },
  A: { pattern: /[a-zA-Z0-9]/ },
  S: { pattern: /[a-zA-Z]/ },
  U: { pattern: /[a-zA-Z]/, transform: (c) => c.toLocaleUpperCase() },
  L: { pattern: /[a-zA-Z]/, transform: (c) => c.toLocaleLowerCase() },
  $: { escape: true }
};

export default StringMask;
