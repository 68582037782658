import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { currency } from 'commons/formatters';
import { useFetchV2, useAuth, useTranslate } from 'hooks';
import { PaymentsTypings } from 'types';

const useBalance = () => {
  const { auth } = useAuth();
  const { request } = useFetchV2();
  const { language } = useTranslate('payments');
  const queryClient = useQueryClient();

  const query = useQuery(
    ['available-balance', auth.partnerId],
    () =>
      request<PaymentsTypings.IAvailableBalances>({
        url: `/v2/partners/${auth.partnerId}/available-balances`
      }),
    {
      staleTime: 2000, // 2 seconds
      retry: false
    }
  );

  const invalidateQuery = useCallback(
    () => queryClient.invalidateQueries(['available-balance', auth.partnerId]),
    [auth.partnerId, queryClient]
  );

  const totalBalance = useMemo(() => {
    if (query.isLoading) {
      return currency(0, language, auth.currency, auth.countryCode);
    }
    return currency(
      query.data?.content?.balance ?? 0,
      language,
      auth.currency,
      auth.countryCode
    );
  }, [
    query.data?.content?.balance,
    language,
    auth.currency,
    auth.countryCode,
    query.isLoading
  ]);

  return {
    ...query,
    totalBalance,
    invalidateQuery
  };
};

export default useBalance;
