import React from 'react';
import { Spinner } from '@nimbus-ds/components';
import classNames from 'classnames';
import { ILoadingProps } from './loading.types';
import './loading.styled.scss';

const Loading: React.FC<ILoadingProps> = ({ color, size, className }) => {
  const classnames = classNames({
    'loading-container': true,
    [className || '']: className && true
  });

  return (
    <div className={classnames}>
      <Spinner color={color} size={size} />
    </div>
  );
};

export default Loading;
