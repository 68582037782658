import React, { useEffect, useState, useRef } from 'react';
import { useFormikContext } from 'formik';
import { Box, FileUploader, Text } from '@nimbus-ds/components';

import {
  IImageGallery,
  IImagePostion,
  IImagesGallery
} from './imageGallery.types';
import { Item } from './widgets';
import { useToast } from 'components/Toast/toast.context';
import { useTranslate } from 'hooks';

const ImageGallery: React.FC<IImageGallery> = ({
  maxItems,
  images,
  loading,
  ariaLabel,
  helpText,
  name,
  onAddImage,
  onDeleteImage
}) => {
  const [innerImages, setInnerImages] = useState<(File | string)[]>([]);
  const [isDeleteImage, setIsDeleteImage] = useState<number | null>(null);
  const { setFieldValue } = useFormikContext();
  const cancelRequest = useRef<boolean>(false);
  const { addToast } = useToast();
  const { translate } = useTranslate(['general-errors']);

  useEffect(() => {
    if (images) setInnerImages(images);
  }, [images]);

  const prepareImages = (files: File[]): IImagesGallery => {
    const imagePositionList = [...innerImages, ...files].reduce(
      (prev: IImagePostion[], cur: File | string, index: number) => {
        let fileName = '';
        //Get name of image when image is a url or a file
        if (typeof cur === 'string') {
          fileName = cur.split('/').pop() as string;
        } else {
          fileName = cur.name;
        }
        //Verify if image exist on array when its a url or a file
        const hasImage =
          innerImages.findIndex((item) =>
            typeof item === 'string'
              ? item.split('/').pop() === fileName
              : item.name === fileName
          ) >= 0;
        if (typeof cur !== `string` && !hasImage) {
          prev.push({ imageName: cur.name, position: index });
        }
        return prev;
      },
      []
    );
    return {
      files: files,
      positions: imagePositionList
    };
  };

  const onSelectFile = async (files: File[]) => {
    if (maxItems && files.length <= maxItems) {
      try {
        const images = prepareImages(files);
        const response = (await onAddImage(images)) as string[];
        const diff = Math.abs(innerImages.length - (maxItems ?? 0));
        const filesList = maxItems ? files.slice(0, diff) : files;
        if (!cancelRequest.current) {
          const getFiles = response ?? filesList;
          setInnerImages((prev) => [...prev, ...getFiles]);
          setFieldValue(
            name,
            maxItems === 1 ? images.files[0].name : images.files
          );
        }
      } catch (error) {}
    } else {
      addToast({
        label: translate('general-errors:images.max_itens'),
        appearance: 'danger'
      });
    }
  };

  useEffect(
    () => () => {
      cancelRequest.current = true;
    },
    []
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = [];
    const count = event.target?.files?.length ?? 0;
    for (let i = 0; i < count; i++) {
      files.push(event.target?.files?.item(i) as File);
    }
    void onSelectFile(files);
  };

  const onPressDelete = async (index: number) => {
    setIsDeleteImage(index);
    const imgUrl = innerImages?.filter(
      (_, indexFilter) => index === indexFilter
    )[0];
    const hasDeletedImage = await onDeleteImage?.(imgUrl);
    const updatedImages = innerImages?.filter(
      (_, indexFilter) => index !== indexFilter
    );
    if ((onDeleteImage && hasDeletedImage) || !onDeleteImage) {
      setInnerImages(updatedImages);
      setIsDeleteImage(null);
    }
    setFieldValue(name, maxItems === 1 ? null : updatedImages);
  };

  const IS_DISABLED = maxItems ? innerImages.length >= maxItems : false;

  return (
    <>
      <Box display="flex" flexWrap="wrap">
        {innerImages?.map((image, index) => {
          return (
            <Item
              key={index}
              image={image}
              isLoading={loading && index === isDeleteImage}
              disableDeleteButton={loading}
              onPressDelete={() => {
                void onPressDelete(index);
              }}
            />
          );
        })}

        {loading && isDeleteImage === null ? (
          <FileUploader.Skeleton
            aspectRatio="1/1"
            width="104px"
            height="104px"
          />
        ) : (
          <FileUploader
            id={name}
            name={name}
            role="file"
            width="104px"
            height="104px"
            aspectRatio="1/1"
            accept="image/jpeg,image/gif,image/png"
            onChange={onChange}
            disabled={IS_DISABLED}
            aria-label={ariaLabel ?? ''}
            multiple
          />
        )}
      </Box>

      {helpText && (
        <Box marginTop="2">
          <Text color="danger-interactive">{helpText}</Text>
        </Box>
      )}
    </>
  );
};

export default ImageGallery;
