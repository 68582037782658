import { ILedger } from '../payments.typings';
import { LedgersData } from './ledgersData.mock';

export class Ledgers implements ILedger {
  constructor(
    public count = 200,
    public entities = LedgersData.generateList(12)
  ) {}

  static generate(): ILedger {
    return new Ledgers();
  }

  static generateList(): ILedger {
    return this.generate();
  }
}
