import React from 'react';
import { Box, Title, Text } from '@nimbus-ds/components';
import { IBasePanel } from './basePanel.types';

const BasePanel = ({
  display = 'default',
  title,
  subtitle,
  children,
  className
}: IBasePanel): JSX.Element => {
  const limitedDisplay = display === 'default' ? '700px' : undefined;
  return (
    <Box>
      {Array.isArray(children) && children[0]}
      <Box
        padding={{
          xs: '4',
          md: '4',
          lg: '6',
          xl: '6'
        }}
      >
        <Box
          className={className}
          minWidth={{
            xs: '100%',
            md: '100%',
            lg: limitedDisplay,
            xl: limitedDisplay
          }}
          width={display === 'default' ? '70%' : '100%'}
          height="calc(100vh - 200px)"
        >
          {title && (
            <Box marginBottom="8">
              <Title>{title}</Title>
            </Box>
          )}
          {subtitle && (
            <Box marginTop="6">
              <Text>{subtitle}</Text>
            </Box>
          )}
          <Box height="100%" marginTop="6">
            {Array.isArray(children) ? children[1] : children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BasePanel;
