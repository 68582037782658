import React, { useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { Text, Title } from '@nimbus-ds/components';

import { IAccordion, IAccordionComponents } from './accordion.types';
import { Item, Header, Body } from './widgets';
import AccordionContext from './accordion.context';
import './accordion.styled.scss';

const Accordion: React.FC<IAccordion> & IAccordionComponents = ({
  children,
  selectedDefault,
  title,
  description,
  className,
  multi = false
}: IAccordion) => {
  const [selected, onSelect] = useState('');

  useEffect(() => {
    selectedDefault && onSelect(selectedDefault);
  }, [selectedDefault]);

  const contextValue = useMemo(
    () => ({
      multi,
      selected,
      onSelect
    }),
    [selected, multi, onSelect]
  );

  return (
    <div
      className={classNames({
        accordion__conntent: true,
        [className || '']: className && true
      })}
    >
      {title && (
        <div className="accordion__conntent-title">
          <Title as="h3">{title}</Title>
        </div>
      )}
      {description && (
        <div className="accordion__content-description">
          <Text>{description}</Text>
        </div>
      )}
      <div className="accordion__content-separator" />
      <AccordionContext.Provider value={contextValue}>
        {children}
      </AccordionContext.Provider>
    </div>
  );
};

Accordion.Item = Item;
Accordion.Header = Header;
Accordion.Body = Body;

export default Accordion;
