import React, { useCallback } from 'react';

import { Toast as ToastNimbus } from '@tiendanube/components';
import { IToast, TAddToast } from './toast.types';
import { ToastContext } from './toast.context';
import styles from './toast.module.scss';

function Toast({ children }: IToast): JSX.Element {
  const [toast, setToast] = React.useState<TAddToast | null>(null);

  const addToast = useCallback((newToast: TAddToast): void => {
    setToast(newToast);
  }, []);

  const closeToast = useCallback(() => {
    setToast(null);
  }, []);

  return (
    <ToastContext.Provider value={{ closeToast, addToast }}>
      {children}
      {toast && (
        <div className={styles.toast}>
          <ToastNimbus
            label={toast.label}
            appearance={toast.appearance}
            onClose={closeToast}
          />
        </div>
      )}
    </ToastContext.Provider>
  );
}

export default Toast;
