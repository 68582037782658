import { ENABLE_HOTJAR } from 'commons/constants';
import { hotjar as reactHotjar } from 'react-hotjar';
import { hotjarID } from './hotjar.definitions';

const hotjar = () => {
  if (ENABLE_HOTJAR) {
    reactHotjar.initialize(
      Number(
        hotjarID[window.location.hostname] || process.env.REACT_APP_HOTJAR_ID_MS
      ),
      Number(process.env.REACT_APP_HOTJAR_SV)
    );
  }
};

export default hotjar;
