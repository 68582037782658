import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { ApplicationsTypings } from 'types';
import { useFetchV2 } from 'hooks';

const useMetrics = (id?: string | number) => {
  const { request } = useFetchV2();

  const query = useQuery(
    ['metrics', id],
    () =>
      request<ApplicationsTypings.IMetrics>({
        url: `/apps/${id}/metrics/summary`,
        method: 'GET'
      }),
    {
      retry: false,
      enabled: !!id
    }
  );

  const IS_LOADING = useMemo(() => query.isLoading, [query.isLoading]);

  const { ACTIVES, UNINSTALLS, INSTALLATIONS_TOTAL } = useMemo(() => {
    const data = query.data?.content;
    return {
      ACTIVES: (query.isFetched && data?.active_installs) as number,
      UNINSTALLS: (query.isFetched && data?.uninstalls) as number,
      INSTALLATIONS_TOTAL: (query.isFetched && data?.total_installs) as number
    };
  }, [query.data?.content, query.isFetched]);

  return {
    ...query,
    IS_LOADING,
    ACTIVES,
    UNINSTALLS,
    INSTALLATIONS_TOTAL
  };
};

export default useMetrics;
