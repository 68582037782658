import { useEffect } from 'react';
import { IUseAmplitude } from './useAmplitude.types';
import { amplitudeTrackAction } from 'commons/utils';

const useAmplitude = (action?: string): IUseAmplitude => {
  useEffect(() => {
    if (action) {
      amplitudeTrackAction(action);
    }
  }, [action]);

  return { amplitudeAction: amplitudeTrackAction };
};

export default useAmplitude;
