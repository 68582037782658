import React from 'react';
import { Box, Tag } from '@nimbus-ds/components';
import { Card, Skeleton } from 'components';

const ApplicationTypesSkeleton: React.FC<{
  type?: 'card' | 'list';
  helpText?: string;
}> = ({ type, helpText }) => (
  <Card visibleBorder={type !== 'list'}>
    {!helpText && (
      <Card.Header>
        <Skeleton
          width="70%"
          height="30px"
          marginBottom="8px"
          borderRadius="4px"
        />
      </Card.Header>
    )}

    {helpText && (
      <Box paddingLeft="2" paddingRight="4">
        <Skeleton width="100%" borderRadius="4px" />
      </Box>
    )}
    <Card.Body>
      <Box
        borderWidthY="1"
        borderStyle="solid"
        borderColor="neutral-interactive"
        position="relative"
        paddingY="4"
        display="flex"
        width="100%"
      >
        <Box width="100%">
          <Skeleton
            width="50%"
            height="20px"
            marginBottom="8px"
            borderRadius="4px"
          />
          <Skeleton
            width="55%"
            height="20px"
            marginBottom="8px"
            borderRadius="4px"
          />
          <Tag.Skeleton />
        </Box>
        <Skeleton width="30px" height="30px" borderRadius="999px" />
      </Box>

      <Box position="relative" paddingY="4" display="flex" width="100%">
        <Box width="100%">
          <Skeleton
            width="50%"
            height="20px"
            marginBottom="8px"
            borderRadius="4px"
          />
          <Skeleton
            width="55%"
            height="20px"
            marginBottom="8px"
            borderRadius="4px"
          />
          <Tag.Skeleton />
        </Box>
        <Skeleton width="30px" height="30px" borderRadius="999px" />
      </Box>
    </Card.Body>
  </Card>
);

export default ApplicationTypesSkeleton;
