import React from 'react';
import { Button, Box, Card, Skeleton } from '@nimbus-ds/components';

const ApplicationsSkeleton: React.FC = () => {
  return (
    <Card>
      <Card.Header>
        <Skeleton width="210px" height="32px" />
      </Card.Header>
      <Card.Body>
        <Box>
          <Box display="flex" flexDirection="column" marginBottom="16">
            <Skeleton width="90px" height="32px" />
            <Box display="flex" flexDirection="column" marginTop="2" gap="2">
              <Skeleton width="100%" height="24px" />
            </Box>
          </Box>
          <Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box display="flex" flex="1">
                <Button.Skeleton width="120px" />
              </Box>

              <Box marginLeft="2" width="100%">
                <Skeleton width="140px" height="24px" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Card.Body>
    </Card>
  );
};

export default ApplicationsSkeleton;
