import React from 'react';
import classNames from 'classnames';

import { IBody } from './body.types';
import './body.styled.scss';

const Body: React.FC<IBody> = ({ children, className, testId }) => {
  const classnames = classNames({
    'modal--body': true,
    [className || '']: className && true
  });
  return (
    <div className={classnames} data-testid={testId}>
      {children}
    </div>
  );
};

export default Body;
