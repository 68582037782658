import { AppsIcon, StoreIcon, VolumeIcon } from '@nimbus-ds/icons';

export const partnershipPlanCards = [
  {
    icon: StoreIcon,
    title: 'partnership-plan:cards.create_store.title',
    subtitle: 'partnership-plan:cards.create_store.subtitle',
    textLink: 'partnership-plan:cards.create_store.link',
    link: 'links:create_store'
  },
  {
    icon: AppsIcon,
    title: 'partnership-plan:cards.create_application.title',
    subtitle: 'partnership-plan:cards.create_application.subtitle',
    textLink: 'partnership-plan:cards.create_application.link',
    link: 'links:create_application'
  },
  {
    icon: VolumeIcon,
    title: 'partnership-plan:cards.affiliate.title',
    subtitle: 'partnership-plan:cards.affiliate.subtitle',
    textLink: 'partnership-plan:cards.affiliate.link',
    link: 'links:affiliate'
  }
];
