import { AuthTypings } from 'types';

export const links = [
  {
    title: 'partner_program',
    links: {
      [AuthTypings.ECountryLanguage.BRAZIL]: [
        'partnership_types',
        'forms_of_commission',
        'commission_receipt',
        'onboarding_business_partners',
        'expert_highlights'
      ],
      [AuthTypings.ECountryLanguage.ARGENTINA]: [
        'partnership_types',
        'commission_receipt'
      ],
      [AuthTypings.ECountryLanguage.MEXICO]: [
        'partnership_types',
        'onboarding_business_partners'
      ]
    }
  },
  {
    title: 'first_steps',
    links: {
      [AuthTypings.ECountryLanguage.BRAZIL]: [
        'trainings',
        'articles_to_get_started'
      ],
      [AuthTypings.ECountryLanguage.ARGENTINA]: ['articles_to_get_started'],
      [AuthTypings.ECountryLanguage.MEXICO]: [
        'trainings',
        'articles_to_get_started'
      ]
    }
  },
  {
    title: 'new_store',
    links: {
      [AuthTypings.ECountryLanguage.BRAZIL]: [
        'create_stores_in_10_minutes',
        'nigrate_to_nuvemshop',
        'change_layout',
        'create_pages',
        'set_up_shop',
        'use_external_codes',
        'lgpd',
        'google_ads'
      ],
      [AuthTypings.ECountryLanguage.ARGENTINA]: [
        'create_stores_in_10_minutes',
        'nigrate_to_nuvemshop',
        'change_layout',
        'create_pages',
        'set_up_shop',
        'use_external_codes',
        'google_ads'
      ],
      [AuthTypings.ECountryLanguage.MEXICO]: [
        'nigrate_to_nuvemshop',
        'change_layout',
        'create_pages',
        'set_up_shop',
        'use_external_codes',
        'google_ads'
      ]
    }
  },
  {
    title: 'development_and_design',
    links: {
      [AuthTypings.ECountryLanguage.BRAZIL]: [
        'apis',
        'trainings',
        'layouts',
        'advanced_customization',
        'tutorials_videos',
        'base_template',
        'our_code'
      ],
      [AuthTypings.ECountryLanguage.ARGENTINA]: [
        'apis',
        'layouts',
        'advanced_customization',
        'base_template',
        'our_code'
      ],
      [AuthTypings.ECountryLanguage.MEXICO]: [
        'apis',
        'layouts',
        'advanced_customization',
        'base_template',
        'our_code'
      ]
    }
  },
  {
    title: 'applications',
    links: {
      [AuthTypings.ECountryLanguage.BRAZIL]: [
        'publication',
        'installation',
        'api_documentation'
      ],
      [AuthTypings.ECountryLanguage.ARGENTINA]: [
        'publication',
        'api_documentation'
      ],
      [AuthTypings.ECountryLanguage.MEXICO]: [
        'publication',
        'api_documentation'
      ]
    }
  },
  {
    title: 'sales_channels',
    links: {
      [AuthTypings.ECountryLanguage.BRAZIL]: [
        'dropshipping',
        'ig_shopping_and_facebook_store',
        'wholesale_sales',
        'sales_of_digital_products_or_services',
        'international_sales'
      ],
      [AuthTypings.ECountryLanguage.ARGENTINA]: [
        'ig_shopping_and_facebook_store',
        'sales_of_digital_products_or_services',
        'international_sales'
      ],
      [AuthTypings.ECountryLanguage.MEXICO]: [
        'ig_shopping_and_facebook_store',
        'wholesale_sales',
        'sales_of_digital_products_or_services',
        'international_sales'
      ]
    }
  },
  {
    title: 'about_nuvemshop',
    links: {
      [AuthTypings.ECountryLanguage.BRAZIL]: [
        'institutional_presentation',
        'platform_commercial_presentation',
        'store_admin_demo'
      ],
      [AuthTypings.ECountryLanguage.ARGENTINA]: ['institutional_presentation'],
      [AuthTypings.ECountryLanguage.MEXICO]: ['institutional_presentation']
    }
  }
];
