import { InterfaceSelectOption } from '@tiendanube/components';
import { CountriesTypings } from 'types';

export const transformCountries = (
  countries: CountriesTypings.ICountry[]
): InterfaceSelectOption[] => {
  return countries.map((country) => ({
    label: country.namePt,
    value: `${country.id}`
  }));
};
