import { Box, Table } from '@nimbus-ds/components';
import { IHeader } from 'components/Table/tables.types';
import React from 'react';

function Header<T>({ columns }: IHeader<T>): JSX.Element {
  return (
    <Table.Head>
      <Table.Row backgroundColor="neutral-surface">
        {columns.getColumns().map((column, i) => (
          <Table.Cell as="th" key={`${column.label}-${i}`}>
            <Box padding="2" {...column.options?.boxWrapperColumn}>
              {column.label}
            </Box>
          </Table.Cell>
        ))}
      </Table.Row>
    </Table.Head>
  );
}
export default Header;
