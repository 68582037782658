import React from 'react';
import { Button } from '@tiendanube/components';

import { ROUTES } from 'commons/constants';
import { CommonPage } from 'components';
import { useTranslate } from 'hooks';

const GeneralErrors: React.FC = () => {
  const { translate } = useTranslate(['general-errors', 'links']);
  return (
    <CommonPage
      title={translate('general-errors:titles.ops')}
      subtitle={translate('general-errors:subtitles.not_found')}
      legend={translate('general-errors:subtitles.error_500')}
    >
      <Button
        appearance="default"
        onClick={() => {
          window.location.href = translate('links:feedback');
        }}
      >
        {`${translate('general-errors:links.to_report')}`}
      </Button>
      <Button
        appearance="primary"
        onClick={() => {
          window.location.href = ROUTES.DEFAULT;
        }}
      >
        {`${translate('general-errors:links.initial')}`}
      </Button>
    </CommonPage>
  );
};

export default GeneralErrors;
