export const percentage = (
  value?: string | number | null,
  fixedFractionDigits = 2,
  returnNoneAs = '0%'
): string => {
  if (!value || value === null) value = 0;
  if (typeof value === 'string') value = Number(value);
  const zeros = new Array(fixedFractionDigits).fill('0').join('');
  const str = value?.toFixed(2)?.replace('.', ',')?.replace(`,${zeros}`, '');
  if (str === '0') return returnNoneAs;
  return `${str}%`;
};
