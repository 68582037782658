import React, { useMemo } from 'react';
import { Box, Text } from '@nimbus-ds/components';
import Skeleton from 'components/Skeleton';
import { useTranslate } from 'hooks';

const Footer: React.FC<{
  total: number;
  totalPerPage: number;
  isLoading?: boolean;
  text?: string;
}> = ({ children, total, totalPerPage, isLoading, text }) => {
  const IS_LOADING = useMemo(() => isLoading, [isLoading]);
  const { translate } = useTranslate('common');

  return (
    <Box
      key="footer"
      maxWidth="100%"
      overflowX="auto"
      display="flex"
      flexDirection={{
        xs: 'column',
        md: 'row'
      }}
      alignItems="center"
      justifyContent={{
        xs: 'center',
        lg: 'space-between'
      }}
      paddingY="4"
      paddingX="1"
    >
      {IS_LOADING && <Skeleton width="100px" height="24px" />}
      {IS_LOADING && <Skeleton width="200px" height="24px" />}
      {!IS_LOADING && (
        <>
          <Box display={{ xs: 'none', lg: 'block' }}>
            <Text>
              {text
                ? text
                : `${translate('common:table.footer', {
                    totalPerPage,
                    total
                  })}`}
            </Text>
          </Box>
          <Box>{children}</Box>
        </>
      )}
    </Box>
  );
};
export default Footer;
