export const pluralize = (
  value: string | number | null | undefined,
  singular: string,
  plural: string
) => {
  let counter = value;
  if (!value || value === null) counter = 0;
  if (typeof value === 'string') counter = Number(value);
  if (counter === 1) return singular;
  return plural;
};
