import React, { useState, useContext } from 'react';
import { Box, Button, Modal, Text } from '@nimbus-ds/components';

import { useTranslate } from 'hooks';
import {
  InterfaceModalConfirmationContext,
  ModalConfirmationInterface,
  modalStateInterface
} from './modalConfirmation.types';

export const ModalConfirmationContext =
  React.createContext<InterfaceModalConfirmationContext>(
    {} as unknown as InterfaceModalConfirmationContext
  );

export const useModalConfirmation: () => InterfaceModalConfirmationContext =
  () => useContext(ModalConfirmationContext);

function ModalConfirmation({
  children
}: ModalConfirmationInterface): JSX.Element {
  const { translate } = useTranslate('common');

  const [modalState, setModalState] = useState<modalStateInterface>({
    show: false,
    message: ''
  });

  const userConfirmation = (
    message: string,
    callback: (status: boolean) => void
  ) => {
    setModalState({
      show: true,
      message,
      callback
    });
  };

  const handlePrimaryButton = () => {
    modalState.callback && modalState.callback(true);
    setModalState({
      show: false,
      message: ''
    });
  };

  const handleDismiss = () => {
    modalState.callback && modalState.callback(false);
    setModalState({
      show: false,
      message: ''
    });
  };

  return (
    <ModalConfirmationContext.Provider value={{ userConfirmation }}>
      {children}
      <Modal
        data-testid="modal-confirmation"
        open={modalState.show}
        onDismiss={handleDismiss}
      >
        <Modal.Header title={translate('modal.exit_edit.title')} />
        <Modal.Body>
          <Box borderColor="neutral-interactive" paddingY="2">
            <Text fontSize="base" textAlign="left">
              {modalState.message}
            </Text>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDismiss}>
            {translate('modal.exit_edit.continue')}
          </Button>
          <Button onClick={handlePrimaryButton} appearance="danger">
            {translate('modal.exit_edit.confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </ModalConfirmationContext.Provider>
  );
}

export default ModalConfirmation;
