import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ROUTES } from 'commons/constants';

const ApplicationsList = React.lazy(() => import('./pages/Listing'));
const ApplicationsActions = React.lazy(() => import('./pages/Actions'));
const ApplicationsDetails = React.lazy(() => import('./pages/Details'));
const ApplicationsAuthentication = React.lazy(
  () => import('./pages/AuthenticationLanding')
);
const ApplicationsStatements = React.lazy(() => import('./pages/Statements'));
const ApplicationsStatementsHomolog = React.lazy(
  () => import('./pages/StatementsHomolog')
);
const Installations = React.lazy(() => import('./pages/Installations'));
const Uninstalls = React.lazy(() => import('./pages/Uninstalls'));
const ScriptDetails = React.lazy(() => import('./pages/ScriptDetails'));

const Routes: React.FC = () => (
  <Switch>
    <Route path={ROUTES.APPLICATIONS.DEFAULT} exact>
      <ApplicationsList />
    </Route>
    <Route path={ROUTES.APPLICATIONS.UPDATE} exact>
      <ApplicationsActions />
    </Route>
    <Route path={ROUTES.APPLICATIONS.DETAILS} exact>
      <ApplicationsDetails />
    </Route>
    <Route path={ROUTES.APPLICATIONS.AUTHENTICATION} exact>
      <ApplicationsAuthentication />
    </Route>
    <Route path={ROUTES.APPLICATIONS.STATEMENTS.DEFAULT} exact>
      <ApplicationsStatements />
    </Route>
    <Route path={ROUTES.APPLICATIONS.STATEMENTS.HOMOLOG} exact sensitive>
      <ApplicationsStatementsHomolog />
    </Route>
    <Route path={ROUTES.APPLICATIONS.INSTALLATIONS} exact sensitive>
      <Installations />
    </Route>
    <Route path={ROUTES.APPLICATIONS.UNINSTALLS} exact sensitive>
      <Uninstalls />
    </Route>
    <Route path={ROUTES.APPLICATIONS.SCRIPT_DETAILS} exact sensitive>
      <ScriptDetails />
    </Route>
    <Redirect from="*" to={ROUTES.NOT_FOUND} />
  </Switch>
);

export default Routes;
