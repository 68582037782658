export enum EInstalledStoreChargeInformation {
  FREE_APP = 'FREE_APP',
  FREE_PERIOD = 'FREE_PERIOD',
  PENDING_PAYMENT = 'PENDING_PAYMENT'
}

export enum EInstalledStoreStatusApp {
  ACTIVE = 'ACTIVE',
  IN_TEST = 'IN_TEST',
  CANCELED = 'CANCELED'
}
