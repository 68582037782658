import React, { useCallback, useMemo } from 'react';
import { Box, Text } from '@nimbus-ds/components';
import { IRows } from 'components/Table/tables.types';
import { getKey } from 'components/Table/table.definitions';
import Skeleton from 'components/Skeleton';
function RowsMobile<T>({
  rows: lines,
  columns,
  isLoading,
  perPage,
  boxWrapperRows,
  boxWrapperInnerRows,
  fillWhiteColumns
}: IRows<T>): JSX.Element {
  const rowRender = useCallback(
    (
      field: T,
      row: T,
      formatter?: (field: any, row: any) => string | JSX.Element,
      fillWhiteColumns = false
    ) => {
      if (!field) {
        return fillWhiteColumns ? <Text>-</Text> : <React.Fragment />;
      }
      return formatter && field ? formatter(field, row) : <Text>{field}</Text>;
    },
    []
  );
  const rows = useMemo(
    () =>
      (isLoading
        ? Array.from({ length: perPage })
        : [
            ...lines,
            ...Array.from({ length: perPage - lines.length }).map(() => ({}))
          ]) as T[],
    [lines, perPage, isLoading]
  );

  return (
    <Box
      borderRadius="2"
      borderWidth="1"
      borderStyle="solid"
      borderColor="neutral-interactive"
      overflow="hidden"
      data-testid="mobile-table-row"
    >
      {rows?.map(
        (row, index) =>
          JSON.stringify(row) !== '{}' && (
            <Box
              key={`mobile-${getKey(index)}`}
              borderBottomWidth="1"
              borderStyle="solid"
              borderColor="neutral-interactive"
              backgroundColor="neutral-background"
              width="100%"
              {...boxWrapperRows}
            >
              <Box
                id={`mobile-${getKey(index)}`}
                display="flex"
                flexWrap="wrap"
                width="100%"
                {...boxWrapperInnerRows}
              >
                {isLoading && (
                  <Box display="flex" width="100%" padding="4">
                    <Skeleton height="26px" width="100%" borderRadius="4px" />
                  </Box>
                )}
                {!isLoading &&
                  columns.getColumns().map((column, i) => {
                    if (column.options?.showNameColumnMobile === false)
                      return (
                        <React.Fragment key={`${column.field as string}${i}`} />
                      );
                    return (
                      <Box
                        key={`${column.field as string}${i}`}
                        padding="4"
                        data-testid={row[column?.field]}
                        {...column.options?.boxWrapperColumn}
                      >
                        {column.options?.showNameColumnMobile && (
                          <Text>{column.label}</Text>
                        )}

                        {rowRender(
                          row[column.field] as unknown as T,
                          row,
                          column.options?.formatter,
                          fillWhiteColumns
                        )}
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          )
      )}
    </Box>
  );
}

export default RowsMobile;
