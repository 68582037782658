import { REGEX } from 'commons/constants';
import { string, addMethod, StringSchema } from 'yup';

addMethod<StringSchema>(string, 'phoneBR', function (errorMessage) {
  return this.test('phoneBR', errorMessage, function (value) {
    if (value === '' || !value) return true;
    if (!REGEX.PHONE_BR.test(value)) {
      return this.createError({ path: this.path, message: errorMessage });
    }
    return true;
  });
});
