import { createContext, useContext } from 'react';

import { IAccordionContext } from './accordion.types';

export const context = createContext<IAccordionContext>(
  {} as IAccordionContext
);
context.displayName = 'AccordionContext';

export const useAccordion: () => IAccordionContext = () => useContext(context);
export default context;
