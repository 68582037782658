import React from 'react';
import { Skeleton as SkeletonNimbus } from '@nimbus-ds/components';

import { TSkeletonsProps } from './skeleton.types';

const Skeleton: React.FC<TSkeletonsProps> = (prop) => (
  <div
    className="skeleton"
    style={{
      width: prop.width,
      height: prop.height,
      marginTop: prop.marginTop,
      marginRight: prop.marginRight,
      marginLeft: prop.marginLeft,
      marginBottom: prop.marginBottom
    }}
  >
    <SkeletonNimbus
      data-testid={prop.testId}
      width={prop.width ? `${prop.width}` : '0'}
      height={prop.height ? `${prop.height}` : '0'}
      borderRadius={prop.borderRadius ? `${prop.borderRadius}` : undefined}
    />
  </div>
);

export default Skeleton;
