export const categoriesAdmin = [
  {
    group: 'order',
    options: [
      {
        label: 'order_list',
        value: 'orders'
      },
      {
        label: 'order_details',
        value: 'orders_single'
      }
    ]
  },
  {
    group: 'product',
    options: [
      {
        label: 'product_list',
        value: 'products'
      },
      {
        label: 'product_details',
        value: 'products_single'
      }
    ]
  },
  {
    group: 'categories',
    options: [
      {
        label: 'categories_list',
        value: 'categories'
      },
      {
        label: 'categories_details',
        value: 'categories_single'
      }
    ]
  },
  {
    group: 'pages',
    options: [
      {
        label: 'page_list',
        value: 'pages'
      },
      {
        label: 'page_details',
        value: 'pages_single'
      }
    ]
  }
];

export const categoriesBulkAction = [
  {
    group: 'order',
    options: [
      {
        label: 'order_list',
        value: 'orders'
      }
    ]
  },
  {
    group: 'product',
    options: [
      {
        label: 'product_list',
        value: 'products'
      }
    ]
  }
];
