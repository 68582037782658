import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ROUTES } from 'commons/constants';
import { AuthRoutes } from 'domains';
import { Loading } from 'components';

const NotFound = React.lazy(() => import('domains/NotFound/NotFound'));

const PublicRoutes: React.FC = () => (
  <Suspense fallback={<Loading size="medium" />}>
    <Switch>
      <Route path={ROUTES.NOT_FOUND} exact>
        <NotFound />
      </Route>
      <Route path={ROUTES.AUTH.DEFAULT}>
        <AuthRoutes />
      </Route>
      <Redirect from="*" to={ROUTES.AUTH.DEFAULT} />
    </Switch>
  </Suspense>
);

export default PublicRoutes;
