import React, { useState } from 'react';
import {
  Box,
  Text,
  Title,
  Button,
  Card,
  Alert,
  Icon
} from '@nimbus-ds/components';
import { ProductUpdates } from '@nimbus-ds/patterns';
import { useTranslate } from 'hooks';
import { CodeIcon } from '@nimbus-ds/icons';
import { ROUTES } from '../../../../../../commons/constants';
import { useHistory } from 'react-router-dom';
import useCreateScriptModal from '../../../../hooks/useCreateScriptModal';

const ScriptEmptyCard: React.FC<{ hasScriptScope: boolean; id: number }> = ({
  hasScriptScope,
  id
}) => {
  const { translate } = useTranslate(['application-empty-card']);
  const history = useHistory();
  const { context } = useCreateScriptModal();
  const [openTutorial, setOpenTutorial] = useState(true);
  return (
    <Card>
      <Card.Body>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Title as="h3">{`${translate('title.script')}`}</Title>
            <ProductUpdates
              position="right-end"
              enabledClick={true}
              onClick={() => setOpenTutorial(false)}
              dismissLink={translate('buttons.dismiss')}
              maxWidth="300px"
              title={translate('descriptions.subtitle')}
              text={translate('descriptions.paragraph')}
              visible={openTutorial}
            >
              {hasScriptScope && (
                <Button
                  appearance="primary"
                  onClick={() => context?.setOpen?.(true)}
                >
                  {`${translate('buttons.create_script')}`}
                </Button>
              )}
              {!hasScriptScope && (
                <Button appearance="neutral" disabled={true}>
                  {`${translate('buttons.create_script')}`}
                </Button>
              )}
            </ProductUpdates>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            paddingTop="5"
          >
            <Box>
              <Icon source={<CodeIcon size="large" color="black" />} />
            </Box>
            <Box
              marginTop="4"
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <Text
                fontWeight="medium"
                fontSize="highlight"
                color="neutral-textHigh"
              >{`${translate('cards.add_script')}`}</Text>
              <Box
                marginBottom="4"
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                paddingTop="0-5"
              >
                <Text textAlign="center">
                  {translate('cards.manage_script')}
                </Text>
                <Text textAlign="center">
                  {translate('cards.manage_script2')}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        {!hasScriptScope && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            justifyContent="space-around"
            paddingRight="5"
            paddingLeft="5"
            paddingBottom="2"
          >
            <Alert
              appearance="primary"
              title={translate('manual.required_permission')}
            >
              <Text>{translate('manual.description')}</Text>
              <Button
                appearance="neutral"
                onClick={() =>
                  history.push(
                    ROUTES.APPLICATIONS.UPDATE.replace(':id', id.toString())
                  )
                }
              >
                {translate('buttons.activate_permission')}
              </Button>
            </Alert>
          </Box>
        )}
      </Card.Body>
    </Card>
  );
};

export default ScriptEmptyCard;
