import React from 'react';
import { Button, Box, Card, Skeleton } from '@nimbus-ds/components';

const MarketingLinkSkeleton: React.FC = () => {
  return (
    <Card>
      <Card.Header>
        <Box display="flex" flexDirection="row">
          <Skeleton width="160px" height="32px" />
        </Box>
      </Card.Header>
      <Card.Body>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          marginY="6"
          gap="2"
        >
          <Skeleton width="100%" height="24px" />
          <Skeleton width="100%" height="24px" />
        </Box>
      </Card.Body>
      <Card.Footer>
        <Box display="flex" alignItems="center">
          <Box marginRight="2">
            <Button.Skeleton width="100px" />
          </Box>
          <Skeleton width="100px" height="24px" />
        </Box>
      </Card.Footer>
    </Card>
  );
};

export default MarketingLinkSkeleton;
