import React from 'react';
import { Box, Card, Button } from '@nimbus-ds/components';

import { Skeleton } from 'components';

const Partnership: React.FC = () => {
  return (
    <Card>
      <Card.Header>
        <Skeleton width="120px" height="32px" />
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="row" alignItems="center" marginY="6">
          <Box borderRadius="full" marginRight="6">
            <Skeleton width="40px" height="40px" borderRadius="32px" />
          </Box>
          <Box display="flex" flexDirection="column" gap="2" width="100%">
            <Skeleton width="100%" height="24px" />
            <Skeleton width="100%" height="24px" />
          </Box>
        </Box>
      </Card.Body>
      <Card.Footer>
        <Button.Skeleton width="180px" />
      </Card.Footer>
    </Card>
  );
};

export default Partnership;
