import { useEffect, useCallback, useRef } from 'react';
import { useImmerReducer, Reducer } from 'use-immer';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { useToast } from 'components/Toast/toast.context';
import axios from 'commons/utils/axios/axiosApiInstance';
import { useTranslate } from 'hooks';

import {
  TAction,
  IState,
  EAction,
  TUseFetch,
  IUseFetchProps,
  IApiResponse
} from './useFetch.types';

const useFetch = <R = unknown>(
  url?: string,
  props?: IUseFetchProps<R>
): TUseFetch<R> => {
  const cancelRequest = useRef<boolean>(false);
  const { addToast } = useToast();
  const { translate } = useTranslate('api');

  const initialState: IState<R> = {
    loading: false
  };

  const reducer = (draft: IState<R>, action: TAction<R>) => {
    switch (action.type) {
      case EAction.LOADING:
        draft.loading = action.payload;
        break;
      case EAction.FETCHED:
        draft.data = action.payload;
        break;
      case EAction.ERROR:
        draft.error = action.payload;
        break;
    }
  };

  const [state, dispatch] = useImmerReducer(
    reducer as Reducer<IState<R>, TAction<R>>,
    initialState
  );

  const showToast = useCallback(
    (
      label: string,
      appearance?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger'
    ) => {
      addToast({
        label,
        appearance
      });
    },
    [addToast]
  );

  const execute = useCallback(
    async (config?: AxiosRequestConfig) => {
      try {
        dispatch({ type: EAction.LOADING, payload: true });
        const getUrl = url ?? (config?.url as string);
        const response = (await axios(getUrl, config)) as AxiosResponse<
          IApiResponse<R>
        >;

        if (!cancelRequest.current) {
          const content = props?.transform
            ? props?.transform(response.data?.content)
            : response.data?.content;

          dispatch({ type: EAction.FETCHED, payload: content });

          const message =
            props?.messages?.messageSuccess || response?.data?.message;

          if (message && (!props?.notToast || props.messages?.messageSuccess)) {
            showToast(translate(message), 'success');
          }

          return content;
        }
      } catch (err: any) {
        const error =
          props?.messages?.messageError ||
          err?.response?.data?.message ||
          'generic_error';

        if (!cancelRequest.current) {
          dispatch({ type: EAction.ERROR, payload: error });
          if (!props?.notToast || props.messages?.messageError) {
            showToast(translate(error), 'danger');
          }
        }
        if (props?.fallbackError?.execute) {
          props?.fallbackError?.callback?.(err);
          throw new Error(translate(error) as string);
        }
      } finally {
        if (!cancelRequest.current) {
          dispatch({ type: EAction.LOADING, payload: false });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      url,
      props?.fallbackError?.execute,
      props?.notToast,
      showToast,
      translate,
      dispatch
    ]
  );

  useEffect(() => {
    if (props?.immediate) {
      execute(props?.config).catch(() => null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.immediate, execute]);

  useEffect(
    () => () => {
      cancelRequest.current = true;
    },
    []
  );

  return [execute, state];
};

export default useFetch;
