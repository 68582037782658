import React, { createContext, useContext, useState } from 'react';
import {
  IAppLayoutBackButton,
  IAppLayoutContext,
  IAppLayoutItem
} from './appLayout.types';

const AppLayoutContext = createContext<IAppLayoutContext>({});

export const useAppLayoutContext = () => useContext(AppLayoutContext);

const AppLayoutProvider: React.FC = ({ children }) => {
  const [backButton, setBackButton] = useState<
    IAppLayoutBackButton | undefined
  >();
  const [items, setItems] = useState<IAppLayoutItem[] | undefined>();
  const [actions, setActions] = useState<IAppLayoutItem[] | undefined>();

  return (
    <AppLayoutContext.Provider
      value={{
        backButton,
        setBackButton,
        items,
        setItems,
        actions,
        setActions
      }}
    >
      {children}
    </AppLayoutContext.Provider>
  );
};

export default AppLayoutProvider;
