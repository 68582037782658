import {
  rand,
  randCompanyName,
  randFloat,
  randNumber,
  randRecentDate,
  randUuid
} from '@ngneat/falso';
import { ELedgerStatus, ILedgerData } from '../payments.typings';

export class LedgersData implements ILedgerData {
  constructor(
    public id = randUuid(),
    public paidAt = randRecentDate(),
    public creditName = randCompanyName(),
    public creditValue = randFloat({
      min: 0,
      max: 1000,
      fraction: 2
    }),
    public status = rand(Object.values(ELedgerStatus)),
    public ledgerEntryTypeId = rand([1, 2, 3, 4, 5, 6, 7, 8]),
    public paymentId = randNumber(),
    public createdAt = randRecentDate(),
    public storeName = randCompanyName()
  ) {}

  static generate(): ILedgerData {
    return new LedgersData();
  }

  static generateList(total: number): ILedgerData[] {
    const data = Array.from({ length: total });
    return data.map(() => ({
      ...this.generate(),
      total: total * 3
    }));
  }
}
