import { useState, useMemo, useCallback } from 'react';
import { IUsePagination } from './usePagination.types';
import { NumberParam, useQueryParams } from 'use-query-params';

const usePagination = (perPage: number, totalItems = 0): IUsePagination => {
  const [param, setParam] = useQueryParams({
    page: NumberParam
  });

  const [page, setPage] = useState<number>(param.page ?? 1);

  const getTotalPages = useCallback(
    (totalItems) => {
      const total = Math.ceil(totalItems / perPage);
      return total > 0 ? total : 1;
    },
    [perPage]
  );

  const onChangePage = useCallback(
    (value: number) => {
      setPage(value);
      setParam({ page: value });
    },
    [setPage, setParam]
  );

  return {
    skip: useMemo(() => page * perPage, [page, perPage]),
    totalPages: useMemo(
      () => getTotalPages(totalItems),
      [totalItems, getTotalPages]
    ),
    page,
    onChangePage,
    getTotalPages,
    setPage
  };
};

export default usePagination;
