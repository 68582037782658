import { Box, Thumbnail, IconButton } from '@nimbus-ds/components';
import { TrashIcon } from '@nimbus-ds/icons';

import React from 'react';
import { IImage } from './image.types';

const Image: React.FC<IImage> = ({
  image,
  onPressDelete,
  isLoading,
  disableDeleteButton
}) => {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginRight="2"
    >
      {isLoading && <Thumbnail.Skeleton width="104px" />}
      {!isLoading && (
        <>
          <Thumbnail
            width="104px"
            height="104px"
            alt="image"
            src={typeof image === 'string' ? image : URL.createObjectURL(image)}
          />
          <Box
            display="flex"
            position="absolute"
            marginTop="1"
            marginRight="1"
            top="0"
            right="0"
          >
            <IconButton
              role="delete"
              disabled={disableDeleteButton}
              size="2.2rem"
              source={<TrashIcon size="small" />}
              onClick={onPressDelete}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Image;
