import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { useFetchV2, useAuth } from 'hooks';
import { AccountTypings } from 'types';
import { EXPIRATION_TIME_6H } from 'commons/constants';

import { IBankAccount } from './useBankAccount.types';

const useBankAccount = <T = AccountTypings.IRevenueAccountFields>() => {
  const queryClient = useQueryClient();
  const { auth } = useAuth();
  const { request } = useFetchV2();

  const query = useQuery(
    'bank-account',
    () =>
      request<IBankAccount<T>>({
        url: `/partners/${auth.partnerId}/bank-account`,
        method: 'GET'
      }),
    { retry: false, staleTime: EXPIRATION_TIME_6H }
  );

  const invalidateCache = useCallback(
    () => queryClient.invalidateQueries('bank-account'),
    [queryClient]
  );

  return { ...query, invalidateCache };
};

export default useBankAccount;
