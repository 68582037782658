import { AxiosRequestConfig } from 'axios';

export interface IState<R> {
  loading: boolean;
  data?: R;
  error?: string;
}

export enum EAction {
  INITIAL_STATE = 'initalState',
  LOADING = 'loading',
  FETCHED = 'fetched',
  ERROR = 'error'
}

export type TAction<R> =
  | { type: EAction.INITIAL_STATE }
  | { type: EAction.LOADING; payload: boolean }
  | { type: EAction.FETCHED; payload: R }
  | { type: EAction.ERROR; payload: string };

type TExecute<R> = (config: AxiosRequestConfig) => Promise<R | undefined>;

export type TUseFetch<R> = [TExecute<R>, IState<R>];

export interface IError {
  request: {
    status: number;
  };
}
export interface IFallbackError {
  execute?: boolean;
  callback?: (status: IError) => void;
}

export interface IUseFetchProps<R> {
  config?: AxiosRequestConfig;
  messages?: { messageSuccess?: string; messageError?: string };
  notToast?: boolean;
  fallbackError?: IFallbackError;
  immediate?: boolean;
  transform?: (content: any) => R;
}

export interface IApiResponse<T> {
  content: T;
  message: string;
  statusCode: number;
}
