import React from 'react';
import { Button } from '@tiendanube/components';
import { Modal } from 'components';
import { useTranslate } from 'hooks';
import style from './sealModal.module.scss';
import { ISealModal } from './seal.types';

const SealModal: React.FC<ISealModal> = ({ seal, show, onDismiss }) => {
  const { translate } = useTranslate(['home', 'links']);

  return (
    <Modal className={style.div_modal} show={show} onDismiss={onDismiss}>
      <div className={style.div_modal_content}>
        <Modal.Header
          className={style.modal_title}
          title={translate('home:cards.seal.title')}
        ></Modal.Header>
        <Modal.Body className={style.div_modal_body}>
          {seal?.map((image, i) => (
            <div key={`image${i}`} className={style.image}>
              <img src={image} alt="Seal of Nube Expert" />
              <Button
                appearance="default"
                onClick={() => {
                  window.open(image, '_blank', 'noopener,noreferrer');
                }}
              >
                {`${translate('home:cards.seal.button_download_seal')}`}
              </Button>
            </div>
          ))}
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default SealModal;
