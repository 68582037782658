import { AbstractTypings, AppsScriptsTypings } from 'types';
import { useMutation, useQuery } from 'react-query';
import { useFetchV2, useToast, useTranslate } from 'hooks';
import { IUsePagination } from 'hooks/usePaginationV2/usePagination.types';
import { PER_PAGE } from 'domains/Applications/pages/Details/widgets/ScriptListCard';
import { IApiResponse } from 'hooks/useFetchV2/useFetch.types';

const useEditScript = (
  appId: number | string,
  scriptId: number | string,
  pagination?: IUsePagination
) => {
  const { request } = useFetchV2();
  const { addToast } = useToast();
  const { translate } = useTranslate(['applications-listing', 'api']);

  const query = useQuery<any>(
    ['script-details', appId, scriptId],
    () =>
      request<AbstractTypings.PageResponse<AppsScriptsTypings.AppScript>>({
        url: `apps/${appId}/scripts/${scriptId}?countVersion=true`
      }),
    {
      retry: false
    }
  );

  const genericError = async () => {
    addToast({
      appearance: 'danger',
      label: translate('api:generic_error')
    });
  };

  const getVersions = useMutation(
    (scriptId: number | string) =>
      request<
        AbstractTypings.PageResponse<AppsScriptsTypings.AppScriptVersion>
      >({
        url: `apps/${appId}/scripts/${scriptId}/versions?page=${
          (pagination?.page ?? 1) - 1
        }&per_page=${PER_PAGE}`,
        method: 'GET'
      }),
    {
      onError: genericError
    }
  );

  const scriptUpload = useMutation(
    (args: { scriptID: number; data: FormData }) =>
      request({
        url: `apps/${appId}/scripts/${args.scriptID}/versions`,
        method: 'POST',
        data: args.data
      }),
    {
      onError: (error: IApiResponse<any>) =>
        addToast?.({
          label:
            error.statusCode === 400
              ? translate(
                  `application-create-script-modal:api.${error.message}`
                )
              : translate(`application-create-script-modal:api.generic_error`),
          appearance: 'danger'
        }),
      onSuccess: () => {
        addToast?.({
          label: translate('application-create-script-modal:script-created'),
          appearance: 'success'
        });
      }
    }
  );

  return {
    script: query.data?.content ?? { entities: [], count: 0 },
    scriptLoading: query.isLoading,
    getVersions,
    versions: getVersions.data?.content ?? { entities: [], count: 0 },
    scriptUpload
  };
};

export default useEditScript;
