export enum EScripTLocation {
  STOREFRONT = 'storefront',
  CHECKOUT = 'checkout'
}

export enum EScriptStatus {
  ACTIVE_STORE_DEMO = 'active_store_demo'
}

export interface AppScript {
  name: string;
  id: number;
  // eslint-disable-next-line babel/camelcase
  app_id: number;
  location: EScripTLocation;
  event: string;
  status: EScriptStatus;
  // eslint-disable-next-line babel/camelcase
  version_count: number;
  // eslint-disable-next-line babel/camelcase
  is_auto_install: boolean;
  // eslint-disable-next-line babel/camelcase
  draft_version_id: number | null;
  // eslint-disable-next-line babel/camelcase
  current_version_id: number | null;
  // eslint-disable-next-line babel/camelcase
  created_at: Date;
  // eslint-disable-next-line babel/camelcase
  updated_at: Date;
  // eslint-disable-next-line babel/camelcase
  allowed_actions: string[];
}

export interface AppScriptVersion {
  id: number;
  scriptId: number;
  src: string;
  version: number;
  status?: string;
  createdAt: Date;
  // eslint-disable-next-line babel/camelcase
  allowed_actions: string[];
}
