import React, { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import * as Sentry from '@sentry/react';
import CacheBuster from 'react-cache-buster';
import '@nimbus-ds/styles/dist/index.css';
import '@nimbus-ds/styles/dist/themes/dark.css';

import ModalConfirmationProvider from 'components/ModalConfirmation';
import ToastContextProvider from 'components/Toast/Toast';
import ToastProgressProvider from 'components/Toast/ToastProgress';
import { IS_DEVELOPMENT, IS_PRODUCTION, IS_STAGING } from 'commons/constants';
import { Loading, AppLayoutProvider, DarkModeProvider } from 'components';
import { GeneralErrors } from 'domains';

import pkg from '../../package.json';

import Router from './Router/Router';
import ReduxProvider from './ReduxProvider';
import './i18n';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } }
});

const App: React.FC = () => {
  return (
    <CacheBuster
      currentVersion={pkg?.version}
      isEnabled={IS_STAGING || IS_PRODUCTION}
      isVerboseMode={false}
      loadingComponent={<Loading />}
    >
      <Suspense fallback={<Loading size="medium" />}>
        <CookiesProvider>
          <QueryClientProvider client={queryClient}>
            <ReduxProvider>
              <Sentry.ErrorBoundary fallback={<GeneralErrors />}>
                <DarkModeProvider>
                  <ModalConfirmationProvider>
                    <ToastContextProvider>
                      <ToastProgressProvider>
                        <AppLayoutProvider>
                          <Router />
                        </AppLayoutProvider>
                      </ToastProgressProvider>
                    </ToastContextProvider>
                  </ModalConfirmationProvider>
                </DarkModeProvider>
              </Sentry.ErrorBoundary>
            </ReduxProvider>
            {IS_DEVELOPMENT && <ReactQueryDevtools position="bottom-right" />}
          </QueryClientProvider>
        </CookiesProvider>
      </Suspense>
    </CacheBuster>
  );
};

export default App;
