import { useCallback, useMemo } from 'react';
import { useTranslation as usei18n } from 'react-i18next';

import { ELocale } from 'app/i18n/i18n.types';
import { AuthTypings } from 'types';
import { IUseTranslate } from './useTranslate.types';

const useTranslate = (key: string | string[]): IUseTranslate => {
  const { t: translate, i18n } = usei18n(key);

  const setLanguage = useCallback(
    (lang: AuthTypings.ECountryLanguage) => {
      i18n.changeLanguage(lang.replace('_', '-'));
    },
    [i18n]
  );

  const languageCode = useMemo(
    () => i18n.language.split('-')?.[1] ?? '',
    [i18n.language]
  );

  return {
    translate,
    setLanguage,
    language: i18n.language as ELocale,
    languageCode: languageCode.toUpperCase()
  };
};

export default useTranslate;
