import {
  Modal,
  Box,
  Text,
  Link,
  Button,
  Icon,
  IconButton,
  Alert,
  useToast
} from '@nimbus-ds/components';
import React from 'react';
import {
  Checkbox,
  Form,
  Input,
  Toggle,
  RadioButtonV2,
  FileUploader,
  Tooltip
} from 'components';
import { useParams } from 'react-router-dom';
import { ExternalLinkIcon, InfoCircleIcon, TrashIcon } from '@nimbus-ds/icons';
import {
  CreateScriptModalValidate,
  IAppScriptModal,
  MAX_5MB_SIZE_IN_BYTES,
  initialValues
} from '.';
import { FormikProps } from 'formik';
import { useTranslate } from '../../../../hooks';
import {
  useCreateAppScript,
  useCreateScriptModal
} from 'domains/Applications/hooks';

export const CreateScriptModal: React.FC = () => {
  const { translate } = useTranslate(['application-create-script-modal']);
  const { id } = useParams<{ id: string }>();
  const { context } = useCreateScriptModal();
  const { addToast } = useToast();

  const scripts = useCreateAppScript(id);

  const onSubmit = async (values: IAppScriptModal) => {
    const result = await scripts.mutateAsync({
      ...values,
      location: values.location
        .map((_, index) => (index === 0 ? 'store' : 'checkout'))
        .filter(Boolean)
        .join(',')
    });

    if (!result.content || !values.file) return;

    const formData = new FormData();
    formData.append('file', values.file);

    scripts.scriptUpload.mutate({
      scriptID: result.content.id,
      data: formData
    });
  };

  return (
    <Modal open={context.open} onDismiss={() => context.setOpen?.(false)}>
      <Modal.Header
        title={translate('application-create-script-modal:title')}
      />
      <Modal.Body>
        <Form
          initialValues={initialValues}
          validationSchema={CreateScriptModalValidate}
          onSubmit={onSubmit}
        >
          {({
            errors,
            values,
            setFieldValue
          }: FormikProps<IAppScriptModal>) => (
            <Box display="flex" flexDirection="column" gap="4">
              <Input
                name="name"
                label={translate('application-create-script-modal:name.label')}
                marginBottom="none"
                helpText={errors.name ? translate(errors.name) : undefined}
              />
              <Box display="flex" flexDirection="column" gap="1">
                <Input
                  name="handle"
                  label={translate(
                    'application-create-script-modal:handle.label'
                  )}
                  marginBottom="none"
                  helpText={
                    errors.handle ? translate(errors.handle) : undefined
                  }
                />
                {!errors.handle && (
                  <Text>
                    {translate(
                      'application-create-script-modal:handle.description'
                    )}
                  </Text>
                )}
              </Box>
              <Box gap="2">
                <Text fontWeight="bold" color="neutral-textHigh">
                  {translate('application-create-script-modal:location.label')}
                </Text>
                <Box paddingTop="2" display="flex" flexDirection="row" gap="2">
                  <Checkbox
                    appearance={errors.location ? 'danger' : 'primary'}
                    id="store"
                    name="location[0]"
                    label={translate(
                      'application-create-script-modal:location.store'
                    )}
                  />
                  <Checkbox
                    appearance={errors.location ? 'danger' : 'primary'}
                    id="checkout"
                    name="location[1]"
                    label={translate(
                      'application-create-script-modal:location.checkout'
                    )}
                  />
                </Box>
              </Box>
              <Box gap="2">
                <Text color="neutral-textHigh" fontWeight="bold">
                  {translate('application-create-script-modal:event.label')}
                </Text>
                <Box paddingTop="2" display="flex" flexDirection="row" gap="2">
                  <Box
                    display="flex"
                    gap="1"
                    alignItems="center"
                    flexDirection="row"
                  >
                    <RadioButtonV2
                      label={translate(
                        'application-create-script-modal:event.onfirstinteraction'
                      )}
                      value="onfirstinteraction"
                      name="event"
                    />
                    <Tooltip
                      content={translate(
                        'application-create-script-modal:event.tooltip.onfirstinteraction'
                      )}
                      arrow={true}
                      position="top"
                    >
                      <Icon
                        source={<InfoCircleIcon />}
                        color="neutral-textHigh"
                      />
                    </Tooltip>
                  </Box>
                  <Box
                    alignItems="center"
                    flexDirection="row"
                    display="flex"
                    gap="1"
                  >
                    <RadioButtonV2
                      label="onload"
                      name="event"
                      disabled={!values.location[0] || values.location[1]}
                      value={translate(
                        'application-create-script-modal:event.onload'
                      )}
                    />
                    <Tooltip
                      content={translate(
                        'application-create-script-modal:event.tooltip.onload'
                      )}
                      arrow={true}
                      position="top"
                    >
                      <Icon
                        color="neutral-textHigh"
                        source={<InfoCircleIcon />}
                      />
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" gap="2">
                <Text fontWeight="bold" color="neutral-textHigh">
                  {translate('application-create-script-modal:file.label')}
                </Text>
                {values.file ? (
                  <Box
                    display="flex"
                    width="100%"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    padding="2"
                    borderColor="neutral-interactive"
                    borderStyle="solid"
                    borderWidth="1"
                    borderRadius="2"
                    marginTop="2"
                    marginBottom="2"
                  >
                    <Text>{values.file.name}</Text>
                    <IconButton
                      source={<TrashIcon />}
                      color="neutral-textHigh"
                      backgroundColor="neutral-surface"
                      onClick={(e) => {
                        e.stopPropagation();
                        setFieldValue('file', undefined);
                      }}
                    />
                  </Box>
                ) : (
                  <FileUploader
                    placeholder={translate(
                      'application-create-script-modal:file.placeholder'
                    )}
                    width="100%"
                    height="100px"
                    name="file"
                    accept="text/javascript"
                    maxSize={MAX_5MB_SIZE_IN_BYTES}
                    helperToast={() => {
                      addToast({
                        type: 'danger',
                        text: translate(
                          'api.Validation failed (expected size is less than 5242880)'
                        ),
                        duration: 4000,
                        id: 'file-upload-validation'
                      });
                    }}
                  />
                )}
                <Text>
                  {translate(
                    'application-create-script-modal:file.description'
                  )}
                </Text>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap="2"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Text fontWeight="bold" color="neutral-textHigh">
                    {translate(
                      'application-create-script-modal:auto-install.label'
                    )}
                  </Text>
                  <Toggle name="isAutoInstall" />
                </Box>
                <Text>
                  {translate(
                    'application-create-script-modal:auto-install.description'
                  )}
                </Text>
                <Link
                  as="a"
                  href="#"
                  textDecoration="none"
                  appearance="primary"
                >
                  {translate(
                    'application-create-script-modal:auto-install.link-label'
                  )}
                  <Icon
                    source={<ExternalLinkIcon />}
                    color="primary-interactive"
                  />
                </Link>
              </Box>
              {Object.keys(errors).length > 0 && (
                <Alert appearance="warning">
                  {translate('application-create-script-modal:generic-error')}
                </Alert>
              )}
              <Box
                gap="2"
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                width="100%"
              >
                <Button onClick={() => context.setOpen?.(false)} type="button">
                  {translate('application-create-script-modal:button.cancel')}
                </Button>
                <Button appearance="primary" type="submit">
                  {translate('application-create-script-modal:button.submit')}
                </Button>
              </Box>
            </Box>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};
