import { useEffect, useState } from 'react';

const useDocumentTitle = (title: string): [string, (title: string) => void] => {
  const [documentTitle, setDocumentTitle] = useState(title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return [documentTitle, setDocumentTitle];
};

export default useDocumentTitle;
