import { ReactComponent as Documentation } from './documentation.svg';
import { ReactComponent as CentralNube } from './central-nube.svg';
import { ReactComponent as Doubt } from './doubt.svg';
import { ReactComponent as WhatsApp } from './whatsapp.svg';

import { IHelpModalCards } from './helpModal.types';
import { ROUTES } from 'commons/constants';

export const helpModalCards: IHelpModalCards[] = [
  {
    title: 'modal.help_modal.documentation.title',
    description: 'modal.help_modal.documentation.description',
    icon: Documentation,
    link: ROUTES.DOCUMENTATION
  },
  {
    title: 'modal.help_modal.central_nube.title',
    description: 'modal.help_modal.central_nube.description',
    icon: CentralNube,
    link: 'links:central_nube'
  },
  {
    title: 'modal.help_modal.doubt.title',
    description: 'modal.help_modal.doubt.description',
    icon: Doubt,
    link: 'links:doubt'
  },
  {
    title: 'modal.help_modal.whatsapp.title',
    description: 'modal.help_modal.whatsapp.description',
    icon: WhatsApp,
    link: 'links:whatsapp'
  }
];
