import React from 'react';
import classNames from 'classnames';

import { Link, Text } from '@tiendanube/components';
import { IBody } from './body.types';
import './body.styled.scss';

const Body: React.FC<IBody> = ({
  className,
  message,
  link,
  children,
  ...props
}) => {
  const classnames = classNames({
    'card--body__content': true,
    [className || '']: className && true
  });
  return (
    <div {...props} className={classnames}>
      {message && <Text>{message}</Text>}
      {children}
      {link && (
        <div
          className="card--body__content--link"
          data-testid={link.dataTestId}
        >
          <Link
            appearance="primary"
            iconPosition="end"
            target="_blank"
            {...link}
          >
            {link.text}
          </Link>
        </div>
      )}
    </div>
  );
};

export default Body;
