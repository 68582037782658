import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Text, Title } from '@tiendanube/components';
import { ChevronDownIcon, ChevronUpIcon } from '@nimbus-ds/icons';

import { useAccordion } from 'components/Accordion/accordion.context';
import { useAccordionItem } from 'components/Accordion/widgets/Item/item.context';
import { Box } from 'components';
import { IHeader } from './header.types';
import './header.styled.scss';

const Header: React.FC<IHeader> = ({
  className,
  title,
  children,
  subTitle,
  icon: Icon,
  noIconToggle = false
}) => {
  const { selected, onSelect } = useAccordion();
  const { index, testId } = useAccordionItem();

  const IS_OPEN = useMemo(() => selected === index, [selected, index]);
  const handleSelect = useCallback(
    () => onSelect(IS_OPEN ? '' : index),
    [IS_OPEN, index, onSelect]
  );

  return (
    <div
      className={classNames({
        active: IS_OPEN,
        accordion__header: true,
        [className || '']: className && true
      })}
      onClick={() => typeof children !== 'function' && handleSelect()}
      data-testid={`accordion-item-${testId || ''}`}
    >
      <Box
        className="accordion__header-info"
        width={{ mobile: '100%', tablet: '100%', desktop: '100%' }}
      >
        {Icon && <div className="accordion__header-icon">{<Icon />}</div>}
        {(title || subTitle) && (
          <Box.Container direction={{ desktop: 'column' }}>
            {title && <Title type="h5">{title}</Title>}
            {subTitle && <Text>{subTitle}</Text>}
          </Box.Container>
        )}
        {typeof children === 'function'
          ? children({
              onSelect: handleSelect,
              selected,
              index
            })
          : children}
      </Box>
      {!noIconToggle && (
        <>{IS_OPEN ? <ChevronUpIcon /> : <ChevronDownIcon />}</>
      )}
    </div>
  );
};

export default Header;
