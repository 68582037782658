import * as React from 'react';
import classNames from 'classnames';

import { Header, Body, Footer } from './widgets';
import { ICard, ICardComponents } from './card.types';
import './card.styled.scss';

const Card: React.FC<ICard> & ICardComponents = ({
  className,
  children,
  visibleBorder = true,
  ...props
}: ICard) => {
  const classnames = classNames({
    card__container: true,
    border: visibleBorder,
    [className || '']: className && true
  });

  return (
    <div {...props} className={classnames}>
      {children}
    </div>
  );
};

Card.Header = Header;
Card.Body = Body;
Card.Footer = Footer;

export default Card;
