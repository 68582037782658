import React, { useCallback } from 'react';
import { Formik, Form as FormFormik, FormikProps } from 'formik';

import { IForm } from './form.types';
import { ExitFormConfirmation } from './widgets';

function Form<T>({
  children,
  className,
  blockChangeRouter = true,
  ...props
}: IForm<T>): JSX.Element {
  const when = useCallback(
    (values) =>
      Object.entries(props.initialValues).toString() !==
      Object.entries(values).toString(),
    [props.initialValues]
  );

  return (
    <Formik
      {...props}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values, formikHelpers) => {
        props.onSubmit(values, formikHelpers);
      }}
    >
      {(formikProps: FormikProps<T>) => (
        <>
          <FormFormik className={className}>
            {typeof children === 'function' ? children(formikProps) : children}
          </FormFormik>
          {blockChangeRouter && !formikProps.isSubmitting && (
            <ExitFormConfirmation when={when(formikProps.values)} />
          )}
        </>
      )}
    </Formik>
  );
}

export default Form;
