import React from 'react';
import { Link, Text, Title } from '@tiendanube/components';
import { LogOutIcon } from '@nimbus-ds/icons';
import { Card } from 'components';
import { useTranslate } from 'hooks';

import { partnershipPlanCards } from './plansCards.definitions';
import styles from './plansCards.module.scss';

const PlansCards = () => {
  const { translate } = useTranslate(['partnership-plan', 'links']);

  return (
    <div className={styles.container}>
      {partnershipPlanCards.map((plan) => {
        const Icon = plan.icon as React.ElementType;
        return (
          <Card key={plan.title} className={styles.card}>
            <div className={styles.title_container}>
              <Icon />
              <Title type="h5">{`${translate(plan.title)}`}</Title>
            </div>
            <Text>{`${translate(plan.subtitle)}`}</Text>
            <Link appearance="primary" underline href={translate(plan.link)}>
              {`${translate(plan.textLink)}`}
              <div className={styles['logout-icon']}>
                <LogOutIcon />
              </div>
            </Link>
          </Card>
        );
      })}
    </div>
  );
};

export default PlansCards;
