import { InterfaceSelectOption } from '@tiendanube/components';
import { ApplicationsTypings } from 'types';

export const transformCategories = (
  page: ApplicationsTypings.IPage
): Omit<ApplicationsTypings.IPage, 'categories'> & {
  categories: InterfaceSelectOption[];
} => ({
  categories: page.categories.flatMap((category) => {
    return {
      label: category.name,
      value: `${category.id}`
    };
  }),
  scopes: page.scopes
});
