export enum EApplicationVisibility {
  PRIVATE = 'private',
  PUBLIC = 'public'
}

export enum EApplicationStatus {
  PUBLISHED = 'published',
  AWAITING_APPROVAL = 'awaiting approval',
  DEVELOPMENT = 'development'
}

export enum EApplicationStatusData {
  PENDING = 'pending',
  FINISHED = 'finished'
}

export enum EAppTypeEnum {
  STANDALONE = 'standalone',
  ENHANCED = 'native'
}
