import React from 'react';
import { Trans } from 'react-i18next';
import { Text } from '@nimbus-ds/components';

import { Checkbox } from 'components';
import { useTranslate } from 'hooks';

import styles from './termsOfUse.module.scss';
import { ITermsOfUse } from './termsOfUse.types';
import { links } from './termsOfUse.definitions';

export const TermsOfUse: React.FC<ITermsOfUse> = ({
  helpText,
  countryCode
}) => {
  const { translate } = useTranslate(['auth-signup', 'links']);
  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <Checkbox id="terms" name="terms" />
        <Text>
          <Trans
            i18nKey={translate('auth-signup:fields.terms')}
            components={[
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                data-testid="link-terms-of-use"
                key="terms"
                className={styles.link}
                href={links[countryCode]?.termOfUse ?? links.BR.termOfUse}
                target="_blank"
                rel="noreferrer"
              />,
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                data-testid="link-policy-terms"
                className={styles.link}
                key="policy_terms"
                href={links[countryCode]?.policyTerms ?? links.BR.policyTerms}
                target="_blank"
                rel="noreferrer"
              />
            ]}
          />
        </Text>
      </div>
      {helpText && <Text color="danger-interactive">{helpText}</Text>}
    </div>
  );
};

export default TermsOfUse;
