import { useFetchV2, useTranslate } from 'hooks';
import { useMutation } from 'react-query';
import { IApiResponse } from '../../../../hooks/useFetch/useFetch.types';
import { useCreateScriptModal } from '..';
import { useToast } from '@nimbus-ds/components';

const useCreateAppScript = (appId: string) => {
  const { request } = useFetchV2();
  const { addToast } = useToast();
  const { translate } = useTranslate([
    'api',
    'application-create-script-modal'
  ]);
  const { context: modalContext } = useCreateScriptModal();

  const uploadFileError = (e: IApiResponse<any>) => {
    const message =
      e.statusCode === 400
        ? translate(`application-create-script-modal:api.${e.message}`)
        : translate(`application-create-script-modal:api.generic_error`);

    addToast?.({
      type: 'danger',
      id: `id-${message}`,
      text: message,
      duration: 4000
    });
  };

  const genericError = (e: IApiResponse<any>) => {
    let message = 'api:generic_error';

    if (e.statusCode === 400) {
      if (
        e.message.includes(
          `For the use of onload event, it's necessary to previously request approval`
        ) ||
        e.message.includes(
          `Event onload not allowed because location is checkout`
        )
      ) {
        message = `application-create-script-modal:api.${e.message}`;
      }
    }

    addToast?.({
      type: 'danger',
      id: `id-${message}`,
      text: translate(message),
      duration: 4000
    });
  };

  const create = useMutation(
    (data: any) =>
      request<{
        // eslint-disable-next-line babel/camelcase
        app_id: number;
        // eslint-disable-next-line babel/camelcase
        created_at: string;
        // eslint-disable-next-line babel/camelcase
        current_version_id: number | null;
        // eslint-disable-next-line babel/camelcase
        draft_version_id: number | null;
        event: string;
        id: number;
        // eslint-disable-next-line babel/camelcase
        is_auto_install: boolean;
        location: string;
        name: string;
        status: string;
        // eslint-disable-next-line babel/camelcase
        updated_at: string;
      }>({
        url: `apps/${appId}/scripts`,
        method: 'POST',
        data
      }),
    {
      onError: genericError
    }
  );

  const scriptUpload = useMutation(
    (args: { scriptID: number; data: FormData }) =>
      request({
        url: `apps/${appId}/scripts/${args.scriptID}/versions`,
        method: 'POST',
        data: args.data
      }),
    {
      onError: uploadFileError,
      onSuccess: () => {
        addToast?.({
          text: translate('application-create-script-modal:script-created'),
          type: 'success',
          duration: 4000,
          id: 'application-create-script-modal:script-created'
        });
        if (!modalContext?.setOpen) return;

        modalContext.setOpen(false);
      }
    }
  );

  return { ...create, scriptUpload };
};

export default useCreateAppScript;
