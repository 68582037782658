import React from 'react';
import { Card, Skeleton, Box } from '@nimbus-ds/components';

const CouponsSkeleton: React.FC = () => {
  return (
    <Card>
      <Card.Header>
        <Skeleton width="200px" height="32px" />
      </Card.Header>
      <Card.Body>
        <Box display="flex" gap="2" flexDirection="column">
          <Skeleton width="100%" height="24px" />
          <Skeleton width="100%" height="24px" />
        </Box>
      </Card.Body>
      <Card.Footer>
        <Box display="flex" alignItems="center" width="100%" gap="4">
          <Skeleton width="100%" height="32px" />
          <Skeleton width="42px" height="42px" borderRadius="42px" />
        </Box>
      </Card.Footer>
    </Card>
  );
};

export default CouponsSkeleton;
