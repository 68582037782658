import React, { useMemo } from 'react';
import { Box, Text } from '@nimbus-ds/components';
import { CheckCircleIcon } from '@nimbus-ds/icons';

import { useTranslate } from 'hooks';
import { IPasswordBoxProps } from './passwordBox.types';
import { ReactComponent as CircleIcon } from './circle-icon.svg';
import { MIN_LENGTH, validations } from './passwordBox.definitions';

const PasswordBox: React.FC<IPasswordBoxProps> = ({
  value
}: IPasswordBoxProps) => {
  const { translate } = useTranslate('common');

  const checkedValidation = useMemo(
    () => ({
      lowerCase: value.toUpperCase() !== value,
      upperCase: value.toLowerCase() !== value,
      minLength: value.length >= MIN_LENGTH,
      specialChar: /(?=.*[()}{[\]·%&¢∞¬÷≠,.^?!"'~=+\-_/*\-+.@#$|])/.test(value),
      hasNumber: /\d/.test(value)
    }),
    [value]
  ) as {
    [key: string]: boolean;
  };

  return (
    <Box
      padding="4"
      backgroundColor="neutral-surfaceDisabled"
      borderRadius="0-5"
      marginBottom="4"
    >
      <Text as="span" color="neutral-textLow">
        {validations.map((validation, i) => (
          <Box key={`${validation.key}box`} display="flex" flexDirection="row">
            <Box
              marginRight="2"
              marginBottom={i < validations.length - 1 ? '2' : undefined}
              display="flex"
              alignItems="center"
            >
              <Text
                as="span"
                color={
                  !!checkedValidation[validation.key]
                    ? 'success-textLow'
                    : 'neutral-textLow'
                }
                data-testid={validation.key}
              >
                {!!checkedValidation[validation.key] ? (
                  <CheckCircleIcon
                    data-testid={`${validation.key}-svg-checked`}
                  />
                ) : (
                  <CircleIcon data-testid={`${validation.key}-svg-unchecked`} />
                )}
              </Text>
            </Box>
            <Text
              as="span"
              color={
                !!checkedValidation[validation.key]
                  ? 'success-textLow'
                  : 'neutral-textLow'
              }
            >
              {translate(validation.description)}
            </Text>
          </Box>
        ))}
      </Text>
    </Box>
  );
};

export default PasswordBox;
