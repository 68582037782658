import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { InterfaceSelectOption } from '@tiendanube/components';
import { useHistory } from 'react-router-dom';

import { ApplicationsTypings } from 'types';
import { useFetchV2, useToast } from 'hooks';

import { transformCategories } from 'commons/formatters';
import { EXPIRATION_TIME_6H, ROUTES } from 'commons/constants';
import { ELocale } from 'app/i18n/i18n.types';
import { IApiResponse } from 'hooks/useFetch/useFetch.types';

type ITransformedCategories = Omit<ApplicationsTypings.IPage, 'categories'> & {
  categories: InterfaceSelectOption[];
};

const useEditApplication = (appId?: string | number, country?: ELocale) => {
  const { request } = useFetchV2();
  const history = useHistory();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const { data: categoriesAndScopesData, isLoading: loadingPage } = useQuery(
    ['categories', 'scopes'],
    () =>
      request<ApplicationsTypings.IPage>({
        url: `/partners-portal/pages/app`,
        method: 'GET'
      }),
    {
      retry: false,
      staleTime: EXPIRATION_TIME_6H,
      enabled: !!appId
    }
  );

  const { data: applicationData, isLoading: loadingApplication } = useQuery(
    ['applicationEdit', appId],
    () =>
      request<ApplicationsTypings.IApplication>({
        url: `/apps-deprecated/${appId}?fields=name,storeRedactUrl,customersRedactUrl,customersDataRequestUrl,id,categoryId,visibility,appUrl,redirectUrl,scopeIdList,status,type,appNativeConfig&lang=${country}`,
        method: 'GET'
      }),
    {
      onError: (err: IApiResponse<any>) => redirectToNotFoundPage(err),
      retry: false,
      staleTime: EXPIRATION_TIME_6H,
      enabled: !!appId
    }
  );

  const redirectToNotFoundPage = (error: IApiResponse<unknown>) => {
    if (error?.statusCode === 404) {
      history.push(ROUTES.NOT_FOUND);
      return;
    }
    addToast({
      appearance: 'danger',
      label: error.message
    });
  };

  const AppTypeId = {
    [ApplicationsTypings.EAppTypeEnum.STANDALONE]: 1,
    [ApplicationsTypings.EAppTypeEnum.ENHANCED]: 2
  } as {
    [key: string]: number;
  };

  const onSubmit = useMutation(
    (values: ApplicationsTypings.IApplication) =>
      request<ApplicationsTypings.TApplicationEdit>({
        url: `/apps/${appId}`,
        method: 'PATCH',
        data: {
          ...values,
          name: { [country as string]: values.name },
          categoryId: Number(values.categoryId),
          scopeIdList: values.scopeIdList.map((scopeId) => Number(scopeId)),
          storeRedactUrl: values.storeRedactUrl ?? null,
          customersRedactUrl: values.customersRedactUrl ?? null,
          customersDataRequestUrl: values.customersDataRequestUrl ?? null,
          appTypeId: values?.appType && AppTypeId[values?.appType]
        }
      }),
    {
      onSuccess: async () => {
        await invalidateCache();
        history.push(ROUTES.APPLICATIONS.DETAILS.replace(':id', `${appId}`));
      },
      onError: (response: IApiResponse<any>) => {
        addToast({
          appearance: 'danger',
          label: response.message
        });
      }
    }
  );

  const IS_LOADING = useMemo(
    () => loadingPage || loadingApplication || onSubmit.isLoading,
    [loadingPage, loadingApplication, onSubmit.isLoading]
  );

  const CATEGORIES_AND_SCOPES: ITransformedCategories | undefined = useMemo(
    () =>
      categoriesAndScopesData &&
      transformCategories(categoriesAndScopesData.content),
    [categoriesAndScopesData]
  );

  const transformApplication = (
    application: ApplicationsTypings.IApplication
  ): ApplicationsTypings.IApplication =>
    ({
      ...application,
      scopeIdList: application.scopeIdList?.map((scopeId) =>
        scopeId.toString()
      ),
      appUrl: application.appUrl ?? '',
      redirectUrl: application.redirectUrl ?? '',
      appNativeConfig: {
        name: application?.appNativeConfig?.name
      }
    } as unknown as ApplicationsTypings.IApplication);

  const APPLICATION = useMemo(
    () => applicationData && transformApplication(applicationData.content),
    [applicationData]
  );

  const invalidateCache = useCallback(
    () => queryClient.invalidateQueries(['applicationEdit', appId]),
    [appId, queryClient]
  );

  return {
    IS_LOADING,
    APPLICATION,
    CATEGORIES_AND_SCOPES,
    onSubmit
  };
};

export default useEditApplication;
