import React from 'react';
import classNames from 'classnames';
import { IBox, IBoxComponents } from './box.types';
import { style } from './box.definitions';
import { Container } from './widgets';
import './box.styled.scss';

const Box: React.FC<IBox> & IBoxComponents = ({
  children,
  width,
  className
}: IBox) => (
  <div
    style={style(width)}
    className={classNames({ box: true, [`${className}`]: className && true })}
    data-testid="box"
  >
    {children}
  </div>
);

Box.Container = Container;

export default Box;
