import { string, addMethod, StringSchema } from 'yup';

addMethod<StringSchema>(
  string,
  'url',
  function (
    errorMessage: string,
    params: { allowHttp: boolean; optional?: boolean }
  ) {
    return this.test('url', errorMessage, function (value) {
      const { path, createError } = this;
      try {
        if (params?.optional && (value === '' || !value)) return true;

        const protocolsValidators = {
          'https:': () => true,
          'http:': (hostname: string) =>
            params?.allowHttp || /localhost|127.0.0.1/.test(hostname)
        };
        const url = new URL(value || '');
        if (/http:|https:/.test(url.protocol)) {
          const protocol = url.protocol as 'http:' | 'https:';
          return protocolsValidators[protocol](url.hostname);
        }
        return createError({ path, message: errorMessage });
      } catch {
        return createError({ path, message: errorMessage });
      }
    });
  }
);
