import React, { ReactText, useMemo } from 'react';
import { Link, Text, Title } from '@tiendanube/components';

import { useCommons, useTranslate } from 'hooks';
import { Card, Modal } from 'components';
import { helpModalCards } from './helpModal.definitions';
import './helpModal.styled.scss';

const HelpModal: React.FC = () => {
  const { translate } = useTranslate(['common', 'links']);
  const { commons, setHelpModal } = useCommons();

  const renderCards = useMemo(
    () =>
      helpModalCards.map((item) => {
        const Icon = item.icon as React.ElementType;
        const link = item.link.includes('ROUTES')
          ? item.link
          : translate(item.link);

        return (
          <Link href={link} target="_self" key={item.title}>
            <Card className="help--modal__card">
              <Icon />
              <div className="help--modal__content">
                <Title type="h6">
                  {translate(`common:${item.title}`) as ReactText}
                </Title>
                <Text size="base">
                  {translate(`common:${item.description}`)}
                </Text>
              </div>
            </Card>
          </Link>
        );
      }),
    [translate]
  );

  return (
    <Modal
      className="help--modal__container"
      show={commons.helpModal}
      onDismiss={() => setHelpModal(false)}
    >
      <Modal.Header>
        <Title type="h4">
          {translate('common:modal.help_modal.title') as ReactText}
        </Title>
      </Modal.Header>
      <Modal.Body>
        <Text>{translate('common:modal.help_modal.subtitle')}</Text>
        <div className="help--card__container">{renderCards}</div>
      </Modal.Body>
    </Modal>
  );
};

export default HelpModal;
