import React from 'react';
import classNames from 'classnames';
import { IContainer } from './container.types';
import { style } from './container.definitions';
import './container.styled.scss';

const Container: React.FC<IContainer> = ({
  children,
  direction,
  justifyContent,
  className,
  testId,
  id
}) => (
  <div
    id={id}
    style={style(direction, justifyContent)}
    className={classNames({
      'box--container': true,
      [`${className}`]: className && true
    })}
    data-testid={testId || 'container'}
  >
    {children}
  </div>
);

export default Container;
