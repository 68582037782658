import React from 'react';
import { Box } from 'components';
import { Box as BoxNimbus, Title } from '@nimbus-ds/components';
import { useTopMenu } from 'hooks';

import { Item } from './widgets';
import { BlankPageComponents, IBlankPageProps } from './blankPage.types';
import './blankPage.styled.scss';

const BlankPage: React.FC<IBlankPageProps> & BlankPageComponents = ({
  title,
  subtitle,
  children
}: IBlankPageProps) => {
  const renderTitle = title as React.ReactText;
  useTopMenu();
  return (
    <>
      <Box.Container
        direction={{ desktop: 'column' }}
        className="blankpage--container"
      >
        {renderTitle && <Title>{renderTitle}</Title>}
        <Box.Container
          direction={{ desktop: 'column' }}
          justifyContent={{ desktop: 'center' }}
          className="blankpage--container__content"
        >
          <Box.Container
            direction={{ desktop: 'column' }}
            className="blankpage--info"
          >
            {subtitle && (
              <>
                <BoxNimbus display={{ xs: 'none', md: 'block' }}>
                  <Title
                    as="h2"
                    fontSize="12"
                    lineHeight="14"
                    fontWeight="regular"
                    color="primary-textHigh"
                  >
                    {subtitle}
                  </Title>
                </BoxNimbus>
                <BoxNimbus display={{ xs: 'block', md: 'none' }}>
                  <Title
                    as="h2"
                    fontSize="8"
                    lineHeight="10"
                    fontWeight="regular"
                    color="primary-textHigh"
                  >
                    {subtitle}
                  </Title>
                </BoxNimbus>
              </>
            )}
            <div className="blankpage--info__items">{children}</div>
          </Box.Container>
        </Box.Container>
      </Box.Container>
    </>
  );
};

BlankPage.Item = Item;

export default BlankPage;
