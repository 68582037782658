import React from 'react';
import { Field, FieldProps } from 'formik';
import {
  Box,
  Icon,
  Label,
  Select as SelectNimbus,
  Text
} from '@nimbus-ds/components';
import {
  ISelect,
  InterfaceSelectGroup,
  InterfaceSelectOption
} from './select.types';

const Select: React.FC<ISelect> = ({
  name,
  label,
  labelIcon,
  options,
  type,
  helpText,
  disabled,
  boxWrapper,
  placeholder,
  ...props
}) => {
  const grouped = (options as InterfaceSelectGroup[]).filter(
    (option) => !!option.group
  );

  const ungrouped = (options as InterfaceSelectGroup[]).filter(
    (option) => !option.group
  ) as unknown as InterfaceSelectOption[];

  return (
    <Box {...boxWrapper}>
      {(label || labelIcon) && (
        <Box paddingBottom="2">
          <Label htmlFor={name}>
            <Icon source={labelIcon} />
            {label}
          </Label>
        </Box>
      )}
      <Field name={name} disabled={disabled}>
        {({ field, form }: FieldProps) => (
          <SelectNimbus
            {...props}
            id={name}
            name={name}
            value={field.value || ''}
            appearance={!!helpText ? 'danger' : 'neutral'}
            disabled={disabled}
            onChange={(e) => {
              const value = e.target.value;
              form.setFieldValue(
                name,
                typeof field.value === 'number' || type === 'number'
                  ? Number(value)
                  : value
              );
            }}
          >
            {placeholder && (
              <SelectNimbus.Option label={placeholder} disabled value={''} />
            )}
            {grouped.map((group) => (
              <SelectNimbus.Group key={group.group} label={group.group}>
                {group.options.map((option) => (
                  <SelectNimbus.Option
                    key={option.value}
                    disabled={option.disabled}
                    label={option.label}
                    value={option.value}
                  />
                ))}
              </SelectNimbus.Group>
            ))}
            {ungrouped.map((option) => (
              <SelectNimbus.Option
                key={option.value}
                label={option.label}
                value={option.value}
                disabled={option.disabled}
              />
            ))}
          </SelectNimbus>
        )}
      </Field>
      {helpText && <Text color="danger-interactive">{helpText}</Text>}
    </Box>
  );
};

export default Select;
