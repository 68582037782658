import { setupWorker, SetupWorkerApi } from 'msw';
import { handlers } from './handlers';

declare const window: Window &
  typeof globalThis & {
    msw: { worker: SetupWorkerApi };
    urlApi?: string;
  };

const worker = setupWorker(...handlers);

window.urlApi = process.env.REACT_APP_API_BFF;
window.msw = { worker };

export { worker };
