import React, { useMemo } from 'react';
import classNames from 'classnames';

import { useAccordion } from 'components/Accordion/accordion.context';
import { useAccordionItem } from 'components/Accordion/widgets/Item/item.context';
import { IGroup } from './body.types';
import './body.styled.scss';

const Body: React.FC<IGroup> = ({ className, children, ...props }) => {
  const { selected } = useAccordion();
  const { index } = useAccordionItem();

  const IS_OPEN = useMemo(() => selected === index, [selected, index]);

  const classnames = classNames({
    active: IS_OPEN,
    accordion__body: true,
    [className || '']: className && true
  });

  return (
    <>
      {IS_OPEN && (
        <div {...props} className={classnames}>
          {children}
        </div>
      )}
    </>
  );
};

export default Body;
