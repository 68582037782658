import React, { useMemo } from 'react';
import { CashIcon } from '@nimbus-ds/icons';
import {
  Box,
  Card,
  Text,
  Button,
  Icon,
  Title,
  Alert
} from '@nimbus-ds/components';
import { useAmplitude, useBankAccount, useLoading, useTranslate } from 'hooks';

import { useHistory } from 'react-router-dom';
import { ROUTES } from 'commons/constants';
import { useBalance, useRescue } from 'domains/Payments/v2/hooks';
import { Skeleton } from 'components';

const Payments = () => {
  const { translate } = useTranslate(['home']);
  const history = useHistory();
  const {
    data: balance,
    totalBalance,
    isLoading: isLoadingBalance
  } = useBalance();
  const { data: bankAccount, isLoading: isLoadingBankAccount } =
    useBankAccount();
  const {
    data: rescue,
    isRescueInProcess,
    isLoading: isLoadingRescue,
    totalRescue
  } = useRescue();

  const { amplitudeAction } = useAmplitude();

  const IS_LOADING = useLoading([
    isLoadingBalance,
    isLoadingBankAccount,
    isLoadingRescue
  ]);

  const isDisabledRequestRescue = useMemo(
    () =>
      !bankAccount?.content ||
      !rescue?.content?.isAvailable ||
      (balance?.content && balance?.content?.balance <= 0) ||
      IS_LOADING,
    [bankAccount, rescue, IS_LOADING, balance?.content]
  );
  return (
    <Card backgroundColor="primary-surface">
      <Card.Header title={`${translate('home:cards.payments.title')}`} />
      <Card.Body>
        <Box display="flex" flexDirection="column">
          <Text>{translate('home:cards.payments.description')}</Text>
          <Title as="h2" color="primary-textHigh">
            {IS_LOADING && <Skeleton width="150px" height="32px" />}
            {!IS_LOADING && totalBalance}
          </Title>
        </Box>
        {!bankAccount?.content && Number(balance?.content?.balance) > 0 && (
          <Box marginTop="2">
            <Alert appearance="warning">
              {translate('home:cards.payments.update_bank_account')}
            </Alert>
          </Box>
        )}

        {isRescueInProcess && (
          <Box marginTop="2">
            <Alert appearance="neutral">
              {translate('home:cards.payments.rescue_in_process', {
                totalRescue
              })}
            </Alert>
          </Box>
        )}
      </Card.Body>
      <Card.Footer>
        <Button
          appearance="primary"
          onClick={() => {
            amplitudeAction('home-payout--button_click');
            history.push(ROUTES.PAYMENTS.REQUEST_RESCUE);
          }}
          disabled={isDisabledRequestRescue || isRescueInProcess}
        >
          {`${translate('home:cards.payments.buttons.rescue')}`}
        </Button>
        <Button
          appearance="neutral"
          onClick={() => history.push(ROUTES.PAYMENTS.DEFAULT)}
        >
          <Icon source={<CashIcon />} />
          {`${translate('home:cards.payments.buttons.history')}`}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default Payments;
