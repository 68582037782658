export const ROUTES = {
  DEFAULT: '/',
  HOME: '/home',
  BANNERS: '/home/banners',
  HEALTH: '/health',
  NOT_FOUND: '/not-found',
  DOCUMENTATION: '/documentation',
  PARTNERSHIP_PLAN: '/partnership-plan',
  AUTH: {
    DEFAULT: '/',
    CREATE_ACCOUNT: '/auth/signup',
    SIGNUP_SUCCESS: '/auth/signup/success',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password/:id/:token',
    INTRODUCTION: '/auth/introduction',
    NEW_PASSWORD: '/auth/new-password',
    STATS: '/auth/stats/:token',
    CONFIRM_EMAIL: '/auth/confirm-email/:id'
  },
  ACCOUNT: {
    DEFAULT: '/account',
    REVENUE: '/account/revenue',
    REGISTRATION: '/account/registration',
    ACCESS_PASSWORD: '/account/access-password',
    EXTERNAL_CODES: '/account/external-codes',
    PARNERSHIP: {
      DEFAULT: '/account/partnership',
      TECHNOLOGICAL: '/account/partnership/technological'
    }
  },
  APPLICATIONS: {
    DEFAULT: '/applications',
    CREATE: '/applications/create',
    UPDATE: '/applications/update/:id',
    DETAILS: '/applications/details/:id',
    UNINSTALLS: '/applications/uninstalls/:id',
    INSTALLATIONS: '/applications/installations/:id',
    AUTHENTICATION: '/applications/authentication/:id',
    STATEMENTS: {
      DEFAULT: '/applications/statements/:id',
      HOMOLOG: '/applications/statements/:id/homolog/:countryId'
    },
    SCRIPT_DETAILS: '/applications/details/:id/script/:scriptId'
  },
  PAYMENTS: {
    DEFAULT: '/payments',
    REQUEST_RESCUE: '/payments/request-rescue',
    REQUEST_RESCUE_STATUS: '/payments/request-rescue/status',
    DETAILS: '/payments/details/:id'
  },
  STORES: {
    DEFAULT: '/stores',
    CREATE: '/stores/create',
    DETAILS: '/stores/details/:id'
  }
};

export const REGEX = {
  CPF_CNPJ:
    /(^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$)|(^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$)/,
  RFC: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
  PASSWORD:
    /(?=.*[()}{[\]·%&¢∞¬÷≠,.^?!"'~=+\-_/*\-+.@#$|])(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z]).{10,}/,
  CURRENCY: /^(\d+(,\d{1,2})?|,?\d{1,2})$/,
  SPACES_AND_SYMBOLS: /[^A-Z0-9]+/gi,
  APP_HANDLE: /^[a-z0-9-]+$/,
  // eslint-disable-next-line no-useless-escape
  DATE: /(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/,
  PHONE_BR: /(\+\d{2})\s?(\d{2})\s(\d{4,5})-?(\d{4})/
};

export const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';
export const IS_STAGING = process.env.REACT_APP_ENV === 'staging';
export const IS_DEVELOPMENT = process.env.REACT_APP_ENV === 'development';
export const ENABLE_RECAPTCHA = IS_PRODUCTION;
export const ENABLE_HOTJAR = IS_PRODUCTION;
export const ENABLE_SENTRY = IS_PRODUCTION;
export const IS_PAYMENTS_CATEGORY = 5;
export const EXPIRATION_TIME_6H = 21600000;
export const EXPIRATION_TIME_2S = 2000;
