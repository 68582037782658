export enum SignupTags {
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign'
}

export enum GaEventCategory {
  CONVERSION = 'Conversion',
  REGISTRATION = 'Registration',
  LOGIN = 'Login'
}

export enum GaEventAction {
  REGISTER = 'Signup',
  LOGIN = 'Login',
  SUCCESS_REGISTER = 'Register'
}

export enum GaEventLabel {
  LOGIN = 'Login Page',
  REGISTER = 'Signup Page'
}
