import { string, addMethod, StringSchema } from 'yup';

addMethod<StringSchema>(string, 'jsonValid', function (errorMessage) {
  return this.test('jsonValid', errorMessage, function (value) {
    try {
      return !value ? true : JSON.parse(value);
    } catch (error) {
      return this.createError({ path: this.path, message: errorMessage });
    }
  });
});
