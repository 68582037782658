import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { useFetchV2, useAuth } from 'hooks';
import { TagsTypings } from 'types';

type TagTypes = 'app';

const useTags = (tag: string, type: TagTypes, relatedId: number | string) => {
  const queryClient = useQueryClient();
  const { auth } = useAuth();
  const { request } = useFetchV2();

  const typeUrlMap: Record<TagTypes, string> = {
    app: `/v2/apps/${relatedId}/tags`
  };

  const query = useQuery(
    [tag, auth.partnerId],
    () =>
      request<TagsTypings.ITag[]>({
        url: typeUrlMap[type]
      }),
    {
      retry: false
    }
  );

  const invalidateCache = useCallback(
    () => queryClient.invalidateQueries(tag),
    [queryClient, tag]
  );

  const hasTag = useMemo<boolean>(() => {
    return query.data?.content
      ? query.data?.content.findIndex((item) => item.tag === tag) >= 0
      : false;
  }, [query, tag]);

  return {
    ...query,
    hasTag,
    invalidateCache
  };
};

export default useTags;
