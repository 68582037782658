export const dateTime = (
  value: Date,
  language = 'pt-BR',
  type?: 'allDate' | 'date' | 'time'
): string => {
  const newDate = new Date(value);
  const date = new Intl.DateTimeFormat(language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
  const hours = new Intl.DateTimeFormat(language, { timeStyle: 'short' });
  if (type === 'date') return `${date.format(newDate)}`;
  if (type === 'time') return `${hours.format(newDate)}`;
  return `${date.format(newDate)} ${hours.format(newDate)}`;
};

export const strToDate = (dateStr: string) => {
  const date = new Date(dateStr);
  if (/^\d+-\d+-\d+$/.test(dateStr)) {
    return new Date(date.getTime() - date.getTimezoneOffset() * -60000);
  }
  return date;
};
