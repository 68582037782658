import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ExternalLinkIcon, InfoCircleIcon, StoreIcon } from '@nimbus-ds/icons';
import {
  Text,
  Title,
  Tooltip,
  Link,
  Button,
  Icon,
  Box,
  Card
} from '@nimbus-ds/components';
import { useTranslate } from 'hooks';
import { ROUTES } from 'commons/constants';

import { Trans } from 'react-i18next';

const Stores: React.FC<{ quantity?: number }> = ({ quantity }) => {
  const { translate } = useTranslate(['home', 'links']);
  const history = useHistory();
  const showQuantity = useMemo(() => !!quantity ?? false, [quantity]);

  return (
    <Card>
      <Card.Header>
        <Box display="flex">
          <Box marginRight="2">
            <StoreIcon size="medium" />
          </Box>
          <Title as="h4">{`${translate('home:cards.stores.title')}`}</Title>
        </Box>
      </Card.Header>
      <Card.Body>
        <Box>
          <Box display="flex" flexDirection="column" marginBottom="8">
            <Title as="h2" fontWeight="bold">
              {quantity}
            </Title>
            {showQuantity && (
              <Box display="flex" flexDirection="row" alignItems="center">
                <Text>{translate('home:cards.stores.quantity_stores')}</Text>
                <Tooltip
                  content={translate('home:cards.stores.help_text')}
                  position="top"
                >
                  <Box marginLeft="1">
                    <Icon source={<InfoCircleIcon />} />
                  </Box>
                </Tooltip>
              </Box>
            )}
            {!showQuantity && (
              <Text>
                <Trans i18nKey={translate('home:cards.stores.create_stores')} />
              </Text>
            )}
          </Box>

          <Box>
            {showQuantity && (
              <Box display="flex" flexDirection="row" alignItems="center">
                <Button
                  onClick={() => history.push(ROUTES.STORES.CREATE)}
                >{`${translate(
                  'home:cards.stores.buttons.create_store'
                )}`}</Button>

                <Box marginLeft="2">
                  <Link
                    as="button"
                    textDecoration="none"
                    onClick={() => history.push(ROUTES.STORES.DEFAULT)}
                  >
                    <Text fontWeight="bold" color="primary-textLow">
                      {translate('home:cards.stores.buttons.stores')}
                    </Text>
                  </Link>
                </Box>
              </Box>
            )}
            {!showQuantity && (
              <Box display="flex" flexDirection="row" alignItems="center">
                <Button
                  onClick={() => history.push(ROUTES.STORES.CREATE)}
                >{`${translate(
                  'home:cards.stores.buttons.create_store'
                )}`}</Button>

                <Box marginLeft="2">
                  <Link
                    as="a"
                    textDecoration="none"
                    href={translate('links:comercial_partner')}
                  >
                    <Text fontWeight="bold" color="primary-textLow">
                      {translate('home:cards.stores.buttons.more_info')}
                    </Text>
                    <Icon
                      source={<ExternalLinkIcon />}
                      color="primary-textLow"
                    />
                  </Link>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Card.Body>
    </Card>
  );
};

export default Stores;
