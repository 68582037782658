import { useMutation } from 'react-query';

import { useToast, useFetchV2, useTranslate } from 'hooks';

const useRecaptcha = () => {
  const { translate } = useTranslate('api');
  const { request } = useFetchV2();
  const { addToast } = useToast();

  const onValidateRecaptcha = useMutation(
    (data: { recaptchaToken: string }) =>
      request({
        url: '/auth/validate-recaptcha',
        method: 'POST',
        data
      }),
    {
      onError: () => {
        addToast({
          appearance: 'danger',
          label: translate('recaptcha_error')
        });
      }
    }
  );

  return [onValidateRecaptcha];
};

export default useRecaptcha;
