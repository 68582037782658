import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useAuth, useFetch, useTranslate } from 'hooks';
import { AuthTypings } from 'types';
import { ROUTES } from 'commons/constants';
import { useToast } from 'components/Toast/toast.context';
import { Loading } from 'components';
import { IStats } from './stats.types';

const Stats: React.FC = () => {
  const history = useHistory();
  const { token } = useParams<IStats>();
  const { setAuth } = useAuth();
  const { setLanguage } = useTranslate('errors');
  const { addToast } = useToast();

  const [onSubmit, { loading: isLoading }] = useFetch<AuthTypings.IUser>(
    '/auth/stats/login',
    {
      fallbackError: {
        execute: true,
        callback: (err) =>
          err.request.status === 500 && history.push(ROUTES.DEFAULT)
      },
      notToast: true
    }
  );

  const authenticate = async (values: IStats) => {
    try {
      const response = await onSubmit({
        method: 'POST',
        data: values
      });
      if (response) {
        setAuth(response);
        setLanguage(
          response?.countryLanguage || AuthTypings.ECountryLanguage.BRAZIL
        );
        localStorage.setItem('token', response?.accessToken ?? '');
        history.push(ROUTES.DEFAULT);
      }
    } catch (err: unknown) {
      const error: Error = err as Error;
      addToast({
        appearance: 'danger',
        label: error.message
      });
    }
  };

  useEffect(() => {
    authenticate({ token });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return <>{isLoading && <Loading size="large" />}</>;
};

export default Stats;
