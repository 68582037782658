import { REGEX } from 'commons/constants';
import { string, addMethod, StringSchema } from 'yup';

addMethod<StringSchema>(string, 'currency', function (errorMessage) {
  return this.test('currency', errorMessage, function (value) {
    return (
      REGEX.CURRENCY.test(value ?? '') ??
      this.createError({ path: this.path, message: errorMessage })
    );
  });
});
