import { IState, TActions, EActions } from './useEvent.types';

export const reducer = <T>(draft: IState<T>, action: TActions<T>) => {
  switch (action.type) {
    case EActions.IS_LOADING:
      draft.isLoading = action.payload;
      break;
    case EActions.DATA:
      draft.data = action.payload;
      break;
    case EActions.IS_ERROR:
      draft.isError = action.payload;
      break;
  }
};

export const baseURL = process.env.REACT_APP_API_BFF;
