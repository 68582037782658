import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Title, Box } from '@nimbus-ds/components';

import { DashboardTypings } from 'types';
import { useAuth, useFetchV2, useTopMenu, useTranslate } from 'hooks';

import {
  Alerts,
  Applications,
  Doubts,
  Partnership,
  ApplicationsSkeleton,
  MarketingLink,
  MarketingLinkSkeleton,
  DemoStore,
  DemoStoreSkeleton,
  Stores,
  StoresSkeleton,
  Coupons,
  CouponsSkeleton,
  Seal,
  SealSkeleton,
  PartnershipSkeleton,
  PaymentsSkeleton,
  Payments
} from './widgets';

const Home: React.FC = () => {
  const { translate } = useTranslate(['home', 'links']);
  const { request } = useFetchV2();
  const { auth } = useAuth();
  useTopMenu();

  const { data, isLoading, isFetched } = useQuery(
    ['dashboard', auth.accessToken],
    () =>
      request<DashboardTypings.IDashboard>({
        url: '/partners-portal/pages/dashboard',
        method: 'GET'
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!auth.accessToken
    }
  );

  const name = useMemo(
    () => data?.content?.partnerName || data?.content?.companyName,
    [data?.content?.partnerName, data?.content?.companyName]
  );

  const isShowAlert = useMemo(
    () => !data?.content?.partnerName && !isLoading && isFetched,
    [data?.content?.partnerName, isLoading, isFetched]
  );

  const IS_LOADING = useMemo(() => isLoading, [isLoading]);

  return (
    <>
      <Box flexDirection="column" padding="6">
        <Box mb="8">
          {IS_LOADING && <Title.Skeleton width="200px" />}
          {!IS_LOADING && (
            <Title>
              {translate('home:subtitle', {
                name
              })}
            </Title>
          )}
        </Box>
        <Box
          display="flex"
          gap="4"
          mb="4"
          flexDirection={{ xs: 'column', md: 'column', xl: 'row' }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="4"
            width={{ xs: '100%', md: '100%', xl: '70%' }}
          >
            {isShowAlert && (
              <Alerts isTechPartner={data?.content?.techPartner} />
            )}

            <Box
              display="flex"
              gap="4"
              flexDirection={{ xs: 'column', md: 'column', xl: 'row' }}
            >
              {IS_LOADING && <ApplicationsSkeleton />}
              {!IS_LOADING && (
                <Applications totalApps={data?.content?.totalApps ?? 0} />
              )}
              {IS_LOADING && <StoresSkeleton />}
              {!IS_LOADING && <Stores quantity={data?.content?.totalStores} />}
            </Box>
            <Box
              display="flex"
              gap="4"
              flexDirection={{ xs: 'column', md: 'column', xl: 'row' }}
            >
              {IS_LOADING && <MarketingLinkSkeleton />}
              {!IS_LOADING && (
                <MarketingLink link={data?.content?.affiliateLink} />
              )}
              {IS_LOADING && <PartnershipSkeleton />}
              {!IS_LOADING && <Partnership />}
            </Box>
          </Box>
          <Box
            display="flex"
            width={{ md: '100%', xl: '30%' }}
            flexDirection="column"
            gap="4"
          >
            {IS_LOADING && <PaymentsSkeleton />}
            {!IS_LOADING && <Payments />}

            {IS_LOADING && <DemoStoreSkeleton />}
            {!IS_LOADING && (
              <DemoStore storeDemoUrl={data?.content?.storeDemoUrl} />
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="4"
          width={{ xs: '100%', md: '100%', xl: '70%' }}
          paddingRight={{ xl: '3' }}
        >
          {IS_LOADING && <Title.Skeleton width="450px" />}
          {(data?.content?.coupon || data?.content?.seal) && !IS_LOADING && (
            <Title as="h2">
              {translate('home:section.specialist_benefit')}
            </Title>
          )}

          {IS_LOADING && <CouponsSkeleton />}
          {data?.content?.coupon && !IS_LOADING && (
            <Coupons coupon={data?.content?.coupon} />
          )}
          {IS_LOADING && <SealSkeleton />}
          {data?.content?.seal && !IS_LOADING && (
            <Seal seal={data?.content?.seal} banners={data?.content?.banners} />
          )}
          <Doubts />
        </Box>
      </Box>
    </>
  );
};

export default Home;
