import { useCallback, useRef, useEffect } from 'react';
import { useImmerReducer, Reducer } from 'use-immer';
import {
  EventStreamContentType,
  fetchEventSource
} from '@microsoft/fetch-event-source';

import { useToast } from 'components/Toast/toast.context';
import { useAuth, useTranslate } from 'hooks';
import { EActions, IState, TActions } from './useEvent.types';
import { baseURL, reducer } from './useEvent.definitions';

const STATUS_SUCCESS = 200;
const STATUS_ERROR = 500;
const STATUS_NO_CONTENT = 204;

const useEvent = <T = unknown>() => {
  const cancelEvent = useRef<boolean>(false);
  const { auth } = useAuth();
  const { addToast } = useToast();
  const { translate } = useTranslate('api');

  const initialState: IState<T> = {
    isLoading: false
  };

  const [state, dispatch] = useImmerReducer(
    reducer as Reducer<IState<T>, TActions<T>>,
    initialState
  );

  const parseEvent = useCallback((data = '') => {
    try {
      if (!data) return { statusCode: STATUS_NO_CONTENT };
      return JSON.parse(data);
    } catch (error) {
      return { statusCode: STATUS_ERROR };
    }
  }, []);

  const subscribeEvent = useCallback(
    (
      path: string,
      params: {
        onMessage?: (event: T) => void;
        message?: { success?: string; error?: string };
      }
    ) => {
      const controller = new AbortController();
      fetchEventSource(`${baseURL}${path}`, {
        signal: controller.signal,
        openWhenHidden: true,
        headers: {
          Accept: 'text/event-stream',
          Authorization: `Bearer ${auth.accessToken}`
        },
        async onopen(response) {
          if (
            response.ok &&
            response.headers.get('content-type') === EventStreamContentType
          ) {
            dispatch({ type: EActions.IS_LOADING, payload: true });
          }
        },
        onmessage(ev) {
          const data = parseEvent(ev.data);
          if (!cancelEvent.current) {
            params.onMessage && params.onMessage(data);
            dispatch({
              type: EActions.DATA,
              payload: data
            });
            dispatch({ type: EActions.IS_LOADING, payload: false });
          }
          if (data.statusCode !== STATUS_NO_CONTENT) {
            controller.abort();
          }
          if (data.statusCode === STATUS_SUCCESS && params?.message?.success) {
            addToast({
              appearance: 'success',
              label:
                params?.message?.success ??
                translate('Your request was successfully Submitted')
            });
          }
          if (data.statusCode === STATUS_ERROR && params?.message?.error) {
            addToast({
              appearance: 'danger',
              label: params?.message?.error ?? translate('generic_error')
            });
          }
        },
        onerror(error) {
          if (!cancelEvent.current) {
            dispatch({ type: EActions.IS_ERROR, payload: error.message });
            dispatch({ type: EActions.IS_LOADING, payload: false });
          }
          controller.abort();
          addToast({
            appearance: 'danger',
            label: params?.message?.error ?? translate('generic_error')
          });
          throw new Error();
        },
        onclose() {
          if (!cancelEvent.current) {
            dispatch({ type: EActions.IS_LOADING, payload: false });
          }
          controller.abort();
          addToast({
            appearance: 'danger',
            label: params?.message?.error ?? translate('generic_error')
          });
        }
      });
    },
    [auth.accessToken, dispatch, addToast, parseEvent, translate]
  );

  useEffect(
    () => () => {
      cancelEvent.current = true;
    },
    []
  );

  return { subscribeEvent, ...state };
};

export default useEvent;
