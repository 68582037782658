import React from 'react';
import classNames from 'classnames';
import { Card, ImageGallery } from 'components';
import { IApplicationImage } from './applicationImage.types';

const ApplicationImage: React.FC<IApplicationImage> = ({
  name,
  title,
  description,
  maxItems,
  images,
  className,
  loading,
  helpText,
  onSubmitImage,
  onDeleteImage
}) => {
  const classnames = classNames({
    statementshomolog__card: true,
    [className || '']: className && true
  });
  return (
    <Card className={classnames}>
      <Card.Header title={title} />
      <Card.Body message={description} />
      <Card.Footer>
        <ImageGallery
          name={name}
          images={images}
          maxItems={maxItems}
          loading={loading}
          onAddImage={onSubmitImage}
          helpText={helpText}
          onDeleteImage={onDeleteImage}
        />
      </Card.Footer>
    </Card>
  );
};

export default ApplicationImage;
