import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ROUTES } from 'commons/constants';

const Account = React.lazy(() => import('./Account'));
const Revenue = React.lazy(() => import('./pages/Revenue'));
const Registration = React.lazy(
  () => import('./pages/Registration/Registration')
);
const AccessPassword = React.lazy(
  () => import('./pages/AccessPassword/AccessPassword')
);
const PartnershipTechnological = React.lazy(
  () => import('./pages/PartnershipTechnological')
);

const ExternalCodes = React.lazy(() => import('./pages/ExternalCodes'));

const Routes: React.FC = () => (
  <Switch>
    <Route path={ROUTES.ACCOUNT.DEFAULT} exact>
      <Account />
    </Route>
    <Route path={ROUTES.ACCOUNT.REVENUE} exact>
      <Revenue />
    </Route>
    <Route path={ROUTES.ACCOUNT.REGISTRATION} exact>
      <Registration />
    </Route>
    <Route path={ROUTES.ACCOUNT.EXTERNAL_CODES} exact>
      <ExternalCodes />
    </Route>
    <Route path={ROUTES.ACCOUNT.ACCESS_PASSWORD} exact>
      <AccessPassword />
    </Route>
    <Route path={ROUTES.ACCOUNT.PARNERSHIP.TECHNOLOGICAL} exact>
      <PartnershipTechnological />
    </Route>
    <Redirect from="*" to={ROUTES.NOT_FOUND} />
  </Switch>
);

export default Routes;
